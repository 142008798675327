<template>
  <div class="louyou">
    <div class="imgs">
      <img
        @click="backhome"
        :src="$getImageUrl(logo.storeLogo, 120, 50, 'webp')"
      />
    </div>
    <div class="table">
      <el-menu
        router
        :default-active="activeIndex"
        @select="handleSelect"
        class="el-menu-demo"
        mode="horizontal"
        text-color="#67686D"
        active-text-color="#6E4FAA"
      >
        <el-menu-item
          index="1"
          :route="{ path: '/', query: { storeId: storeID } }"
          >{{ $t('首页') }}</el-menu-item
        >
        <el-menu-item
          index="3"
          :route="{ path: '/goods', query: { storeId: storeID } }"
          >{{ $t('全部产品') }}</el-menu-item
        >
        <el-menu-item
          v-for="item in datalist"
          :key="item.id"
          :index="item.id"
          @click="clicktable(item.customizeId, item.pageType, item.url)"
          >{{ item.title }}</el-menu-item
        >
      </el-menu>
    </div>
    <div class="righttable">
      <!-- <div ><img src="../../assets/语言切换.png"/><span>中文</span></div> -->
      <div @click.stop="openSearch">
        <img src="../../assets/搜索小.png" /><span>{{ $t('搜索') }}</span>
      </div>
      <div @click="opencar">
        <el-badge
          :value="this.$store.state.user.token ? Num : locNum"
          class="item"
        >
          <img src="../../assets/购物车.png" />
        </el-badge>
      </div>
      <div><img @click="trmyuser" src="../../assets/登录 单色.png" /></div>
      <!-- <el-popover placement="bottom" width="50" trigger="click">
        <div
          class="li"
          style="text-align: center;height: 30px;line-height: 30px;"
          @click="onSelectLang(item)"
          v-for="(item, index) in actions"
          :key="index"
        >
          {{ item.text }}
        </div>
        <div slot="reference">
          <div
            style="display: flex;align-items: center;justify-content: center;height: 40px;"
          >
            <img src="@/assets/语言@2x.png" style="width: 22px;height: 22px;" />
          </div>
        </div>
      </el-popover> -->
      <div @click="xuanZ" style="cursor: pointer;">
        {{ lang.showName
        }}{{ currency.showNameCn ? '/' + currency.showNameCn : '' }}
      </div>
      <div>
        <img
          class="later"
          @click.stop="drawer = true"
          src="../../assets/更多.png"
        />
      </div>
    </div>
    <div class="bizhongxuanze">
      <el-dialog
        title=""
        :visible.sync="dialogFormVisible"
        top="35vh"
        width="25%"
      >
        <el-form :model="form">
          <el-form-item :label="$t('语言')" :label-width="formLabelWidth">
            <el-select
              v-model="form.lang"
              :placeholder="$t('请选择语言')"
              size="medium"
            >
              <el-option
                v-for="(item, index) in actions"
                :key="index"
                :label="item.text"
                :value="item.code"
              ></el-option>
              <!-- <el-option label="区域二" value="beijing"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('货币')" :label-width="formLabelWidth">
            <el-select
              v-model="form.currency"
              :placeholder="$t('请选择展示货币')"
              :disabled="disabled"
            >
              <el-option
                :label="item.showNameCn"
                :value="item.currencyId"
                v-for="(item, index) in currencyList"
                :key="index"
              ></el-option>
              <!-- <el-option label="区域二" value="beijing"></el-option> -->
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="onSubmit">{{
            $t('确定')
          }}</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 从上往下的 -->
    <el-drawer
      class="drawers"
      :modal="false"
      :visible.sync="drawer1"
      :direction="direction1"
      :with-header="false"
    >
      <div class="inputbox">
        <div class="inputboxs">
          <el-input
            class="SearchInput"
            :placeholder="$t('请输入内容')"
            @keyup.enter.native="Searchs"
            v-model="input2"
          >
          </el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="Searchs"
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-close"
            @click="closedom"
          ></el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 从左往右的 -->
    <van-popup
      v-model="drawer"
      position="left"
      :style="{ width: '50%', height: '100%' }"
    >
      <van-sidebar v-model="activeKey">
        <van-sidebar-item
          :title="$t('首页')"
          :to="{ path: '/', query: { storeId: storeID } }"
          @click="drawer1close(1)"
        />
        <van-sidebar-item
          :title="$t('全部商品')"
          :to="{ path: '/goods', query: { storeId: storeID } }"
          @click="drawer1close(3)"
        />
        <van-sidebar-item
          :title="item.title"
          @click="drawer1close(item.customizeId, item.pageType, item.url)"
          v-for="item in datalist"
          :key="item.id"
          :url="item.url"
        ></van-sidebar-item>
      </van-sidebar>
    </van-popup>
  </div>
</template>

<script>
import { gettoken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import Bus from '@/utils/bus'
import Buss from '@/utils/buss'
import { listStoreNavigation, getStoreIndexInfo } from '@/API/listFront'
import {
  Language,
  currency,
  StoreFindContact,
  AreaCodeDefault
} from '@/API/Language'
// import { getStoreIndexInfo } from "@/API/listFront";
export default {
  name: 'laYoy',
  computed: {
    ...mapGetters(['Num', 'locNum'])
  },
  props: {},

  data () {
    return {
      input2: '',
      datalist: [],
      drawer1: false,
      drawer: false,
      direction1: 'ttb',
      direction: 'ltr',
      activeIndex: '1',
      listlogo: '',
      tabsss: '',
      abox: false,
      storeID: localStorage.getItem('storeID'),
      activeKey: 0,
      logo: {},
      actions: [],
      dialogFormVisible: false,
      form: {
        lang: '',
        currency: ''
      },
      formLabelWidth: '120px',
      currencyList: [],
      currency: {},
      lang: {},
      disabled: false
    }
  },
  mounted () {
    Bus.$on('log', content => {
      this.activeIndex = content
    })
    Buss.$on('logs', content => {
      this.activeIndex = content
    })
  },
  created () {
    if (
      localStorage.getItem('menutable') !== null &&
      localStorage.getItem('menutable') !== undefined &&
      localStorage.getItem('menutable') !== ''
    ) {
      this.activeIndex = localStorage.getItem('menutable') + ''
    } else {
      this.activeIndex = '1'
      localStorage.setItem('menutable', 1)
      // this.$router.push({ path: '/', query: { storeId: localStorage.getItem("storeID") } });
    }

    if (location.pathname !== '/404'||localStorage.getItem('storeID')) {
      this.logotable()
      this.dynamicrouter()
      this.creadshoppinglist()
      this.getLanguage()
      this.getCurrency()
      this.kefu()
      this.getAreaCodeDefault()
    }
  },
  methods: {
    // 获取默认区号
    async getAreaCodeDefault () {
      let res = await AreaCodeDefault({
        code: localStorage.getItem('lang')
      })
      if (res.data) {
        localStorage.setItem('AreaCode', res.data.areaCode)
      }
    },
    async kefu () {
      let res = await StoreFindContact({
        storeId: localStorage.getItem('storeID')
      })
      let kefu = { phone: [], email: [],address:'' }

      if(res.data?.phone.length>0){
        kefu.phone=JSON.parse(res.data.phone)
      }
      if(res.data?.email.length>0){
        kefu.email=JSON.parse(res.data.email)
      }
      if(res.data?.address.length>0){
        let lang=localStorage.getItem('lang')
        let address=JSON.parse(res.data.address)[lang]
        kefu.address=address
      }
      localStorage.setItem('kefu', JSON.stringify(kefu))
    },
    xuanZ () {
      this.dialogFormVisible = true
      // 获取当前页面路径
      let currentPath = this.$route.path

      if (currentPath == '/onlinePayment') {
        this.disabled = true
      } else {
        this.disabled = false
      }
    },
    onSelectLang (action) {
      localStorage.setItem('lang', action.code)
      this.$changeLanguage(action.code)
      // this.$router.go(0)
    },
    onSubmit () {
      this.dialogFormVisible = false
      localStorage.setItem('lang', this.form.lang)
      this.$changeLanguage(this.form.lang)
      localStorage.setItem('currency', this.form.currency)
      this.getAreaCodeDefault().then(() => {
        let a = this.currencyList.filter(el => {
          return el.currencyId == this.form.currency
        })
        this.$store.dispatch('order/upadtaCurrency', a[0].icon)
        let url = window.location.href
        let index = url.indexOf('&lang=')
        if (index > -1) {
          url = url.substring(0, index)
          let idx = url.indexOf('&currency=')
          if (idx > -1) {
            url = url.substring(0, idx)
          }
        }

        location.href = url
      })

      // localStorage.setItem('currencyUnit', a[0].icon)
      // this.$router.go(0)
    },
    async getCurrency () {
      let a = { storeId: localStorage.getItem('storeID') }
      let { data } = await currency(a)
      this.currencyList = data
      if (!localStorage.getItem('currency')) {
        localStorage.setItem('currency', data[0].currencyId)
        this.currency = data[0]
        this.form.currency = data[0].currencyId
        this.$store.dispatch('order/upadtaCurrency', data[0].icon)
        location.reload()
      } else {
        var isCNExist = data.filter(el => {
          return el.currencyId == localStorage.getItem('currency')
        })
        if (isCNExist.length == 0) {
          let url = window.location.href
          let index = url.indexOf('&lang=')
          if (index > -1) {
            url = url.substring(0, index)
            let idx = url.indexOf('&currency=')
            if (idx > -1) {
              url = url.substring(0, idx)
            }
          }
          localStorage.setItem('currency', data[0].currencyId)
          this.currency = data[0]
          this.form.currency = data[0].currencyId
          this.$store.dispatch('order/upadtaCurrency', data[0].icon)
          // location.reload()
          location.href = url
        } else {
          this.currency = isCNExist[0]
          this.form.currency = isCNExist[0].currencyId
          this.$store.dispatch('order/upadtaCurrency', isCNExist[0].icon)
        }
      }
    },
    async getLanguage () {
      let a = { storeId: localStorage.getItem('storeID') }
      let b = []
      let { data } = await Language(a)
      data.forEach(el => {
        this.actions.push({
          text: el.showName,
          code: el.code,
          isDefault: el.isDefault
        })
        b.push(el.code)
      })
      if (!localStorage.getItem('lang')) {
        let a = data.filter(el => {
          return el.isDefault == true
        })
        localStorage.setItem('lang', a[0].code)
        this.form.lang = a[0].code
        this.lang = a[0]
        location.reload()
      } else {
        // debugger
        var isCNExist = b.includes(localStorage.getItem('lang'))
        if (!isCNExist) {
          let url = window.location.href
          let index = url.indexOf('&lang=')
          if (index > -1) {
            url = url.substring(0, index)
            let idx = url.indexOf('&currency=')
            if (idx > -1) {
              url = url.substring(0, idx)
            }
          }
          let a = data.filter(el => {
            return el.isDefault == true
          })
          localStorage.setItem('lang', a[0].code)
          this.lang = a[0]
          this.form.lang = a[0].code
          location.href = url
        } else {
          this.lang = data.filter(el => {
            return el.code == localStorage.getItem('lang')
          })[0]
          this.form.lang = this.lang.code
        }
      }
      // this.actions=data
    },
    // 获取店铺logo
    async logotable () {
      let data1 = {
        storeId: localStorage.getItem('storeID')
      }
      let { data } = await getStoreIndexInfo(data1)
      document.title = data.storeIndexInfo.title
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = data.storeIndexInfo.storeLogo
      document.getElementsByTagName('head')[0].appendChild(link)
      this.logo = data.storeIndexInfo
      localStorage.setItem('logotable', JSON.stringify(data))
    },
    // 监听屏幕宽度
    creadersize () {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      let w = window.innerWidth
      let a = false
      if (w > 767) {
        a = false
      } else {
        a = true
      }
      this.abox = a
    },
    backhome () {
      this.creadersize()
      if (this.abox === true) {
        this.$router.push({
          path: '/',
          query: { storeId: localStorage.getItem('storeID') }
        })
      }
    },
    drawer1close (id, type, url) {
      this.drawer = false
      this.$store.dispatch('user/TablistID', id)
      localStorage.setItem('menutable', id)
      if (type == 0) {
        this.$router.push({
          path: '/',
          query: { storeId: localStorage.getItem('storeID') }
        })
        // this.$store.dispatch("user/TablistID", id);
      } else if (type == 1) {
        this.$router.push({
          path: '/productDetails',
          query: {
            value: this.parseUrlParams(url).value,
            storeId: localStorage.getItem('storeID')
          }
        })
      } else if (type == 2) {
        window.location.href = url
      } else if (type == 3) {
        this.$router.push({
          path: '/ExternalWord',
          query: { storeId: localStorage.getItem('storeID') }
        })
      }
    },
    // 跳转详情
    clicktable (id, type, url) {
      this.$store.dispatch('user/TablistID', id)
      if (type == 0) {
        this.$router.push({
          path: '/',
          query: { storeId: localStorage.getItem('storeID') }
        })
      } else if (type == 1) {
        this.$router.push({
          path: '/productDetails',
          query: {
            value: this.parseUrlParams(url).value,
            storeId: localStorage.getItem('storeID')
          }
        })
      } else if (type == 2) {
        if (url.indexOf('classId3=') != -1) {
          let a = url.split('classId3=')
          let c = a[0].split('classId2=')
          let b = c[0].split('classId1=')
          localStorage.setItem('customizeId', a[1])
          this.$store.dispatch('user/TablistID', a[1])
          this.$router.push({
            path: '/goods',
            query: {
              classId1: b[1].slice(0, -1),
              classId2: c[1].slice(0, -1),
              classId3: a[1],
              storeId: localStorage.getItem('storeID')
            }
          })
        } else if (url.indexOf('classId2=') != -1) {
          let a = url.split('classId2=')
          let b = a[0].split('classId1=')
          localStorage.setItem('customizeId', a[1])
          this.$store.dispatch('user/TablistID', a[1])
          this.$router.push({
            path: '/goods',
            query: {
              classId1: b[1].slice(0, -1),
              classId2: a[1],
              storeId: localStorage.getItem('storeID')
            }
          })
        } else if (url.indexOf('classId1=') != -1) {
          let a = url.split('classId1=')
          localStorage.setItem('customizeId', a[1])
          this.$store.dispatch('user/TablistID', a[1])
          this.$router.push({
            path: '/goods',
            query: { classId1: a[1], storeId: localStorage.getItem('storeID') }
          })
        }
        // window.location.href = url
      } else if (type == 3) {
        this.$router.push({
          path: '/ExternalWord',
          query: { storeId: localStorage.getItem('storeID') }
        })
      }
    },
    // 第一种方法
    parseUrlParams (url) {
      const params = {}
      if (!url || url === '' || typeof url !== 'string') {
        return params
      }
      const paramsStr = url.split('?')[1]
      if (!paramsStr) {
        return params
      }
      const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
      for (let i = 0; i < paramsArr.length / 2; i++) {
        const value = paramsArr[i * 2 + 1]
        params[paramsArr[i * 2]] =
          value === 'true' ? true : value === 'false' ? false : value
      }
      return params
    },
    handleSelect (index) {
      localStorage.setItem('menutable', index + '')
      this.activeIndex = index + ''
      // this.$forceUpdate()
    },
    // 初始化动态添加路由
    async dynamicrouter () {
      let a = {
        storeId: localStorage.getItem('storeID')
      }
      let { data } = await listStoreNavigation(a)
      this.datalist = data
      let b = localStorage.getItem('customizeId')
      if (b !== '' && b !== undefined && b !== null) {
        await data.forEach(item => {
          if (item.customizeId === b) {
            this.activeIndex = item.id + ''
            localStorage.setItem('menutable', item.id + '')
          }
        })
        if (
          localStorage.getItem('classId1') !== '' ||
          localStorage.getItem('classId2') !== '' ||
          localStorage.getItem('classId3') !== ''
        ) {
          this.activeIndex = 3
          await this.$router.push({
            path: '/goods',
            query: {
              classId1: localStorage.getItem('classId1'),
              classId2: localStorage.getItem('classId2'),
              classId3: localStorage.getItem('classId3'),
              storeId: localStorage.getItem('storeID')
            }
          })
        } else {
          await this.$router.push({
            path: 'ExternalWord',
            query: { id: b, storeId: localStorage.getItem('storeID') }
          })
        }
      }
      // if()
    },
    // 获取购物车列表
    async creadshoppinglist () {
      if (this.$store.state.user.token) {
        let a = {
          userId: this.$store.state.user.personaluser.id,
          storeId: localStorage.getItem('storeID')
        }
        await this.$store.dispatch('shoppingcar/inquireshoppinglist', a)
      } else {
        this.$store.commit('locshoppingcar/lookloacshoppingcarnum')
      }
    },
    // 鼠标点击搜索
    openSearch () {
      this.drawer1 = true
    },
    closedom () {
      this.drawer1 = false
      this.input2 = ''
    },
    // 搜索
    Searchs () {
      this.$router.push(
        {
          path: '/search',
          query: {
            title: this.input2,
            storeId: localStorage.getItem('storeID')
          }
        },
        () => {},
        () => {}
      )
      this.drawer1 = false
      this.input2 = ''
    },
    // 购物车
    opencar () {
      if (this.$store.state.user.token) {
        this.$router.push(
          {
            path: '/ShoppingCart',
            query: { storeId: localStorage.getItem('storeID') }
          },
          () => {},
          () => {}
        )
      } else {
        this.$router.push(
          {
            path: '/login',
            query: { storeId: localStorage.getItem('storeID') }
          },
          () => {},
          () => {}
        )
      }
    },
    // 个人信息模块跳转
    trmyuser () {
      if (
        this.$route.path != '/usermy/personalUser' &&
        this.$route.path != '/usermy/messageUser' &&
        this.$route.path != '/usermy/leaveMessage' &&
        this.$route.path != '/usermy/addressAdministration' &&
        this.$route.path != '/usermy/myOorder' &&
        this.$route.path != '/usermy/afterSale'
      ) {
        if (gettoken()) {
          this.$router.push(
            {
              path: '/usermy',
              query: { storeId: localStorage.getItem('storeID') }
            },
            () => {},
            () => {}
          )
        } else {
          this.$router.push(
            {
              path: '/login',
              query: { storeId: localStorage.getItem('storeID') }
            },
            () => {},
            () => {}
          )
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.louyou {
  padding: 0 18.5%;
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;

  .imgs {
    width: 10%;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .table {
    flex: 1;
    padding-left: 50px;
    width: 450px;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: 'Microsoft YaHei';

    .el-menu-item {
      a {
        display: block;
        height: 100%;
      }
    }
  }
}

.van-sidebar {
  width: 100%;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px;
}

.el-menu-item {
  padding: 0;
  font-size: 14px;
  font-family: 'Microsoft YaHei';
  color: #384358;
  font-weight: 600;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #6e4faa;
  font-size: 16px;
  font-family: 'Microsoft YaHei';
  font-weight: 600;
}

.el-menu--horizontal > .el-menu-item {
  margin-left: 23px;
  height: 30px;
  line-height: 30px;
}

.li:hover {
  cursor: pointer;
  background-color: #ecf5ff;
  color: #6ab3ff;
}
::v-deep.el-popover {
  padding: 12px 0 !important;
}
.righttable {
  display: flex;
  font-size: 14px;
  line-height: 40px;
  color: #67686d;
  font-weight: 600;
  height: 40px;

  .later {
    display: none;
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      cursor: pointer;
      width: 22px;
      height: 22px;
    }
  }
}

.righttable > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0px 0.5rem;

  span {
    cursor: pointer;
  }
}

.righttable > div > img {
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.drawers {
  height: 20%;

  .inputbox {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .inputboxs {
      border-bottom: 1px solid #cccccc;

      .el-button {
        width: 60px;
        height: 50px;
        font-size: 20px;
        font-weight: 600;
        background-color: #fff;
        color: #6e4faa;
        border: 0px;
      }
    }

    .SearchInput {
      width: 400px;
      height: 50px;
      font-size: 15px;
    }
  }
}
.inputboxs {
  /deep/.el-input__inner {
    background: rgba(255, 255, 255, 0.03);
    border: 0px;
    height: 50px;
    color: #817d7d;
  }
  /deep/.el-input__inner::foucs {
    border-bottom: 1px solid;
  }
}
.bizhongxuanze {
  /deep/.el-input__inner {
    height: 50px;
    // width:350px;
  }
}

/deep/.el-drawer {
  box-shadow: 0;
}

/deep/ .el-drawer.ttb {
  background: #fff;
}
</style>
