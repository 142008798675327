// 中国
export default {
    返回: '返回',
    用户服务协议及法律声明: '用户服务协议及法律声明',
    用户服务协议: '用户服务协议',
    法律声明: '法律声明',
    隐私政策: '隐私政策',
    商品分类: '商品分类',
    请选择: '请选择',
    暂无商品: '暂无商品',
    请选择商品分类: '请选择商品分类',
    全部: '全部',
    销量从高到低: '销量从高到低',
    销量从低到高: '销量从低到高',
    价格从高到低: '价格从高到低',
    价格从低到高: '价格从低到高',
    推荐产品: '推荐产品',
    首页: '首页',
    全部产品: '全部产品',
    搜索: '搜索',
    请输入内容: '请输入内容',
    免费注册: '免费注册',
    时: '时',
    邮箱: '邮箱',
    请输入邮箱: '请输入邮箱',
    密码: '密码',
    请输入密码: '请输入密码',
    请设置位密码需包字母及数字: '请设置8-20位密码，需包字母及数字',
    手机号: '手机号',
    验证码: '验证码',
    验证码2: '验证码',
    请输入验证码: '请输入验证码',
    已阅读并同意以下协议: '已阅读并同意以下协议',
    注册: '注册',
    已有账号: '已有账号',
    直接登录: '直接登录',
    秒后重新发送: '{seconds}秒后重新发送',
    发送验证码: '发送验证码',
    邮箱必须输入: '邮箱必须输入',
    请输入正确的邮箱: '请输入正确的邮箱',
    密码必须输入: '密码必须输入',
    密码最少8位最多16位: '密码最少8位最多16位',
    请输入正确的密码格式必须包含两种字符: '请输入正确的密码格式必须包含两种字符',
    请输入正确的手机号: '请输入正确的手机号',
    请输入正确验证码: '请输入正确验证码',
    注册成功: '注册成功',
    需勾选协议: '需勾选协议',
    发送成功: '发送成功',
    重新发送验证码: '重新发送验证码',
    登录: '登录',
    密码登录: '密码登录',
    验证码登录: '验证码登录',
    请输入邮箱手机号: '请输入邮箱/手机号',
    请输入账号密码: '请输入账号密码',
    立即登录: '立即登录',
    我是新用户: '我是新用户',
    立即注册: '立即注册',
    忘记密码: '忘记密码',
    欢迎登录: '欢迎登录',
    取消: '取 消',
    确定: '确 定',
    验证邮箱: '验证邮箱',
    修改邮箱: '修改邮箱',
    手机验证: '手机验证',
    邮箱验证: '邮箱验证',
    邮箱修改: '邮箱修改',
    完成: '完成',
    为确认身份我们需验证您的安全手机: '为确认身份，我们需验证您的安全手机',
    如果您当前的手机号已无法使用请联系客服:
        '如果您当前的手机号已无法使用请联系客服',
    当前安全手机号: '当前安全手机号',
    恭喜您邮箱地址验证成功: '恭喜您！邮箱地址验证成功',
    请在下次使用邮箱地址进行登录: '请在下次使用邮箱地址进行登录',
    下一步: '下一步',
    返回个人中心: '返回个人中心',
    修改密码: '修改密码',
    再次输入新密码: '再次输入新密码',
    立即修改: '立即修改',
    请再次输入密码: '请再次输入密码',
    两次输入密码不一致: '两次输入密码不一致',
    请输入旧密码: '请输入旧密码',
    长度在8到16个字符: '长度在 8 到 16 个字符',
    请输入新密码: '请输入新密码',
    必须包含两种字符: '必须包含两种字符',
    新密码不能和旧密码相同: '新密码不能和旧密码相同',
    修改成功: '修改成功',
    找回密码: '找回密码',
    立即提交: '立即提交',
    姓: '姓',
    名: '名',
    国家区域: '国家/区域',
    城市: '城市',
    邮政编码: '邮政编码',
    详细地址: '详细地址',
    默认地址: '默认地址',
    新增地址: '新增地址',
    请输入名: '请输入名',
    长度在1到20个字符: '长度在1 到 20 个字符',
    长度在1到8个字符: '长度在1 到 8 个字符',
    请输入姓: '请输入姓',
    请选择国家: '请选择国家',
    请选择城市: '请选择城市',
    请输入邮编: '请输入邮编',
    请输入详细地址: '请输入详细地址',
    请输入电话号码: '请输入电话号码',
    编辑地址: '编辑地址',
    收货地址: '收货地址',
    地址最多10条还能保存xxx条: '地址最多10条还能保存{seconds}条',
    地址最多: '地址最多',
    条: '条',
    还能保存: '还能保存',
    编辑: '编辑',
    删除地址: '删除地址',
    暂无地址信息: '暂无地址信息',
    是否删除地址: '是否删除地址',
    删除成功: '删除成功',
    已取消删除: '已取消删除',
    是否删除: '是否删除',
    售后申请: '售后申请',
    售后申请提交成功: '售后申请提交成功',
    售后申请提交失败: '售后申请提交失败',
    售后专员可能与您电话沟通请保持手机畅通:
        '售后专员可能与您电话沟通，请保持手机畅通',
    温馨提示: '温馨提示',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询:
        '商品寄回地址将在审核通过后以短信形式告知，或在申请记录中查询。',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通:
        '提交服务单后，售后专员可能与您电话沟通，请保持手机畅通。',
    退货处理成功后退款金额将原路返回到您的支持账户中:
        '退货处理成功后退款金额将原路返回到您的支持账户中；',
    联系客服: '联系客服',
    商品信息: '商品信息',
    单价: '单价',
    数量: '数量',
    小计: '小计',
    实付: '实付',
    申请信息: '申请信息',
    退货类型: '退货类型',
    我要退款: '我要退款',
    我要退货退款: '我要退货退款',
    没收到货或与店铺协商同意不用退货只退款:
        '没收到货，或与店铺协商同意不 用退货只退款',
    已收到货需要退还收到的货物: '已收到货，需要退还收到的货物',
    退货金额: '退货金额',
    不可修改最多: '不可修改，最多',
    含发货邮费: '含发货邮费',
    退货理由: '退货理由',
    请选择退货原因: '请选择退货原因',
    问题描述: '问题描述',
    上传凭证: '上传凭证',
    为了帮助您更好的解决问题请上传图片: '为了帮助您更好的解决问题，请上传图片',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件:
        '最多可上传5张图片，每张图片大小不超过5M，支持bmp,gif,jpg,png,jpeg格式文件',
    联系方式: '联系方式',
    联系人: '联系人',
    请输入联系人: '请输入联系人',
    联系电话: '联系电话',
    确认提交: '确认提交',
    客服电话: '客服电话',
    客服邮箱: '客服邮箱',
    请输入名字: '请输入名字',
    长度在2到10个字符: '长度在 2 到 10 个字符',
    请输入电话: '请输入电话',
    请描述问题: '请描述问题',
    最多上传五张: '最多上传五张',
    最多上传一张: '最多上传一张',
    上传成功: '上传成功',
    文件过大: '文件过大',
    售后单号: '售后单号',
    申请时间: '申请时间',
    重新申请: '重新申请',
    取消售后: '取消售后',
    退款失败: '退款失败',
    退款中: '退款中',
    退款完成: '退款完成',
    提交申请: '提交申请',
    店铺处理: '店铺处理',
    店铺退款: '店铺退款',
    售后完成: '售后完成',
    售后商品: '售后商品',
    售后信息: '售后信息',
    售后方式: '售后方式',
    退款金额: '退款金额',
    退款原因: '退款原因',
    退款说明: '退款说明',
    购买信息: '购买信息',
    商品单价: '商品单价',
    件: '件',
    实付金额: '实付金额',
    发货件数: '发货件数',
    发货状态: '发货状态',
    订单编号: '订单编号',
    等待店铺审核: '等待店铺审核',
    留言内容: '留言内容',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料:
        '您可以在这里给店铺留言，为了保证你的权益，请尽可能详细的上传留言资料',
    发表留言: '发表留言',
    店: '店',
    客: '客',
    客户: '客户',
    店铺: '店铺',
    退货原因: '退货原因',
    退货数量: '退货数量',
    请输入反馈内容: '请输入反馈内容',
    取消成功: '取消成功',
    发表成功: '发表成功',
    添加超时: '添加超时',
    下单时间: '下单时间',
    查看详情: '查看详情',
    服务单号: '服务单号',
    暂无售后信息: '暂无售后信息',
    留言回复: '留言回复',
    x条回复: '{num}条回复',
    留言回复长度xxx个字符: '留言回复，长度2-100个字符',
    提交: '提交',
    长度在2到100个字符: '长度在 2 到 100 个字符',
    评论成功: '评论成功',
    手机号码: '手机号码',
    邮箱地址: '邮箱地址',
    立即留言: '立即留言',
    请填写留言内容: '请填写留言内容',
    留言成功: '留言成功',
    留言管理: '留言管理',
    我要留言: '我要留言',
    回复: '回复',
    查看全部: '查看全部',
    查看全部x条回复: '查看全部{num}条回复',
    暂无留言信息: '暂无留言信息',
    全部消息: '全部消息',
    已读消息: '已读消息',
    未读消息: '未读消息',
    标记已读: '标记已读',
    删除: '删除',
    暂无消息通知: '暂无消息通知',
    请勾选信息: '请勾选信息',
    请选择删除项需已读才能删除: '请选择删除项，需已读才能删除',
    待付款: '待付款',
    待发货: '待发货',
    待收货: '待收货',
    已完成: '已完成',
    订单号: '订单号',
    订单金额: ' 订单金额',
    已关闭: '已关闭',
    部分订单取消: '部分订单取消',
    部分退款完成: '部分退款完成',
    部分退款: '部分退款',
    部分收货: '部分收货',
    交易异常: '交易异常',
    部分发货: '部分发货',
    发货时间: '发货时间',
    退款成功: '退款成功',
    取消原因: '取消原因',
    暂无订单信息: '暂无订单信息',
    删除订单成功: '删除订单成功',
    订单已超时: '订单已超时',
    确认收货成功: '确认收货成功',
    取消订单成功: '取消订单成功',
    当前头像: '当前头像',
    点击上传: '点击上传',
    昵称: '昵称',
    保密: '保密',
    男: '男',
    女: '女',
    昵称不能为空: '昵称不能为空',
    退出登录: '退出登录',
    上传失败: '上传失败',
    上传头像图片只能是JPG格式: '上传头像图片只能是 JPG 格式!',
    上传头像图片大小不能超过2MB: '上传头像图片大小不能超过 2MB!',
    个人信息: '个人信息',
    我的订单: '我的订单',
    售后服务: '售后服务',
    地址管理: '地址管理',
    扫码支付: '扫码支付',
    消息通知: '消息通知',
    微信支付: '微信',
    交易单号: '交易单号',
    支付成功: '支付成功',
    支付失败: '支付失败',
    查看订单: '查看订单',
    返回首页: '返回首页',
    应付总额: '应付总额',
    请您在: '请您在',
    内完成支付否则订单会被自动取消: '内完成支付，否则订单会被自动取消',
    请您在x内完成支付否则订单会被自动取消: '请您在<span style="color: red;">{num}</span>内完成支付，否则订单会被自动取消',
    确定放弃支付吗: '确定放弃支付吗？',
    订单提交成功请尽快付款: '订单提交成功，请尽快付款！',
    选择以下支付方式付款: '选择以下支付方式付款',
    继续支付: '继续支付',
    残忍离开: '残忍离开',
    提示: '提示',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货:
        '线下支付不支持线上售后服务，如需售后请联系店铺。若已付款请耐心等待店铺确认收款并发货！',
    您可以参考如下线下付款方式: '您可以参考如下线下付款方式',
    支付凭证: '支付凭证',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持xxx格式图片不大于3M:
        '若您已按如上付款方式付款，请上传相关凭证，如转账截图等，支持jpg，png格式，图片不大于3M',
    订单已取消: '订单已取消',
    分: '分',
    秒: '秒',
    请选择付款方式: '请选择付款方式',
    实付款: '实付款',
    请在: '请在',
    您当前是线下支付请耐心等待店铺确认收款若:
        '您当前是线下支付，请耐心等待店铺确认收款！若',
    后店铺未确认收款订单将自动取消: '后店铺未确认收款，订单将自动取消!',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消: '您当前是线下支付，请耐心等待店铺确认收款！若<span style="color: red;">{num}</span>后店铺未确认收款，订单将自动取消!',
    退货申请中: '退货申请中',
    删除订单: '删除订单',
    确认收货: '确认收货',
    取消订单: '取消订单',
    去付款: '去付款',
    支付方式: '支付方式',
    支付宝: '支付宝',
    微信: '微信',
    线下支付: '线下支付',
    商品总额: '商品总额',
    运费: '运费',
    收货信息: '收货信息',
    创建时间: '创建时间',
    付款时间: '付款时间',
    订单取消时间: '订单取消时间',
    收件人: '收件人',
    地址: '地址',
    部分商品正在退款中: '部分商品正在退款中',
    已经有一个账户: '已经有一个账户？',
    天: '天',
    联系信息: '联系信息',
    收件地址: '收件地址',
    切换地址: '切换地址',
    电子邮件地址: '电子邮件地址',
    通过电子邮件向我发送新闻和优惠: '通过电子邮件向我发送新闻和优惠',
    姓名: '姓名',
    完整地址: '完整地址',
    电话: '电话',
    还没有收货地址哦: '还没有收货地址哦',
    添加地址: '添加地址',
    在线支付: '在线支付',
    商品: '商品',
    备注说明: '备注说明',
    订单: '订单',
    商品件数: '商品件数',
    商品总价: '商品总价',
    活动优惠: '活动优惠',
    优惠卷抵扣: '优惠卷抵扣',
    提交订单: '提交订单',
    库存: '库存',
    描述文字: '描述文字',
    发货地: '发货地',
    缺少库存: '缺少库存',
    添加购物车: '添加购物车',
    立即购买: '立即购买',
    此宝贝已下架: '此宝贝已下架',
    描述: '描述',
    添加成功: '添加成功',
    添加失败库存不足: '添加失败库存不足',
    请选择规格: '请选择规格',
    请输入商品名称: '请输入商品名称',
    综合排序: '综合排序',
    销量高低: '销量高低',
    价格高低: '价格高低',
    没有找到搜索的内容: '没有找到搜索的内容',
    选择地址: '选择地址',
    是否选择该地址: '是否选择该地址',
    选择成功: '选择成功!',
    未选择地址或没有地址: '未选择地址或没有地址',
    已取消: '已取消',
    操作: '操作',
    全选: '全选',
    已失效商品: '已失效商品',
    已下架: '已下架',
    删除选中商品: '删除选中商品',
    共: '共',
    共x件商品已选择x件: '共{num}件商品，已选择{num2}件',
    商品合计: ' 商品合计',
    下单结算: '下单结算',
    失效宝贝: '失效宝贝',
    删除失效商品: '删除失效商品',
    您的购物车还没有商品哟: '您的购物车还没有商品哟~',
    继续逛逛: '继续逛逛',
    全部商品: '全部商品',
    立即支付: '立即支付',
    个人中心: '个人中心',
    性别: '性别',
    确认已付款: '确认已付款',
    确定删除选中的商品吗: '确定删除选中的商品吗？',
    未发货: '未发货',
    已发货: '已发货',
    请输入下方图形验证码: '请输入下方图形验证码',
    看不清换一张: '看不清换一张',
    请勾选商品: '请勾选商品',
    KBZ支付: 'KBZPay',
    BCEL支付: 'BCEL',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名:
        '请输入留言内容，若您使用BCEL或KBZPay支付，请在此输入用来接收退款的银行卡号及户名',
    请输入留言内容: '请输入留言内容',
  语言: '语言',
  请选择语言: '请选择语言',
  货币: '货币',
  请选择展示货币: '请选择展示货币',
  查看支付是否成功: '查看支付是否成功>>>',
  查询: '查询',
  您未支付请重新支付: '您未支付，请重新支付',
  确定已输入接收退款的银行卡号和户名: '确定已输入接收退款的银行卡号和户名',
  绑定: '绑定',
  绑定邮箱: '绑定邮箱',
  分享店铺:'分享店铺',
  分享至:'分享至',
  海报:'海报',
  分享:'分享',
  推荐店铺给你:'推荐店铺给你',
  复制成功: '复制成功',
  保存: '保存',
  分享店铺至:'分享店铺至',

    我的钱包: '我的钱包',
    我的余额: '我的余额',
    提现记录: '提现记录',
    账户余额: '账户余额',
    提现中: '提现中',
    钱包服务: '钱包服务',
    提现: '提现',
    余额记录: '余额记录',
    收款管理: '收款管理',
    支付密码: '支付密码',
    提现币种: '提现币种',
    人民币: '人民币',
    缅币: '缅币',
    基普: '基普',
    收款方式: '收款方式',
    选择收款方式: '选择收款方式',
    银行卡: '银行卡',
    收款码: '收款码',
    时间: '时间',
    币种: '币种',
    当前没有余额记录: '当前没有余额记录!',
    当前没有提现记录: '当前没有提现记录',
    提现详情: '提现详情',
    到账方式: '到账方式',
    到账银行卡号: '到账银行卡号',
    银行名称: '银行名称',
    开户人: '开户人',
    开户支行: '开户支行',
    收款名称: '收款名称',
    提现金额: '提现金额',
    提现手续费: '提现手续费',
    审核信息: '审核信息',
    审核结果: '审核结果',
    打款信息: '打款信息',
    打款状态: '打款状态：',
    流水号: '流水号',
    打款凭证: '打款凭证',
    备注: '备注：',
    添加收款码: '添加收款码',
    收款码类型: '收款码类型',
    请选择收款码类型: '请选择收款码类型',
    支持币种: '支持币种',
    请输入收款名称: '请输入收款名称',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: '提示:只可上传一张图片，图片大小不超过5M，支 持jpg,png,jpeg格式文件上传的收款二维码需要清 晰，无遮挡。',
    请输入支付密码: '请输入支付密码',
    添加银行卡: '添加银行卡',
    请输入开户支行: '请输入开户支行',
    请输入开户人: '请输入开户人',
    请输入银行卡号: '请输入银行卡号',
    银行卡号: '银行卡号:',
    请选择银行: '请选择银行',
    国家: '国家:',
    银行卡管理: '银行卡管理',
    收款码管理: '收款码管理',
    还没有绑定银行卡: '还没有绑定银行卡!',
    还没有添加收款码: '还没有添加收款码!',
    确定删除银行卡吗: '确定删除银行卡吗？',
    确定删除收款码吗: '确定删除收款码吗？',
    请输入支付密码6位数字: '请输入支付密码，6位数字',
    确认支付密码: '确认支付密码',
    请再次输入支付密码: '请再次输入支付密码',
    确认新密码: '确认新密码',
    请输入新支付密码6位数字: '请输入新支付密码6位数字',
    新支付密码: '新支付密码',
    原支付密码: '原支付密码',
    请输入原支付密码: '请输入原支付密码',
    修改支付密码: '修改支付密码',
    您未设置支付密码请先设置: '您未设置支付密码，请先设置',
    去设置: '去设置',
    两次密码输入不一致: '两次密码输入不一致',
    设置成功: '设置成功',
    编辑银行卡: '编辑银行卡',
    请输入收款码名称: '请输入收款码名称',
    请上传收款码: '请上传收款码',
    编辑收款码: '编辑收款码',
    可提现金额: '可提现金额',
    到账银行卡: '到账银行卡',
    请选择银行卡: '请选择银行卡',
    请选择收款码: '请选择收款码',
    可提现: '可提现',
    全部提现: '全部提现',
    请输入提现金额: '请输入提现金额',
    实际到账金额: '实际到账金额',
    手续费: '手续费：',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: '每天最多提现{num}次，单次最高提现金额{icon}{money},最低提现金额{icon}{money2}',

    立即提现: '立即提现',
    请选择收款方式: '请选择收款方式',
    输入金额超过可提现余额: '输入金额超过可提现余额',
    单次最高提现xx: '单次最高提现{icon}{money}',
    日期选择: '日期选择',
    待审核: '待审核',
    待打款: '待打款',
    提现成功: '提现成功',
    提现失败: '提现失败',
    修改: '修改',
    查询中: '查询中',
    至: '至',
    开始日期: '开始日期',
    结束日期: '结束日期',
    记录时间: '记录时间',
    请选择币种: '请选择币种',
    余额数据: '余额数据',
    重置: '重置',
    变动理由: '变动理由',
    收款账号: '收款账号',
    提现状态: '提现状态',
    提现时间: '提现时间',
    请再次输入新密码: '请再次输入新密码',
    请输入新支付密码: '请输入新支付密码',
    请输入手机号: '请输入手机号',
    退款: '退款',
    消费: '消费',
    充值: '充值',
    打款说明: '打款说明',
    新增成功: '新增成功',
    暂无数据: '暂无数据',
    申请成功: '申请成功',
    收款类型: '收款类型',
    收款码名称: '收款码名称',
    通过: '通过',
    不通过: "不通过",
    打款成功: '打款成功',
    拒绝打款: '拒绝打款',
    订单ID: '订单ID',
    请选择支付货币: '请选择支付货币',
    请选择收货地址: '请选择收货地址',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit} {money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "首重{num}kg {currencyUnit} {money}，续重{num2}kg   {currencyUnit} {money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "首件{num3}件 {currencyUnit} {money3}，续件{num4}件 {currencyUnit} {money4}",
    支持配送区域: '（支持配送区域：{diqu}。）',
    配送费: '配送费',
    暂无配送区域: '暂无配送区域',
    该商品在当前地区暂不支持配送: '该商品在当前地区暂不支持配送',
    查看运费: '查看运费',
    运费方式: '运费方式',
    价格: '价格',
    请选择配送方式: '请选择配送方式',
    配送方式: '配送方式',
    配送费说明: '配送费说明',
    我明白了: '我明白了',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: '若多个商品选用同一个运费模板，则运费是按同一个运费方式的规则进行计算。',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: '若多个商品不是同一个运费模板，则运费是多个模板的价格之和。',
    快捷登录登录即表示同意协议: '快捷登录，登录即表示同意协议',
    打款中: '打款中',
    银行编码: '银行编码',
    加载中: '加载中',
    对不起您访问的店铺可能已被删除名称被更改或不存在: '对不起，您访问的店铺可能已被删除、名称被更改或不存在...',
    卢比支付: 'IDR支付',
    比索支付: 'PHP支付',
    泰铢支付: 'THB支付',
    越南盾支付: 'VND支付',
    设置密码: '设置密码',
    请输入大于1的正整数: '请输入大于1的正整数',
    当前仅支持xxx的金额: '当前仅支持{currency} {min}-{currency} {max}的金额',
    绑定手机号: '绑定手机号',
    请点击右上角: '请点击右上角',
    分享给好友或朋友圈: '分享给好友或朋友圈',
    促销广告: '促销广告',
    最近新品: '最近新品',
    更多: '更多',
    全部分类: '全部分类',
    推荐商品: '推荐商品',

//     1.6
    我的优惠券:'我的优惠券',
    历史记录:'历史记录',
    已使用:'已使用',
    已过期:'已过期',
    优惠券:'优惠券',
    领取:'领取',
    已领取:'已领取',
    有效期:'有效期：',
    活动优惠详情:'活动优惠详情',
    可用商品:'可用商品',
    单独购买:'单独购买',
    发起拼团:'发起拼团',
    拼团价:'拼团价',
    可参与的拼团:'可参与的拼团',
    '再邀X人即可拼单成功':'再邀{num}人即可拼单成功>',
    拼单详情:'拼单详情',
    待分享:'待分享',
    '若拼团失败，货款将原路退回':'若拼团失败，货款将原路退回',
    促销价:'促销价',
    距离结束时间:'距离结束时间',
    满XX减XX:"满{currencyUnit}{priceStart}减{currencyUnit}{reducePrice}{couponType==2?'%':''}",
    满XX减X:'满{currencyUnit}{priceStart}减{reducePrice}%',
    分享好友X人成团立省X:'分享好友<span style="color: #8D4BBB;">{num}人</span>成团，立省<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'分享好友',
    '这些人正在拼，立即参与 ':'这些人正在拼，立即参与 ',
    满x可用:'满{currencyUnit}{priceStart}可用',
    这些人正在拼立即参与:'这些人正在拼，立即参与 ',
    限领x张:'限领{receiveCount}张',
    满XX送赠品:'满{currencyUnit}{priceStart}送赠品',
    去使用:'去使用',
    销量:'销量',
    赠品:'赠品',
    限时优惠:'限时优惠',
    距结束:'距结束：',
    拼团好物:'拼团好物',
    当前优惠券可以购买以下商品:'当前优惠券可以购买以下商品:',
    以下商品:'以下商品',
    满XX减XX可用:"满{currencyUnit}{priceStart}减{currencyUnit}{reducePrice}可用",
    满XX减X可用:'满{currencyUnit}{priceStart}减{reducePrice}% 可用',
    再买x可用:'再买{currencyUnit}{difPrice}可用',
    合计:'合计',
    优惠:'优惠',
    去购物车:'去购物车',
    拼团:'拼团',
    满XX可用:'满{currencyUnit}{priceStart}可用',
    满减满赠:'满减满赠',
    成团发货:'成团发货',
    还差xx人成团:'还差<span style="color: #FF4545;">{num}</span>人成团',
    拼团结束:'拼团结束',
    拼团成功:'拼团成功',
    有成员未付款请等待付款成团:'有成员未付款，请等待付款成团',
    我正在参与的拼团:'我正在参与的拼团',
    满减:'满减',
    满赠:'满赠',
    规格选择:'规格选择',
    凑单:'凑单',
    切换其他优惠:'切换其他优惠',
    超过最大购买数:'超过最大购买数',
    商品限购x件:'商品限购{num}件',
    剩余:'剩余',
    结束:'结束',
    参与拼团:'参与拼团',
    拼单失败:'拼单失败',
    可用优惠券:'可用优惠券',
    详情:'详情',
    免费:'免费',
    已赠完:'已赠完',
    已领完:"已领完",
    拼单成功:'拼单成功',
    原价:'原价',
    此拼团已满请选择其他拼团:'此拼团已满，请选择其他拼团',
    请勾选删除商品: '请勾选删除商品',
    活动未开始:'活动未开始',
    已满X可减X:'已满{currencyUnit}{difPrice}可减{currencyUnit}{reducePrice}',
    已满X可减XX:'已满{currencyUnit}{difPrice}可减{reducePrice}%',
    再买X可减XX:'再买{currencyUnit}{difPrice}可减{reducePrice}%',
    再买X送赠品:'再买{currencyUnit}{difPrice}送赠品',
    已满X送赠品:'已送赠品',
    不可领取:'不可领取',
    限X件:'限{num}件',
    您还没有优惠券:'您还没有优惠券',
    x件: '{num}件',


    退货积分:'退货积分',
    积分:'积分',
    用户注册:'用户注册',
    手动增加:'手动增加',
    积分兑换退款:'积分兑换退款',
    下单购物:'下单购物',
    过期积分:'过期积分',
    兑换商品:'兑换商品',
    手动减少:'手动减少',
    订单退款:'订单退款',
    积分明细:'积分明细',
    已兑:'已兑',
    积分商城:'积分商城',
    规则:'规则',
    可用积分:'可用积分',
    冻结积分:'冻结积分',
    我的兑换:'我的兑换',
    售后:'售后',
    分销中心:'分销中心',
    立即兑换:'立即兑换',
    已兑完:'已兑完',
    "积分不足，无法兑换":'积分不足，无法兑换',
    兑换数量:'兑换数量',
    "限兑*件":'限兑{num}件',
    积分抵扣:'积分抵扣',
    "共*分":'共{num}分',
    全部订单:'全部订单',
    兑换须知:'兑换须知',
    已达兑换上限:'已达兑换上限',
    我的积分:'我的积分',
    积分规则:'积分规则',
    兑换时间:'兑换时间',

    我的佣金:'我的佣金',
    佣金数据:'佣金数据',
    分销申请协议:'分销申请协议',
    申请成为分销员:'申请成为分销员',
    "已申请，请等待审核":'已申请，请等待审核',
    "审核拒绝，继续申请":'审核拒绝，继续申请',
    可提佣金:'可提佣金',
    冻结佣金:'冻结佣金',
    分销订单:'分销订单',
    佣金明细:'佣金明细',
    我的下级:'我的下级',
    推广商品:'推广商品',
    推广店铺:'推广店铺',
    暂无记录:'暂无记录',
    一级佣金收入:'一级佣金收入',
    一级佣金退款:'一级佣金退款',
    二级佣金收入:'二级佣金收入',
    二级佣金退款:'二级佣金退款',
    下单用户:'下单用户',
    订单总价:'订单总价',
    分销利润层级:'分销利润层级',
    结算状态:'结算状态',
    佣金:'佣金',
    一级:'一级',
    二级:'二级',
    冻结中:'冻结中',
    已结算:'已结算',
    请输入用户昵称或手机号:'请输入用户昵称或手机号',
    二级团队:'二级团队',
    分享链接:'分享链接',
    可推广至:'可推广至',
    分享最高可赚x:'分享最高可赚{icon}{num}',
    分享推广:'分享推广',
    用户昵称或手机号:'用户昵称或手机号',
    复制:'复制',
    商品名称:'商品名称',
    最高可得:'最高可得',
    可获得积分:'可获得积分',
    总获佣金:'总获佣金',
    不能超过最小提现额度:'不能超过最小提现额度',
    普通订单:'普通订单',
    售货机:'售货机',
    订单类型:'订单类型',
    请选择类型:'请选择类型',
    '房间号/桌号':'房间号/桌号'
}
