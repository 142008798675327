<template>
  <div class="footlitle">
    <div class="box">
      <!-- <ul class="footlitle1">
            <li class="footlitleli">
            <p>我们的商店</p>
            <span>请随意参观我们的商店或与我们联系</span>
            <span> 南格兰德大街1401号 </span>
            <span>洛杉矶，约90015</span>
            <span>(213)748 - 2411</span>
            </li>
           <li>
            <p>博客文章</p>
            <span>条款和条件</span>
            <span> 常见问题解答 </span>
            <span>付款</span>
            <span>退款</span>
          </li>
           <li>
            <p>支持</p>
            <span>条款和条件</span>
            <span> 常见问题解答 </span>
            <span>付款</span>
            <span>退款</span>
          </li>
        </ul> -->
      <div class="sharBox" :style="!logo.copyrightInfo&&!kefuCont?.address&&kefuCont.email.length==0&&kefuCont.phone.length==0?'top:20px;':''">
        <div class="title">
          <div style="margin-right: 5px">{{ $t('分享店铺') }}</div>

          <div
            data-share="facebook"
            data-width="800"
            data-height="600"
            :data-title="logo.title"
            data-quote="Share quote"
            data-description="Share lalala"
            data-hashtag="#哇呗"
            :data-url="url"
            class="sharli button"
          >
            <img src="@/assets/Facebook.png" />
          </div>
          <div
            data-share="whatsapp"
            data-width="800"
            data-height="600"
            :data-title="logo.title"
            data-quote="Share quote"
            data-description="Share lalala"
            data-hashtag="#哇呗"
            :data-url="url"
            class="button sharli"
          >
            <img src="@/assets/whatsapp.png" />
          </div>
          <div
            data-share="twitter"
            data-width="800"
            data-height="600"
            :data-title="logo.title"
            data-quote="Share quote"
            data-description="Share lalala"
            data-hashtag="#哇呗"
            :data-url="url"
            class="button sharli"
          >
            <img src="@/assets/tuite.png" />
          </div>
          <div class="row-c sharli" @click="haibaoFx">
            <img src="@/assets/sharePoster.png" />
          </div>
          <div class="row-c sharli copy-button" @click="copyText">
            <img src="@/assets/lianjie.png" />
          </div>
          <div class="row-c sharli copy-button" v-if="is_wx" @click="wxShar">
            <img src="@/assets/wx.png" />
          </div>
        </div>
      </div>
      <div class="bottomtitle" v-if="logo.copyrightInfo">{{ logo.copyrightInfo }}</div>
      <div v-if="logo.themeId!=1" class="row-c row-d">
        <template  v-if="kefuCont?.address">
          <div
              class="row-s"
              style="color:#fff;font-size:14px;"
          >
            <i
                class="el-icon-location-outline"
                style="color:#fff;margin-right:4px;margin-top:2px;"
            ></i
            >{{ kefuCont?.address}}
          </div>
        </template>
<!--        <div-->
<!--            class="row-s"-->
<!--            style="color:#fff;font-size:14px;"-->
<!--            v-else-->
<!--        >-->
<!--          <i-->
<!--              class="el-icon-location-outline"-->
<!--              style="color:#fff;margin-right:4px;margin-top:2px;"-->
<!--          ></i-->
<!--          >- -->
<!--        </div>-->

        <div class="row-s" style="margin-top:5px;">
          <template  v-if="kefuCont?.phone.length>0">
            <div
                class="row-s"
                style="color:#fff;font-size:14px;margin-left:10px;"
                v-for="(item, index) in kefuCont?.phone"
                :key="index"
            >
              <i
                  class="el-icon-phone-outline"
                  style="color:#fff;margin-right:4px;margin-top:2px;"
              ></i
              >{{ item?item:'-' }}
            </div>
          </template>
<!--          <div-->
<!--              v-else-->
<!--              class="row-s"-->
<!--              style="color:#fff;font-size:14px;"-->
<!--          >-->
<!--            <i-->
<!--                class="el-icon-phone-outline"-->
<!--                style="color:#fff;margin-right:4px;margin-top:2px;"-->
<!--            ></i-->
<!--            >- -->
<!--          </div>-->
          <template v-if="kefuCont?.email.length>0">
            <div
                class="row-s"
                style="color:#fff;font-size:14px;margin-left:10px;"
                v-for="(item, index) in kefuCont?.email"
                :key="index"
            >
              <i
                  class="el-icon-message"
                  style="color:#fff;margin-right:4px;margin-top:2px;"
              ></i
              >{{ item?item:'-' }}
            </div>
          </template>
<!--          <div-->
<!--              v-else-->
<!--              class="row-s"-->
<!--              style="color:#fff;font-size:14px;"-->
<!--          >-->
<!--            <i-->
<!--                class="el-icon-message"-->
<!--                style="color:#fff;margin-right:4px;margin-top:2px;"-->
<!--            ></i-->
<!--            >- -->
<!--          </div>-->

        </div>
      </div>
    </div>
    <div class="jszc row-c row-d" @click="routeClick()">
      <img src="@/assets/pingtaiLog.png" style="width: 200px;height: 40px;margin-bottom: 5px">
      <div style="color: #999999"  v-if="infomation.language?.value">{{ infomation.language?.value }}</div>

    </div>
    <div class="weixinBox" v-if="weixinShow">
      <div class="mask" @click="weixinShow = false"></div>
      <div class="weixinJt row-c row-d">
        <img src="@/assets/weixinJT.png" />
        <div class="labe">{{ $t('请点击右上角') }}</div>
        <div class="labe">{{ $t('分享给好友或朋友圈') }}</div>
      </div>
    </div>
    <div class="ewm" v-if="show">
      <div class="mask" @click="show = false"></div>
      <div class="pop">
        <div style="position: relative;">
          <!-- <img src="@/assets/close.png" class="close" @click="show = false" />
           -->
          <i class="el-icon-close close" @click="show = false"></i>
          <div class="content">
            <div class="spTitle">{{ logo.title }}</div>
            <div class="tip">{{ $t('推荐店铺给你') }}</div>
            <div class="ewmBox">
              <canvas
                id="QRCode_header"
                style="width: 100px; height: 100px;"
              ></canvas>
            </div>
            <div class="btnBox" @click="savePoster">
              <img src="@/assets/uaptdate.png" />{{ $t('保存') }}
              <!-- <div class="btn"  @click="show=false">取消</div>
            <div class="btn2">保存</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position:fixed;left:9999px;">
      <div id="poster-container">
        <div class="ewm">
          <div class="pop2">
            <div style="position: relative; background: #fff;">
              <div class="content">
                <div class="spTitle">{{ logo.title }}</div>
                <div class="tip">{{ $t('推荐店铺给你') }}</div>
                <div class="ewmBox">
                  <canvas
                    id="QRCode_header2"
                    style="width: 100px; height: 100px;"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStoreIndexInfo } from '@/API/listFront'
import Share from '@/utils/share'
import QRCode from 'qrcode'
import html2canvas from 'html2canvas'
import weixinModule from '@/utils/publick'
import {StoreFindContact} from "@/API/Language";
export default {
  name: 'footerBox',
  data () {
    return {
      titles: '',
      url: window.location.href,
      endPage: '',
      endPageUrl: '',
      abox: true,
      logo: {},
      qrUrl: location.href,
      show: false,
      platform: {},
      infomation: {},
      is_wx: /MicroMessenger/i.test(window.navigator.userAgent),
      weixinShow: false,
      kefuCont: {
        phone: [],
        email: [],
        address:''
      },
    }
  },
  created () {
    if (location.pathname !== '/404' || localStorage.getItem('storeID')) {
      this.logotable()
      this.kefu()
    }

  },
  mounted () {
    this.url = this.qrUrl =
      window.location.origin +
      '/' +
      '?storeId=' +
      localStorage.getItem('storeID') +
      '&lang=' +
      localStorage.getItem('lang') +
      '&currency=' +
      localStorage.getItem('currency')
    if(localStorage.getItem('userData')){
      this.url=this.qrUrl=this.url+'&parentUserId='+ JSON.parse(localStorage.getItem('userData')).id
    }
    Share.init()
    let that = this
    window.onresize = function windowResize () {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      let w = window.innerWidth
      let h = 600
      let a = false
      if (w > 767) {
        h = 600
      } else {
        h = 250
        a = true
      }
      that.abox = a
      that.hdgd = h + 'px'
    }
  },
  methods: {
    async kefu () {
      let res = await StoreFindContact({
        storeId: localStorage.getItem('storeID')
      })
      let kefu = { phone: [], email: [],address:'' }

      if(res.data?.phone){
        kefu.phone=JSON.parse(res.data.phone)
      }
      if(res.data?.email){
        kefu.email=JSON.parse(res.data.email)
      }
      if(res.data?.address){
        let lang=localStorage.getItem('lang')
        let address=JSON.parse(res.data.address)[lang]
        kefu.address=address
        console.log(address,lang,'我地址呢')
      }
      this.kefuCont=kefu
      localStorage.setItem('kefu', JSON.stringify(kefu))
    },
    wxShar () {
      this.weixinShow = true
      weixinModule.weiXinShareFn(
        this.logo.title,
        window.$i18n.t('推荐店铺给你'),
        this.logo?.storeLogo
      )
    },
    copyText () {
      let inputDom = document.createElement('textarea') // js创建一个文本框
      document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
      inputDom.value = this.url //将需要复制的内容赋值到创建的文本框中
      inputDom.select() //选中文本框中的内容
      inputDom.focus()
      document.execCommand('copy') //执行复制操作
      document.body.removeChild(inputDom) //最后移出
      this.$message.success(window.$i18n.t('复制成功'))
    },
    savePoster () {
      // 获取海报容器的 DOM 元素
      const posterContainer = document.getElementById('poster-container')
      // 使用 html2canvas 将海报容器绘制为 Canvas
      html2canvas(posterContainer).then(canvas => {
        // 转换为图像
        const dataUrl = canvas.toDataURL('image/png')

        // 创建一个隐藏的链接
        const link = document.createElement('a')
        link.setAttribute('download', 'poster.png')
        link.setAttribute('href', dataUrl)

        // 触发点击事件下载图像
        link.click()
      })
    },
    drawImage () {
      const img = new Image()

      // img.src = this.list[0].imgUrl
      // img.width='300'
      // img.height='200'
      img.style = 'width:300px;height:200px;border-radius: 10px 10px 0 0;'
      img.setAttribute('crossOrigin', 'anonymous')

      img.onload = function () {
        const canvas = document.getElementById('posterCanvas')
        const context = canvas.getContext('2d')
        context.drawImage(img, 0, 0, 300, 200) // 根据需要设置绘制的位置和大小
      }
    },
    haibaoFx () {
      this.show = true
      this.visible = false
      setTimeout(() => {
        this.getQRCode()
        this.drawImage()
      }, 100)
    },
    getQRCode () {
      //生成的二维码为URL地址js

      let opts = {
        errorCorrectionLevel: 'H', //容错级别
        type: 'image/png', //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        width: 100, //宽
        height: 100, //高
        text: window.location.href, //二维码内容
        color: {
          dark: '#333333', //前景色
          light: '#fff' //背景色
        }
      }

      let msg = document.getElementById('QRCode_header')
      let msg2 = document.getElementById('QRCode_header2')

      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this.qrUrl, opts, error => {
        if (error) {
          this.$message.error('二维码加载失败')
        }
      })
      QRCode.toCanvas(msg2, this.qrUrl, opts, error => {
        if (error) {
          this.$message.error('二维码加载失败')
        }
      })
    },
    // 获取店铺logo
    async logotable () {
      let data1 = {
        storeId: localStorage.getItem('storeID')
      }
      let { data } = await getStoreIndexInfo(data1)
      this.logo = data.storeIndexInfo
      this.platform = data.TECHNICAL
      this.infomation = data.INFORMATION
    },
    routeClick () {
      // if (this.abox) {
      //   return this.$message({
      //     showClose: true,
      //     message: "电脑端浏览效果更佳",
      //     type: "success",
      //   });
      // }
      // 通过此方法可以使用
      if (this.infomation.technical.agreementText) {
        if (this.isValidUrl(this.infomation.technical.agreementText)) {
          window.open(this.infomation.technical.agreementText)
        } else {
          console.error('无效的路径:', this.infomation.technical.agreementText)
          // 可以选择进行其他处理或者提示给用户
        }
      }

      // window.location.href = this.logo.endPageUrl
    },
    isValidUrl (string) {
      try {
        new URL(string)
        return true
      } catch (_) {
        return false
      }
    },
    handleSelect (index) {
      localStorage.setItem('menutable', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.weixinBox {
  position: relative;

  .mask {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 17;
  }
  .weixinJt {
    position: fixed;
    z-index: 18;
    right: 50px;
    top: 20px;
    // position: absolute;
    img {
      width: 40px;
      height: 80px;
    }
    .labe {
      margin: 10px 0 0;
      font-size: 18px;
      color: #fff;
    }
  }
}
.ewm {
  position: relative;
  background: #fff;
  border-radius: 10px;
  .mask {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 17;
  }
  .pop2 {
    background: #fff;
    border-radius: 10px;
    // width: 550px;
    box-shadow: 0 2px 10px 0 rgba(10, 5, 74, 0.3);

    .close {
      position: absolute;
      top: 8px;
      right: 5px;
      width: 25px;
      height: 25px;
      z-index: 1;
    }
    .imgShop {
      width: 300px;
      height: 200px;
      border-radius: 10px 10px 0 0;
    }
    .content {
      background: #fff;
      padding: 15px 13px;
      .moneys {
        font-size: 18px;
        color: #ff4545;
        font-weight: bold;
        display: flex;
        align-items: center;
        .moneys2 {
          font-size: 12px;
          color: #999999;
          font-weight: 500;
          margin-left: 13px;
        }
      }
      .spTitle {
        margin-top: 5px;
        width: 270px;
        font-size: 14px;
        color: #384358;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 2; //显示的行
      }
      .tip {
        font-size: 13px;
        color: #999999;
      }
      .ewmBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
      }
      .btnBox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px 10px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 12px;
        }
        color: #6e4faa;
        font-size: 16px;
        .btn {
          width: 120px;
          height: 44px;
          text-align: center;
          border: 1px solid #8d4bbb;
          border-radius: 8px;
          line-height: 44px;
          color: #6e4faa;
        }
        .btn2 {
          width: 120px;
          height: 44px;
          text-align: center;
          border-radius: 8px;
          line-height: 44px;
          background: #6e4faa;
          color: #fff;
        }
      }
    }
  }
  .pop {
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 10px;
    // width: 550px;
    box-shadow: 0 2px 10px 0 rgba(10, 5, 74, 0.3);

    .close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 25px;
      height: 25px;
      z-index: 1;
    }
    .tip {
      font-size: 13px;
      color: #999999;
    }
    .imgShop {
      width: 300px;
      height: 200px;
      border-radius: 10px 10px 0 0;
    }
    .content {
      padding: 15px 13px;
      .moneys {
        font-size: 18px;
        color: #ff4545;
        font-weight: bold;
        display: flex;
        align-items: center;
        .moneys2 {
          font-size: 12px;
          color: #999999;
          font-weight: 500;
          margin-left: 13px;
        }
      }
      .spTitle {
        margin-top: 5px;
        width: 270px;
        font-size: 14px;
        color: #384358;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 2; //显示的行
      }
      .ewmBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
      }
      .btnBox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px 10px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 12px;
        }
        color: #6e4faa;
        font-size: 16px;
        .btn {
          width: 120px;
          height: 44px;
          text-align: center;
          border: 1px solid #8d4bbb;
          border-radius: 8px;
          line-height: 44px;
          color: #6e4faa;
        }
        .btn2 {
          width: 120px;
          height: 44px;
          text-align: center;
          border-radius: 8px;
          line-height: 44px;
          background: #6e4faa;
          color: #fff;
        }
      }
    }
  }
}
.sharBox {
  font-size: 12px;
  color: #ffffff;
  position: absolute;
  right: 30px;
  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .sharli {
      margin-right: 5px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
.footlitle {
  // padding-top: 30px;
  background-color: #2a2d34;

  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 100px;
    padding: 30px 0;
  }

  .footlitle1 {
    display: flex;

    li {
      margin-left: 250px;
      list-style: none;
      width: 30%;

      p {
        font-size: 25px;
        font-family: 'Microsoft YaHei';
        font-weight: 400;
        color: #ffffff;
        line-height: 60px;
      }

      span {
        display: block;
        font-size: 16px;
        font-family: 'Microsoft YaHei';
        font-weight: normal;
        color: #7e7f84;
        line-height: 21px;
      }
    }
  }

  .bottomtitle {
    // margin-top: 30px;
    font-size: 16px;
    font-family: 'Microsoft YaHei';
    font-weight: normal;
    color: #ffffff;
    margin-bottom: 5px;
  }

  .jszc {
    //border-top: 1px solid #7e7f84;
    font-size: 12px;
    font-family: 'Microsoft YaHei';
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    padding: 5px 0;

    span {
      color: #15a8ac;
    }
  }
}

.footlitle li:nth-child(1) {
  margin-left: 0px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
