<template>
  <div>
    <div class="behome">
      <div class="heardswiper">
        <el-carousel class="carousel" :height="hdgd" :interval="stimen">
          <el-carousel-item
              class="itemcarousel"
              v-for="item in Bannerlist"
              :key="item.id"
          >
            <el-image
                class="imgpc"
                :src="$getImageUrl(item.imgUrl, 1080, 550, 'webp')"
                @click="pcbanner(item)"
                fit=""
            />
          </el-carousel-item>
        </el-carousel>

        <van-swipe
            class="my-swipe"
            :autoplay="5000"
            indicator-color="white"
            ref="swipe"
        >
          <van-swipe-item
              v-for="item in Bannerlist"
              :key="item.id"
              @click.stop="H5banner(item)"
          >
            <img :src="$getImageUrl(item.imgUrl, 0, 250, 'webp')"/>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="product" v-if="storeIndexInfo?.storeIndexInfo?.themeId == 1">
        <template v-if="limitedTimeActivities?.length>0">
          <div class="limitedTimeDiscount" v-for="(item,index) in limitedTimeActivities" :key="index" v-if="item.userDiscountGoodsDTOList?.records?.length>0">
            <div class="row-b">
              <div class="time row-s">
                <div class="label">
                  <div class="text">{{$t('限时优惠')}}</div>
                  <div class="line"></div>
                </div>
                <countdown :time="item.timeDiff" :nowTime="item.nowTime"></countdown>

              </div>
              <div class="more" @click="goActive(item.id,'limitedTimeDiscount')">{{$t('更多')}}
                <i class="el-icon-arrow-right"></i>

              </div>
            </div>
            <ul class="limitedTimeList">
              <li
                  v-for="(it,idx) in item.userDiscountGoodsDTOList.records"
                  :key="it.id"
                  class="productli"
                  @click="clickproduct(it.id,'','','home')"
              >
                <div class="productlin1">
                  <img :src="$getImageUrl(it.goodImg, 0, 240, 'webp')" alt=""/>
                </div>
                <div class="productlin2">
                  <div>
                    <span>{{ it.goodsName }}</span>
                  </div>
                </div>
                <div class="productlin3">
              <span class="mony">{{ currencyUnit }}</span
              >{{ it?.moneyArr[0] }}<span class="mony" v-if="it?.moneyArr[1]">.{{ it?.moneyArr[1] }}</span>
                  <!--{{ $formatNumber(it.discountPrice) }}-->
                </div>
                <div class="productlin4 row-s">
<!--                  <span class="mony">{{ currencyUnit }}</span>-->
                  <div class="oldMoney">{{ currencyUnit }}{{ it?.sellingPrice }}
                    <!--{{ $formatNumber(it.sellingPrice) }}-->
                  </div>
<!--                  <span class="mony" v-if="it?.moneyArr2[1]">.{{ it?.moneyArr2[1] }}</span>-->
                  <div class="discountes">-{{ parseInt(it.discount * 1) }}%</div>
                </div>
                <div class="productlin5">
                  <div class="salesVolume">{{ $t('销量') }} {{ it.sales ? it.sales : 0 }}</div>
                </div>
              </li>
              <li
                  v-show="list.length % 2 !== 0"
                  style="opacity: 0"
                  class="productli"
              ></li>
            </ul>

          </div>
        </template>
        <template  v-if="fullReductionFullGift?.length>0">
         <div class="limitedTimeDiscount" v-for="(item,index) in fullReductionFullGift" :key="index" v-if="item.applicationFullReductionGoodsDTOList.records?.length>0">
           <div class="row-b">
             <div class="time row-s">
               <div class="label">
                 <div class="text" v-if="item.type==1">{{$t('满XX减XX',{currencyUnit:item.applicationFullReductionPriceDTOList[0].icon,priceStart:item.applicationFullReductionPriceDTOList[0].fullPrice,reducePrice:item.applicationFullReductionPriceDTOList[0].reducePrice})}}</div>
                 <div class="text" v-if="item.type==2">{{$t('满XX送赠品',{currencyUnit:item.applicationFullReductionPriceDTOList[0].icon,priceStart:item.applicationFullReductionPriceDTOList[0].fullPrice})}}</div>
                 <div class="line"></div>
               </div>
               <countdown :time="item.timeDiff" :nowTime="item.nowTime"></countdown>

             </div>
             <div class="more" @click="goActive(item.id,'fullReductionFullGift')">{{$t('更多')}}
               <i class="el-icon-arrow-right"></i>

             </div>
           </div>
           <ul class="limitedTimeList">
             <li
                 v-for="(it,idx) in item.applicationFullReductionGoodsDTOList.records"
                 :key="it.id"
                 class="productli"
                 @click="clickproduct(it.id,'','','home')"
             >
               <div class="productlin1">
                 <img :src="$getImageUrl(it.goodImg, 0, 240, 'webp')" alt=""/>
               </div>
               <div class="productlin2">
                 <div>
                   <span>{{ it.goodsName }}</span>
                 </div>
               </div>
               <div class="productlin3">
              <span class="mony">{{ currencyUnit }}</span
              >{{ it?.moneyArr[0] }}<span class="mony" v-if="it?.moneyArr[1]">.{{ it?.moneyArr[1] }}</span>
                 <!--{{ $formatNumber(it.discountPrice) }}-->
               </div>
               <div class="productlin4 row-s" v-if="it?.moneyArr2">
                 <span class="mony">{{ currencyUnit }}</span>
                 <div class="oldMoney">{{ it?.moneyArr2[0] }}
                   <!--{{ $formatNumber(it.sellingPrice) }}-->
                 </div>
                 <span class="mony" v-if="it?.moneyArr2[1]">.{{ it?.moneyArr2[1] }}</span>
                 <div class="discountes">-{{ parseInt(it.discount * 1) }}%</div>
               </div>
               <div class="productlin5">
                 <div class="salesVolume">{{ $t('销量') }} {{ it.sales ? it.sales : 0 }}</div>
               </div>
             </li>
             <li
                 v-show="list.length % 2 !== 0"
                 style="opacity: 0"
                 class="productli"
             ></li>
           </ul>

         </div>
       </template>
        <template v-if="teamwork?.length>0">
          <div class="limitedTimeDiscount" v-for="(item,index) in teamwork" :key="index" v-if="item.userGroupGoodsDTOIPage?.records?.length>0">
            <div class="row-b">
              <div class="time row-s">
                <div class="label">
                  <div class="text">{{$t('拼团')}}</div>
                  <div class="line"></div>
                </div>
                <countdown :time="item.timeDiff" :nowTime="item.nowTime"></countdown>

              </div>
              <div class="more" @click="goActive(item.id,'teamwork')">{{$t('更多')}}
                <i class="el-icon-arrow-right"></i>

              </div>
            </div>
            <ul class="limitedTimeList">
              <li
                  v-for="(it,idx) in item.userGroupGoodsDTOIPage.records"
                  :key="it.id"
                  class="productli"
                  @click="clickproduct(it.id,'teamwork',item.id,'home')"
              >
                <div class="productlin1">
                  <img :src="$getImageUrl(it.goodImg, 0, 240, 'webp')" alt=""/>
                </div>
                <div class="productlin2">
                  <div>
                    <span>{{ it.goodsName }}</span>
                  </div>
                </div>
                <div class="productlin3">
              <span class="mony">{{ currencyUnit }}</span
              >{{ it?.moneyArr[0] }}<span class="mony" v-if="it?.moneyArr[1]">.{{ it?.moneyArr[1] }}</span>
                  <!--{{ $formatNumber(it.discountPrice) }}-->
                </div>
                <div class="productlin4 row-s" style="text-decoration: line-through;">
<!--                  <span class="mony">{{ currencyUnit }}</span>-->
                  <div class="oldMoney">{{ currencyUnit }}{{ it?.sellingPrice }}
                    <!--{{ $formatNumber(it.sellingPrice) }}-->
                  </div>
<!--                  <span class="mony" v-if="it?.moneyArr2[1]">.{{ it?.moneyArr2[1] }}</span>-->
<!--                  <div class="discountes">-{{ parseInt(it.discount * 1) }}%</div>-->
                </div>
                <div class="productlin5">
                  <div class="salesVolume">{{ $t('销量') }} {{ it.num ? it.num : 0 }}</div>
                </div>
              </li>
              <li
                  v-show="list.length % 2 !== 0"
                  style="opacity: 0"
                  class="productli"
              ></li>
            </ul>

          </div>
        </template>
        <div class="productheard">
          <div class="child1"></div>
          <div class="child2">
            <p>{{ $t('推荐产品') }}</p>
          </div>
          <div class="child3"></div>
        </div>
        <ul class="productlist">
          <li
              v-for="item in list"
              :key="item.id"
              class="productli"
              @click="clickproduct(item.id)"
          >
            <div class="productlin1">
              <img :src="$getImageUrl(item.goodImg, 0, 240, 'webp')" alt=""/>
            </div>
            <div class="productlin2">
              <div>
                <span>{{ item.title }}</span>
              </div>
            </div>
            <div class="productlin3">
              <span class="mony">{{ currencyUnit }} </span
              ><span class="mony2">{{ currencyUnit }} </span
            >{{ $formatNumber(item.price) }}
            </div>
          </li>
          <li
              v-show="list.length % 2 !== 0"
              style="opacity: 0"
              class="productli"
          ></li>
        </ul>
      </div>
    </div>
    <div class="prodBox" v-if="storeIndexInfo?.storeIndexInfo?.themeId == 2">
      <div class="AllCategories">
        <div class="label">{{ $t('全部分类') }}</div>
        <div class="list">
          <div
              class="li"
              v-for="(item, index) in classificationArray"
              :key="index"
              @click="goClassification(item.id)"
          >
            <img :src="item.classUrl"/>
            <div class="txt">{{ item.nameZh }}</div>
          </div>
        </div>
      </div>
      <template v-if="limitedTimeActivities?.length>0">
        <div class="limitedTimeDiscount" v-for="(item,index) in limitedTimeActivities" :key="index" v-if="item.userDiscountGoodsDTOList?.records?.length>0">
          <div class="row-b">
            <div class="time row-s">
              <div class="label">
                <div class="text">{{$t('限时优惠')}}</div>
                <div class="line"></div>
              </div>
              <countdown :time="item.timeDiff" :nowTime="item.nowTime"></countdown>

            </div>
            <div class="more" @click="goActive(item.id,'limitedTimeDiscount')">{{$t('更多')}}
              <i class="el-icon-arrow-right"></i>

            </div>
          </div>
          <ul class="limitedTimeList">
            <li
                v-for="(it,idx) in item.userDiscountGoodsDTOList.records"
                :key="it.id"
                class="productli"
                @click="clickproduct(it.id,'','','home')"
            >
              <div class="productlin1">
                <img :src="$getImageUrl(it.goodImg, 0, 240, 'webp')" alt=""/>
              </div>
              <div class="productlin2">
                <div>
                  <span>{{ it.goodsName }}</span>
                </div>
              </div>
              <div class="productlin3">
              <span class="mony">{{ currencyUnit }}</span
              >{{ it?.moneyArr[0] }}<span class="mony" v-if="it?.moneyArr[1]">.{{ it?.moneyArr[1] }}</span>
                <!--{{ $formatNumber(it.discountPrice) }}-->
              </div>
              <div class="productlin4 row-s">
                <!--                  <span class="mony">{{ currencyUnit }}</span>-->
                <div class="oldMoney">{{ currencyUnit }}{{ it?.sellingPrice }}
                  <!--{{ $formatNumber(it.sellingPrice) }}-->
                </div>
                <!--                  <span class="mony" v-if="it?.moneyArr2[1]">.{{ it?.moneyArr2[1] }}</span>-->
                <div class="discountes">-{{ parseInt(it.discount * 1) }}%</div>
              </div>
              <div class="productlin5">
                <div class="salesVolume">{{ $t('销量') }} {{ it.sales ? it.sales : 0 }}</div>
              </div>
            </li>
            <li
                v-show="list.length % 2 !== 0"
                style="opacity: 0"
                class="productli"
            ></li>
          </ul>

        </div>
      </template>
      <template v-if="fullReductionFullGift?.length>0">
        <div class="limitedTimeDiscount" v-for="(item,index) in fullReductionFullGift" :key="index" v-if="item.applicationFullReductionGoodsDTOList.records?.length>0">
          <div class="row-b">
            <div class="time row-s">
              <div class="label">
                <div class="text" v-if="item.type==1">{{$t('满XX减XX',{currencyUnit:item.applicationFullReductionPriceDTOList[0].icon,priceStart:item.applicationFullReductionPriceDTOList[0].fullPrice,reducePrice:item.applicationFullReductionPriceDTOList[0].reducePrice})}}</div>
                <div class="text" v-if="item.type==2">{{$t('满XX送赠品',{currencyUnit:item.applicationFullReductionPriceDTOList[0].icon,priceStart:item.applicationFullReductionPriceDTOList[0].fullPrice})}}</div>
                <div class="line"></div>
              </div>
              <countdown :time="item.timeDiff" :nowTime="item.nowTime"></countdown>

            </div>
            <div class="more" @click="goActive(item.id,'fullReductionFullGift')">{{$t('更多')}}
              <i class="el-icon-arrow-right"></i>

            </div>
          </div>
          <ul class="limitedTimeList">
            <li
                v-for="(it,idx) in item.applicationFullReductionGoodsDTOList.records"
                :key="it.id"
                class="productli"
                @click="clickproduct(it.id,'','','home')"
            >
              <div class="productlin1">
                <img :src="$getImageUrl(it.goodImg, 0, 240, 'webp')" alt=""/>
              </div>
              <div class="productlin2">
                <div>
                  <span>{{ it.goodsName }}</span>
                </div>
              </div>
              <div class="productlin3">
              <span class="mony">{{ currencyUnit }}</span
              >{{ it?.moneyArr[0] }}<span class="mony" v-if="it?.moneyArr[1]">.{{ it?.moneyArr[1] }}</span>
                <!--{{ $formatNumber(it.discountPrice) }}-->
              </div>
              <div class="productlin4 row-s" v-if="it?.moneyArr2">
                <span class="mony">{{ currencyUnit }}</span>
                <div class="oldMoney">{{ it?.moneyArr2[0] }}
                  <!--{{ $formatNumber(it.sellingPrice) }}-->
                </div>
                <span class="mony" v-if="it?.moneyArr2[1]">.{{ it?.moneyArr2[1] }}</span>
                <div class="discountes">-{{ parseInt(it.discount * 1) }}%</div>
              </div>
              <div class="productlin5">
                <div class="salesVolume">{{ $t('销量') }} {{ it.sales ? it.sales : 0 }}</div>
              </div>
            </li>
            <li
                v-show="list.length % 2 !== 0"
                style="opacity: 0"
                class="productli"
            ></li>
          </ul>

        </div>
      </template>
      <template v-if="teamwork?.length>0">
        <div class="limitedTimeDiscount" v-for="(item,index) in teamwork" :key="index" v-if="item.userGroupGoodsDTOIPage?.records?.length>0">
          <div class="row-b">
            <div class="time row-s">
              <div class="label">
                <div class="text">{{$t('拼团')}}</div>
                <div class="line"></div>
              </div>
              <countdown :time="item.timeDiff" :nowTime="item.nowTime"></countdown>

            </div>
            <div class="more" @click="goActive(item.id,'teamwork')">{{$t('更多')}}
              <i class="el-icon-arrow-right"></i>

            </div>
          </div>
          <ul class="limitedTimeList">
            <li
                v-for="(it,idx) in item.userGroupGoodsDTOIPage.records"
                :key="it.id"
                class="productli"
                @click="clickproduct(it.id,'teamwork',item.id,'home')"
            >
              <div class="productlin1">
                <img :src="$getImageUrl(it.goodImg, 0, 240, 'webp')" alt=""/>
              </div>
              <div class="productlin2">
                <div>
                  <span>{{ it.goodsName }}</span>
                </div>
              </div>
              <div class="productlin3">
              <span class="mony">{{ currencyUnit }}</span
              >{{ it?.moneyArr[0] }}<span class="mony" v-if="it?.moneyArr[1]">.{{ it?.moneyArr[1] }}</span>
                <!--{{ $formatNumber(it.discountPrice) }}-->
              </div>
              <div class="productlin4 row-s" style="text-decoration: line-through;">
                <div class="oldMoney">{{ currencyUnit }}{{ it?.sellingPrice }}
                </div>
              </div>
              <div class="productlin5">
                <div class="salesVolume">{{ $t('销量') }} {{ it.num ? it.num : 0 }}</div>
              </div>
            </li>
            <li
                v-show="list.length % 2 !== 0"
                style="opacity: 0"
                class="productli"
            ></li>
          </ul>

        </div>
      </template>
      <div class="NewProducts">
        <div class="label">{{ $t('最近新品') }}</div>
        <div class="list">
          <div
              class="li"
              v-for="(item, index) in latestProductsList"
              :key="index"
              @click="clickproduct(item.id)"
          >
            <div v-if="item.price">
              <img :src="item.goodImg"/>
              <div class="productName">{{ item.title }}</div>
              <div class="txt">
                {{ item.price.icon }}{{ item.price.goodsPrice }}
              </div>
              <div class="underlinedPrice" v-if="item.price.originalPrice&&item.isDistribute!=1">
                {{ item.price.icon }}{{ item.price.originalPrice }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="NewProducts">
        <div class="label">{{ $t('推荐商品') }}</div>
        <ul class="productlist">
          <li
              v-for="item in list"
              :key="item.id"
              class="productli"
              @click="clickproduct(item.id)"
          >
            <div class="productlin1">
              <img :src="$getImageUrl(item.goodImg, 0, 240, 'webp')" alt=""/>
            </div>
            <div class="productlin2">
              <div>
                <span>{{ item.title }}</span>
              </div>
            </div>
            <div class="productlin3">
              <span class="mony">{{ currencyUnit }} </span
              ><span class="mony2">{{ currencyUnit }} </span
            >{{ $formatNumber(item.price) }}
            </div>
          </li>
          <li
              v-show="list.length % 2 !== 0"
              style="opacity: 0"
              class="productli"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listFrontGoodsUser,
  listStoreBanner,
  updateStoreBannerIncrement4Id,
  getStoreIndexInfo,
  getStoreClassification,
  getLatestProducts
} from '@/API/listFront'
import Buss from '@/utils/buss'
import {mapGetters} from 'vuex'
import Countdown from "@/components/countdown.vue";

export default {
  name: 'myHome',
  components: {Countdown},
  data() {
    return {
      abox: true,
      stimen: 3000,
      hdgd: '440px',
      list: [],
      Bannerlist: [],
      listext: '',
      classificationArray: [],
      latestProductsList: [],
      storeIndexInfo: {},
      limitedTimeActivities: [],
      teamwork:[],
      fullReductionFullGift:[],
    }
  },
  computed: {
    ...mapGetters(['currencyUnit'])
  },
  mounted() {
    let that = this
    window.onresize = function windowResize() {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      let w = window.innerWidth
      let h = 600
      let a = false
      if (w > 767) {
        h = 600
      } else {
        h = 250
        a = true
      }
      that.abox = a
      that.hdgd = h + 'px'
    }
  },
  created() {
    this.$store.dispatch('user/Bannergoods', '')
    this.$store.dispatch('user/Bannerid', '')
    this.logotable()
    this.gainlist()
    setTimeout(() => {
      this.$refs.swipe.resize()
    }, 1000)
    if(this.$route.query.parentUserId){
      localStorage.setItem('parentUserId',this.$route.query.parentUserId)
    }
  },
  methods: {
    // 限时活动详情
    goActive(id, name) {
      this.$router.push({
        path: '/activityProducts',
        query: {id: id, storeId: localStorage.getItem('storeID'), activeName: name,source:'home'}
      })
    },
    // 去商品详情
    goDetails(item) {
      sessionStorage.setItem('home', 0)
      this.$router.push({
        path: '/productDetails',
        query: {value: item.id, storeId: localStorage.getItem('storeID')}
      })
    },
    // 获取最新商品
    async getLatest() {
      let res = await getLatestProducts({
        storeId: localStorage.getItem('storeID')
      })
      if (res.code == 200) {
        this.latestProductsList = res.data
      }
    },
    // 去分类需求
    goClassification(it) {
      // 替换当前返回的路由
      this.$router.replace({
        path: '/goods',
        query: {
          classId1: it,
          storeId: localStorage.getItem('storeID')
        }
      })
    },
    // 获取分类
    async getclassification() {
      let res = await getStoreClassification({
        storeId: localStorage.getItem('storeID')
      })
      if (res.code == 200) {
        this.classificationArray = res.data
      }
      // this.classificationArray = this.chunkArray(res.data, 8)
      console.log(res, this.classificationArray)
    },
    chunkArray(arr, size) {
      let result = []
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size))
      }
      return result
    },
    // 获取店铺logo
    async logotable() {
      let data1 = {
        storeId: localStorage.getItem('storeID')
      }
      let {data} = await getStoreIndexInfo(data1)
      console.log(data)
      if (data) {
        // data.storeIndexInfo.themeId=1
        this.storeIndexInfo = data

        if (data.storeIndexInfo.themeId == 2) {
          this.getclassification()
          this.getLatest()
        }
      }
    },
    // pc端轮播图点击
    async pcbanner(list) {
      let data1 = {
        id: list.id,
        type: '0'
      }
      sessionStorage.setItem('home', 0)
      let data = await updateStoreBannerIncrement4Id(data1)
      if (list.pageType == 2) {
        // localStorage.setItem("menutable", '3');
        Buss.$emit('logs', '3')
      }
      if (list.hrefUrl.indexOf('value=') != -1) {
        let a = list.hrefUrl.split('value=')
        await this.$store.dispatch('user/Bannergoods', a[1])
        await this.$store.dispatch('user/Bannerid', list.id)
        if (data.code === 200) {
          this.$router.push({
            path: '/productDetails',
            query: {value: a[1], storeId: localStorage.getItem('storeID')}
          })
        }
      } else if (list.hrefUrl.indexOf('customizeId=') != -1) {
        let a = list.hrefUrl.split('customizeId=')
        await this.$store.dispatch('user/TablistID', a[1])
        this.$router.push({
          path: '/ExternalWord',
          query: {storeId: localStorage.getItem('storeID')}
        })
      } else if (list.hrefUrl.indexOf('classId3=') != -1) {
        let a = list.hrefUrl.split('classId3=')
        let c = a[0].split('classId2=')
        let b = c[0].split('classId1=')
        localStorage.setItem('customizeId', a[1])
        this.$store.dispatch('user/TablistID', a[1])
        this.$router.push({
          path: '/goods',
          query: {
            classId1: b[1].slice(0, -1),
            classId2: c[1].slice(0, -1),
            classId3: a[1]
          }
        })
      } else if (list.hrefUrl.indexOf('classId2=') != -1) {
        let a = list.hrefUrl.split('classId2=')
        let b = a[0].split('classId1=')
        localStorage.setItem('customizeId', a[1])
        this.$store.dispatch('user/TablistID', a[1])
        this.$router.push({
          path: '/goods',
          query: {classId1: b[1].slice(0, -1), classId2: a[1]}
        })
      } else if (list.hrefUrl.indexOf('classId1=') != -1) {
        let a = list.hrefUrl.split('classId1=')
        localStorage.setItem('customizeId', a[1])
        this.$store.dispatch('user/TablistID', a[1])
        this.$router.push({
          path: '/goods',
          query: {classId1: a[1]}
        })
      }
    },
    // H5移动端
    async H5banner(list) {
      let data1 = {
        id: list.id,
        type: '0'
      }
      let data = await updateStoreBannerIncrement4Id(data1)

      if (list.hrefUrl.indexOf('value=') != -1) {
        let a = list.hrefUrl.split('value=')
        await this.$store.dispatch('user/Bannergoods', a[1])
        await this.$store.dispatch('user/Bannerid', list.id)
        if (data.code === 200) {
          this.$router.push({
            path: '/productDetails',
            query: {value: a[1], storeId: localStorage.getItem('storeID')}
          })
        }
      } else if (list.hrefUrl.indexOf('customizeId=') != -1) {
        let a = list.hrefUrl.split('customizeId=')
        await this.$store.dispatch('user/TablistID', a[1])
        window.location.href = list.hrefUrl
      } else if (list.hrefUrl.indexOf('classId3=') != -1) {
        let a = list.hrefUrl.split('classId3=')
        localStorage.setItem('customizeId', a[1])
        this.$store.dispatch('user/TablistID', a[1])
        window.location.href = list.hrefUrl
      } else if (list.hrefUrl.indexOf('classId2=') != -1) {
        let a = list.hrefUrl.split('classId2=')
        localStorage.setItem('customizeId', a[1])
        this.$store.dispatch('user/TablistID', a[1])
        window.location.href = list.hrefUrl
      } else if (list.hrefUrl.indexOf('classId1=') != -1) {
        let a = list.hrefUrl.split('classId1=')
        localStorage.setItem('customizeId', a[1])
        this.$store.dispatch('user/TablistID', a[1])
        window.location.href = list.hrefUrl
      }
    },

    clickproduct(id,activityName,teamworkID,source) {
      sessionStorage.setItem('home', 0)
      if(source=='home'){
        this.$router.push({
          path: '/productDetails',
          query: {value: id, storeId: localStorage.getItem('storeID'),teamworkID:teamworkID,activeName:activityName,source:source}
        })
      }else {
        this.$router.push({
          path: '/productDetails',
          query: {value: id, storeId: localStorage.getItem('storeID')}
        })
      }


    },
    // 获取商品列表
    async gainlist() {

      let id = {
        storeId: localStorage.getItem('storeID')
      }
      let lorder = {
        position: 1,
        storeId: localStorage.getItem('storeID')
      }
      let llist = await listStoreBanner(lorder)
      let {data} = await listFrontGoodsUser(id)
      this.list = []
      this.Bannerlist = llist.data
      if (data.discount.length > 0) {
        data.discount.forEach(el => {
          // 将时间字符串转换为 Date 对象
          let endTime = new Date(this.$getTimeZoneOffset(el.endTime));
          let nowTime = new Date(this.$getTimeZoneOffset(el.nowTime));
// 计算时间差（单位：毫秒）
          el.timeDiff = endTime / 1000;

          if (el.userDiscountGoodsDTOList.records.length > 0){
            el.userDiscountGoodsDTOList.records = el.userDiscountGoodsDTOList.records.splice(0, 6)
            el.userDiscountGoodsDTOList.records.forEach(el=>{
              el.moneyArr = (''+el.discountPrice).split('.')
              el.moneyArr2 = (''+el.sellingPrice).split('.')
            })
          }
        })

        this.limitedTimeActivities = data.discount
      }
      if(data?.group?.length>0){
        data.group.forEach(el=>{
          // 将时间字符串转换为 Date 对象
          let endTime = new Date(this.$getTimeZoneOffset(el.endTime));
          // let nowTime = new Date(el.nowTime);

// 计算时间差（单位：毫秒）
          el.timeDiff = endTime / 1000;
          if(el.userGroupGoodsDTOIPage.records.length>0)
            el.userGroupGoodsDTOIPage.records=el.userGroupGoodsDTOIPage.records.splice(0,6)
          el.userGroupGoodsDTOIPage.records.forEach(el=>{
            el.moneyArr = (''+el.groupPrice).split('.')
            el.moneyArr2 = (''+el.sellingPrice).split('.')
          })
        })

        this.teamwork=data.group

      }
      if(data?.listFullReduction?.length>0){
        data.listFullReduction.forEach(el=>{
          // 将时间字符串转换为 Date 对象
          let endTime = new Date(this.$getTimeZoneOffset(el.endTime));
          // let nowTime = new Date(el.nowTime);

// 计算时间差（单位：毫秒）
          el.timeDiff = endTime / 1000;
          if(el.applicationFullReductionGoodsDTOList.records.length>0)
            el.applicationFullReductionGoodsDTOList.records=el.applicationFullReductionGoodsDTOList.records.splice(0,6)
          el.applicationFullReductionGoodsDTOList.records.forEach(el=>{
            el.moneyArr = (''+el.goodsPrice).split('.')
            // el.moneyArr2 = (''+el.sellingPrice).split('.')
          })
        })

        this.fullReductionFullGift=data.listFullReduction

      }

      for (let i = 0; i < data.listFrontGoods.length; i++) {
        if (i === 8) {
          return
        } else {
          this.list.push(data.listFrontGoods[i])
        }
      }


    }
  }
}
</script>

<style lang="scss" scoped>
.prodBox {
  padding: 19.5px 350px 0;

  .AllCategories {
    .label {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 13px;
    }

    .list {
      flex-wrap: wrap;
      display: flex;

      .li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 23px;
        margin-bottom: 20px;
        cursor: pointer;

        img {
          width: 130px;
          height: 90px;
          border-radius: 5px;
        }

        .txt {
          margin-top: 5px;
          font-size: 14px;
        }
      }

      .li:nth-child(8n) {
        margin-right: 0;
      }
    }
  }

  .limitedTimeDiscount {
    padding-top: 20px;

    .time {
      margin-bottom: 13px;

      .label {
        margin-right: 20px;

        .text {
          font-size: 20px;
          font-weight: bold;
          position: relative;
          z-index: 1;
        }

        .line {
          position: relative;
          z-index: 0;

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 10px;
            bottom: 0px;
            left: 0; /* 将三角形定位到父元素中间 */

            background: linear-gradient(to right, #E4BFFD, #8D4BBB);

          }
        }


      }
    }

    .more {
      cursor: pointer;
      color: #999;
    }

    //新增四个活动的列表样式
    .limitedTimeList {
      display: flex;
      flex-wrap: wrap;
      margin: 0px;
      // width: 75rem;
      // width: 66%;
      //padding-bottom: 20px;

      .productli {
        box-shadow: 1px 1px 1px 0 rgba(60, 66, 75, 0.1);
        border-radius: 10px;
        overflow: hidden;
        list-style: none;
        width: 12.075rem;
        margin: 10px 14px 10px 0;
        height: 328px;
        border: 1px solid #eeeeee;
        position: relative;

        .productlin1 {
          height: 179px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .productlin2 {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 10px;
          // height: 70px;

          div {
            //padding-bottom: 10px;
            height: 33px;
            //border-bottom: 1px solid #eeeeee;

            span {
              cursor: pointer;
              height: 33px;
              font-size: 14px;
              display: -webkit-box;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              -webkit-line-clamp: 2; // 超出多少行
              -webkit-box-orient: vertical;
              font-family: 'Microsoft YaHei';
              color: #384358;
              line-height: 16px;
            }
          }
        }

        .productlin3 {
          cursor: pointer;
          padding-left: 10px;
          line-height: 30px;
          font-size: 20px;
          font-family: 'Microsoft YaHei';
          font-weight: 600;
          color: #ff4545;

          .mony {
            font-size: 14px;
            //margin-right: 2px;
          }

          .mony2 {
            display: none;
          }
        }

        .discount {
          position: absolute;
          right: 14px;
          bottom: 35px;
          width: 50px;
          height: 34px;
          text-align: center;
          background: rgba(254, 39, 37, .1);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 13px;
          border-radius: 8px;
          color: #FE2725;
        }

        .discount::after {
          content: "";
          position: absolute;
          top: 15px; /* 将三角形定位到父元素底部 */
          left: -5px; /* 将三角形定位到父元素中间 */

          border-top: 5px solid transparent; /* 左侧透明 */
          border-bottom: 5px solid transparent; /* 右侧透明 */
          border-right: 5px solid #ffe9e9; /* 底部为实线 */
        }

        .productlin4 {
          cursor: pointer;
          padding: 0 10px;
          font-size: 18px;
          font-family: 'Microsoft YaHei';
          font-weight: 600;

          .oldMoney {
            color: #999;
            font-size: 12px;
            font-weight: 600;
            text-decoration: line-through;
          }

          .mony {
            margin-top: 5px;
            color: #999;
            font-size: 12px;
            font-weight: 600;
          }

          .mony2 {
            display: none;
          }

          .discountes {
            margin-left: 5px;
            color: #384358;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .productlin5 {
          margin-top: 8px;
          padding: 0 10px;
          box-sizing: border-box;

          .salesVolume {
            text-align: center;
            width: 100%;
            height: 26px;
            line-height: 26px;
            font-size: 13px;
            color: #ffffff;
            background: #8D4BBB;
            border-radius: 13px;
          }
        }

      }

      .productliout {
        box-shadow: 1px 1px 1px 0 rgba(60, 66, 75, 0.1);
        border-radius: 10px;
        overflow: hidden;
        list-style: none;
        width: 270px;
        margin: 10px 7.5px;
        height: 370px;
        border: 1px solid #eeeeee;

        .productlin1 {
          height: 240px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .productlin2 {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 10px;
          height: 70px;

          div {
            padding-bottom: 20px;
            height: 50px;
            border-bottom: 1px solid #eeeeee;

            span {
              cursor: pointer;
              height: 50px;
              font-size: 14px;
              display: -webkit-box;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              -webkit-line-clamp: 2; // 超出多少行
              -webkit-box-orient: vertical;
              font-family: 'Microsoft YaHei';
              color: #384358;
              line-height: 25px;
            }
          }
        }

        .productlin3 {
          cursor: pointer;
          padding-left: 20px;
          line-height: 40px;
          font-size: 18px;
          font-family: 'Microsoft YaHei';
          font-weight: normal;
          color: #ff4545;

          .mony {
            font-size: 16px;
            margin-right: 3px;
          }

          .mony2 {
            display: none;
          }
        }
      }

      .productli:hover {
        box-shadow: 0 5px 15px 0 rgba(48, 55, 66, 0.15);
        transform: translate(0, -5px);
      }
    }


  }

  .NewProducts {
    .label {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 13px;
    }

    .list {
      flex-wrap: wrap;
      display: flex;

      .li {
        cursor: pointer;
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 10px;
        margin-bottom: 20px;

        img {
          width: 190px;
          height: 170px;
          // border-radius: 5px;
        }

        .productName {
          margin-top: 5px;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          width: 190px;
          height: 42px;
        }

        .txt {
          margin-top: 5px;
          font-size: 18px;
          color: #ff4545;
        }

        .underlinedPrice {
          font-size: 14px;
          color: #cacaca;
          text-decoration: line-through; /* 添加划线 */
        }
      }

      .li:nth-child(6n) {
        margin-right: 0;
      }
    }
  }
}

.behome {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heardswiper {
  margin-top: 10px;
  width: 63%;
  height: 550px;

  .carousel {
    width: 100%;
    height: 550px;

    .itemcarousel {
      height: 550px;
    }
  }

  .my-swipe .van-swipe-item {
    display: none;
  }

  .imgpc {
    width: 100%;
    height: 100%;
  }
}

.product {
  width: 66%;

  .limitedTimeDiscount {
    padding-top: 20px;

    .time {
      margin-bottom: 13px;

      .label {
        margin-right: 20px;

        .text {
          font-size: 20px;
          font-weight: bold;
          position: relative;
          z-index: 1;
        }

        .line {
          position: relative;
          z-index: 0;

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 10px;
            bottom: 0px;
            left: 0; /* 将三角形定位到父元素中间 */

            background: linear-gradient(to right, #E4BFFD, #8D4BBB);

          }
        }


      }
    }

    .more {
      cursor: pointer;
      color: #999;
    }

    //新增四个活动的列表样式
    .limitedTimeList {
      display: flex;
      flex-wrap: wrap;
      margin: 0px;
      // width: 75rem;
      // width: 66%;
      //padding-bottom: 20px;

      .productli {
        box-shadow: 1px 1px 1px 0 rgba(60, 66, 75, 0.1);
        border-radius: 10px;
        overflow: hidden;
        list-style: none;
        width: 12.075rem;
        margin: 10px 14px 10px 0;
        height: 328px;
        border: 1px solid #eeeeee;
        position: relative;

        .productlin1 {
          height: 179px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .productlin2 {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 10px;
          // height: 70px;

          div {
            //padding-bottom: 10px;
            height: 33px;
            //border-bottom: 1px solid #eeeeee;

            span {
              cursor: pointer;
              height: 33px;
              font-size: 14px;
              display: -webkit-box;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              -webkit-line-clamp: 2; // 超出多少行
              -webkit-box-orient: vertical;
              font-family: 'Microsoft YaHei';
              color: #384358;
              line-height: 16px;
            }
          }
        }

        .productlin3 {
          cursor: pointer;
          padding-left: 10px;
          line-height: 30px;
          font-size: 20px;
          font-family: 'Microsoft YaHei';
          font-weight: 600;
          color: #ff4545;

          .mony {
            font-size: 14px;
            //margin-right: 2px;
          }

          .mony2 {
            display: none;
          }
        }

        .discount {
          position: absolute;
          right: 14px;
          bottom: 35px;
          width: 50px;
          height: 34px;
          text-align: center;
          background: rgba(254, 39, 37, .1);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 13px;
          border-radius: 8px;
          color: #FE2725;
        }

        .discount::after {
          content: "";
          position: absolute;
          top: 15px; /* 将三角形定位到父元素底部 */
          left: -5px; /* 将三角形定位到父元素中间 */

          border-top: 5px solid transparent; /* 左侧透明 */
          border-bottom: 5px solid transparent; /* 右侧透明 */
          border-right: 5px solid #ffe9e9; /* 底部为实线 */
        }

        .productlin4 {
          cursor: pointer;
          padding: 0 10px;
          font-size: 18px;
          font-family: 'Microsoft YaHei';
          font-weight: 600;

          .oldMoney {
            color: #999;
            font-size: 12px;
            font-weight: 600;
            text-decoration: line-through;
          }

          .mony {
            margin-top: 5px;
            color: #999;
            font-size: 12px;
            font-weight: 600;
          }

          .mony2 {
            display: none;
          }

          .discountes {
            margin-left: 5px;
            color: #384358;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .productlin5 {
          margin-top: 8px;
          padding: 0 10px;
          box-sizing: border-box;

          .salesVolume {
            text-align: center;
            width: 100%;
            height: 26px;
            line-height: 26px;
            font-size: 13px;
            color: #ffffff;
            background: #8D4BBB;
            border-radius: 13px;
          }
        }

      }

      .productliout {
        box-shadow: 1px 1px 1px 0 rgba(60, 66, 75, 0.1);
        border-radius: 10px;
        overflow: hidden;
        list-style: none;
        width: 270px;
        margin: 10px 7.5px;
        height: 370px;
        border: 1px solid #eeeeee;

        .productlin1 {
          height: 240px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .productlin2 {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 10px;
          height: 70px;

          div {
            padding-bottom: 20px;
            height: 50px;
            border-bottom: 1px solid #eeeeee;

            span {
              cursor: pointer;
              height: 50px;
              font-size: 14px;
              display: -webkit-box;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              -webkit-line-clamp: 2; // 超出多少行
              -webkit-box-orient: vertical;
              font-family: 'Microsoft YaHei';
              color: #384358;
              line-height: 25px;
            }
          }
        }

        .productlin3 {
          cursor: pointer;
          padding-left: 20px;
          line-height: 40px;
          font-size: 18px;
          font-family: 'Microsoft YaHei';
          font-weight: normal;
          color: #ff4545;

          .mony {
            font-size: 16px;
            margin-right: 3px;
          }

          .mony2 {
            display: none;
          }
        }
      }

      .productli:hover {
        box-shadow: 0 5px 15px 0 rgba(48, 55, 66, 0.15);
        transform: translate(0, -5px);
      }
    }


  }

  .productheard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;

    .child1 {
      width: 150px;
      height: 1.3px;
      background-color: #e9e9e9;
    }

    .child2 {
      margin: 10px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #67686d;
      word-spacing: 0.01px;
      // width: 160px;
      height: 18px;
      font-size: 22.65px;
    }

    .child3 {
      width: 150px;
      height: 1.3px;
      background-color: #e9e9e9;
    }
  }
}

.productlist {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  // width: 75rem;
  // width: 66%;
  padding-bottom: 80px;

  .productli {
    box-shadow: 1px 1px 1px 0 rgba(60, 66, 75, 0.1);
    border-radius: 10px;
    overflow: hidden;
    list-style: none;
    width: 16.875rem;
    margin: 10px 14px;
    height: 370px;
    border: 1px solid #eeeeee;

    .productlin1 {
      height: 240px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .productlin2 {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      // height: 70px;

      div {
        padding-bottom: 20px;
        height: 50px;
        border-bottom: 1px solid #eeeeee;

        span {
          cursor: pointer;
          height: 50px;
          font-size: 14px;
          display: -webkit-box;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          -webkit-line-clamp: 2; // 超出多少行
          -webkit-box-orient: vertical;
          font-family: 'Microsoft YaHei';
          color: #384358;
          line-height: 25px;
        }
      }
    }

    .productlin3 {
      cursor: pointer;
      padding-left: 20px;
      line-height: 40px;
      font-size: 18px;
      font-family: 'Microsoft YaHei';
      font-weight: normal;
      color: #ff4545;

      .mony {
        font-size: 16px;
        margin-right: 3px;
      }

      .mony2 {
        display: none;
      }
    }
  }

  .productliout {
    box-shadow: 1px 1px 1px 0 rgba(60, 66, 75, 0.1);
    border-radius: 10px;
    overflow: hidden;
    list-style: none;
    width: 270px;
    margin: 10px 7.5px;
    height: 370px;
    border: 1px solid #eeeeee;

    .productlin1 {
      height: 240px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .productlin2 {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      height: 70px;

      div {
        padding-bottom: 20px;
        height: 50px;
        border-bottom: 1px solid #eeeeee;

        span {
          cursor: pointer;
          height: 50px;
          font-size: 14px;
          display: -webkit-box;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          -webkit-line-clamp: 2; // 超出多少行
          -webkit-box-orient: vertical;
          font-family: 'Microsoft YaHei';
          color: #384358;
          line-height: 25px;
        }
      }
    }

    .productlin3 {
      cursor: pointer;
      padding-left: 20px;
      line-height: 40px;
      font-size: 18px;
      font-family: 'Microsoft YaHei';
      font-weight: normal;
      color: #ff4545;

      .mony {
        font-size: 16px;
        margin-right: 3px;
      }

      .mony2 {
        display: none;
      }
    }
  }

  .productli:hover {
    box-shadow: 0 5px 15px 0 rgba(48, 55, 66, 0.15);
    transform: translate(0, -5px);
  }
}
</style>
