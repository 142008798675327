export default {
    BCEL支付: 'BCEL',
    KBZ支付: 'KBZPay',
    保存: 'menyimpan',
    保密: 'Rahasia',
    备注说明: 'instruksi manual',
    必须包含两种字符: 'Harus berisi dua karakter',
    编辑: 'sunting',
    编辑地址: 'Sunting alamat',
    标记已读: 'tandai sebagai membaca',
    不可修改最多: 'Yang paling tidak bisa dimodifikasi',
    部分订单取消: 'Pesanan sebagian dibatalkan',
    部分发货: 'Pengiriman sebagian',
    部分商品正在退款中: 'Beberapa item sedang dikembalikan',
    部分收货: 'Penerimaan sebagian',
    部分退款: 'Pengembalian dana sebagian',
    部分退款完成: 'Pengembalian dana sebagian selesai',
    残忍离开: 'pergi dengan kejam',
    操作: 'beroperasi',
    查看订单: 'periksa pesanan',
    查看全部: 'Lihat semua',
    查看全部x条回复: 'Lihat semua{num} balasan',
    查看详情: 'periksa detailnya',
    查看支付是否成功: 'Periksa apakah pembayaran berhasil>>>',
    查询: 'Mempertanyakan',
    城市: 'kota',
    创建时间: 'Waktu pembuatan',
    此宝贝已下架: 'Produk ini telah dikeluarkan dari rak',
    待发货: 'Menunggu pengiriman',
    待付款: 'Pembayaran tertunda',
    待收货: 'Barang yang akan diterima',
    单价: 'Harga satuan',
    当前安全手机号: 'Nomor ponsel aman saat ini',
    当前头像: 'Avatar saat ini',
    地址: 'alamat',
    地址管理: 'Manajemen Alamat',
    地址最多10条还能保存xxx条: 'Hingga 10 alamat dapat disimpan dan {seconds} dapat disimpan',
    登录: 'Masuk',
    等待店铺审核: 'Menunggu ulasan toko',
    点击上传: 'Klik untuk mengunggah',
    电话: 'Telepon',
    电子邮件地址: 'alamat email',
    店: 'toko',
    店铺: 'toko',
    店铺处理: 'Pemrosesan Toko',
    店铺退款: 'Pengembalian dana toko',
    订单: 'Pesan',
    订单编号: 'Nomor pesanan',
    订单号: 'Nomor pesanan',
    订单金额: ' Jumlah pesanan',
    订单取消时间: 'Waktu pembatalan pesanan',
    订单提交成功请尽快付款: 'Pesanan berhasil dikirimkan, silakan bayar sesegera mungkin!',
    订单已超时: 'Waktu pesanan telah habis',
    订单已取消: 'Pesanan telah dibatalkan',
    发表成功: 'Berhasil dipublikasikan',
    发表留言: 'Posting pesan',
    发货地: 'tempat pengiriman',
    发货件数: 'Jumlah pengiriman',
    发货时间: 'waktu pengiriman',
    发货状态: 'Status pengiriman',
    发送成功: 'Kirim berhasil',
    发送验证码: 'Kirim kode verifikasi',
    法律声明: 'Pemberitahuan Hukum',
    返回: 'kembali',
    返回个人中心: 'Kembali ke pusat pribadi',
    返回首页: 'Kembali ke halaman beranda',
    分: 'fen',
    服务单号: 'Nomor pesanan layanan',
    付款时间: 'Waktu pembayaran',
    个人信息: 'Informasi Pribadi',
    个人中心: 'Pusat Pribadi',
    恭喜您邮箱地址验证成功: 'Selamat! Alamat email berhasil diverifikasi',
    共x件商品已选择x: 'Total {num} produk telah dipilih {num2}',
    购买信息: 'Informasi pembelian',
    国家区域: 'Negara/Wilayah',
    还没有收货地址哦: 'Belum ada alamat pengiriman',
    含发货邮费: 'Termasuk ongkos kirim dan ongkos kirim',
    欢迎登录: 'Selamat datang untuk masuk',
    回复: 'balasan',
    活动优惠: 'Diskon aktivitas',
    货币: 'mata uang',
    继续逛逛: 'Teruslah berbelanja',
    继续支付: 'Lanjutkan membayar',
    价格从低到高: 'Harga dari rendah ke tinggi',
    价格从高到低: 'harga dari tinggi ke rendah',
    价格高低: 'Tingkat harga',
    交易单号: 'Nomor pesanan transaksi',
    交易异常: 'Pengecualian transaksi',
    看不清换一张: 'Tidak bisa melihat dengan jelas, ganti satu',
    客: 'Ke',
    客服电话: 'Nomor telepon layanan pelanggan',
    客服邮箱: 'Email layanan pelanggan',
    客户: 'Pelanggan',
    库存: 'Inventaris',
    立即登录: 'Masuk sekarang',
    立即购买: 'Beli Sekarang',
    立即留言: 'Tinggalkan pesan sekarang',
    立即提交: 'Kirim sekarang',
    立即修改: 'ubah segera',
    立即支付: 'Bayar Sekarang',
    立即注册: 'Daftar sekarang',
    联系电话: 'Nomor kontak',
    联系方式: 'Informasi kontak',
    联系客服: 'Hubungi layanan pelanggan',
    联系人: 'Kontak',
    联系信息: 'Informasi kontak',
    两次输入密码不一致: 'Kata sandi yang dimasukkan dua kali tidak konsisten',
    留言成功: 'Pesan berhasil',
    留言管理: 'Manajemen pesan',
    留言回复: 'Tinggalkan balasan pesan',
    留言回复长度xxx个字符: 'Panjang balasan pesan adalah 2-100 karakter',
    留言内容: 'Isi pesan',
    没收到货或与店铺协商同意不用退货只退款: 'Tidak menerima barang, atau bernegosiasi dengan toko, tidak perlu dikembalikan, hanya pengembalian dana',
    没有找到搜索的内容: 'Konten pencarian tidak ditemukan',
    密码: 'kata sandi',
    密码必须输入: 'Kata sandi harus dimasukkan',
    密码登录: 'Login kata sandi',
    密码最少8位最多16位: 'Kata sandi minimal harus 8 karakter dan maksimal 16 karakter',
    免费注册: 'Pendaftaran gratis',
    描述: 'deskripsi',
    描述文字: 'Teks deskripsi',
    秒: 'kedua',
    秒后重新发送: 'Kirim ulang setelah beberapa detik',
    名: 'nama',
    默认地址: 'alamat default',
    男: 'laki',
    内完成支付否则订单会被自动取消: 'Selesaikan pembayaran dalam waktu yang ditentukan jika tidak, pesanan akan dibatalkan secara otomatis',
    昵称: 'nama panggilan',
    昵称不能为空: 'Nama panggilan tidak boleh kosong',
    您当前是线下支付请耐心等待店铺确认收款若: 'Anda saat ini membayar offline, harap bersabar dan tunggu toko mengonfirmasi pembayaran!',
    您的购物车还没有商品哟: 'Belum ada item di keranjang belanja Anda~',
    您可以参考如下线下付款方式: 'Anda dapat merujuk ke metode pembayaran offline berikut',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料: 'Anda dapat meninggalkan pesan ke toko di sini. Untuk memastikan hak Anda, harap unggah informasi pesan sedetail mungkin',
    您未支付请重新支付: 'Belum bayar, silakan bayar lagi',
    女: 'perempuan',
    评论成功: 'Komentar berhasil',
    切换地址: 'Ganti alamat',
    请勾选商品: 'Silahkan cek produknya',
    请勾选信息: 'Silakan periksa informasinya',
    请描述问题: 'Tolong jelaskan masalahnya',
    请设置位密码需包字母及数字: 'Silakan atur kata sandi digit yang harus berisi huruf dan angka',
    请输入电话: 'Silakan masukkan nomor telepon Anda',
    请输入电话号码: 'Silakan masukkan nomor telepon Anda',
    请输入反馈内容: 'Silakan masukkan konten umpan balik',
    请输入旧密码: 'Silakan masukkan kata sandi lama',
    请输入联系人: 'Silakan masukkan contact person',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名: 'Silakan masukkan isi pesan. Jika Anda menggunakan BCEL atau KBZPay untuk membayar, silakan masukkan bank nomor kartu dan nama rekening di sini untuk menerima pengembalian dana.Nomor kartu bank dan nama rekening',
    请输入密码: 'Silakan masukkan kata sandi',
    请输入名: 'Silakan masukkan nama Anda',
    请输入名字: 'Silakan masukkan nama Anda',
    请输入内容: 'Silakan masukkan konten',
    请输入商品名称: 'Silakan masukkan nama produk',
    请输入下方图形验证码: 'Silakan masukkan kode verifikasi grafis di bawah',
    请输入详细地址: 'Silakan masukkan alamat detail',
    请输入新密码: 'Silakan masukkan kata sandi baru',
    请输入姓: 'Silakan masukkan nama belakang Anda',
    请输入验证码: 'Silakan masukkan kode verifikasi',
    请输入邮编: 'Silakan masukkan kode pos Anda',
    请输入邮箱: 'Silakan masukkan email Anda',
    请输入邮箱手机号: 'Silakan masukkan email/nomor ponsel Anda',
    请输入账号密码: 'Silakan masukkan kata sandi akun Anda',
    请输入正确的密码格式必须包含两种字符: 'Silakan masukkan format kata sandi yang benar yang harus berisi dua karakter',
    请输入正确的手机号: 'Silakan masukkan nomor ponsel yang benar',
    请输入正确的邮箱: 'Silakan masukkan alamat email yang benar',
    请输入正确验证码: 'Silakan masukkan kode verifikasi yang benar',
    请填写留言内容: 'Silakan isi isi pesan',
    请选择: 'Silahkan pilih',
    请选择城市: 'Silakan pilih kota',
    请选择付款方式: 'Silakan pilih metode pembayaran',
    请选择规格: 'Silakan pilih spesifikasi',
    请选择国家: 'Silakan pilih negara',
    请选择删除项需已读才能删除: 'Silakan pilih item yang akan dihapus dan harus dibaca sebelum dapat dihapus',
    请选择商品分类: 'Silakan pilih kategori produk',
    请选择退货原因: 'Silakan pilih alasan pengembalian',
    请选择语言: 'Silakan pilih bahasa',
    请选择展示货币: 'Silakan pilih mata uang tampilan',
    请再次输入密码: 'Silakan masukkan kata sandi Anda lagi',
    请在下次使用邮箱地址进行登录: 'Silakan gunakan alamat email Anda untuk login di lain waktu',
    取消: 'Batal',
    取消成功: 'Pembatalan berhasil',
    取消订单: 'Batalkan pesanan',
    取消订单成功: "Pesanan berhasil dibatalkan",
    取消售后: 'Batalkan layanan purna jual',
    取消原因: 'alasan pembatalan',
    去付款: 'Pergi untuk membayar',
    全部: 'semua',
    全部产品: 'Semua Produk',
    全部商品: 'Semua Produk',
    全部消息: 'Semua pesan',
    全选: 'Pilih semua',
    缺少库存: 'Kurangnya stok',
    确定: 'Oke',
    确定放弃支付吗: 'Apakah Anda yakin ingin melepaskan pembayaran?',
    确定删除选中的商品吗: 'Apakah Anda yakin ingin menghapus produk yang dipilih?',
    确定已输入接收退款的银行卡号和户名: 'Pastikan Anda telah memasukkan nomor kartu bank dan nama rekening untuk menerima pengembalian dana',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消: 'Saat ini Anda membayar secara offline. Harap sabar menunggu toko mengonfirmasi pembayaran. Jika toko tidak mengonfirmasi pembayaran setelah <span style="color: red;">{num}</span>, pesanan akan otomatis dibatalkan!',
    确认收货: 'Konfirmasi penerimaan',
    确认收货成功: 'Konfirmasi penerimaan yang berhasil',
    确认提交: 'Konfirmasi penyerahan',
    确认已付款: 'Konfirmasikan pembayaran',
    如果您当前的手机号已无法使用请联系客服: 'Jika nomor ponsel Anda saat ini tidak tersedia lagi, silakan hubungi layanan pelanggan',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持xxx格式图片不大于3M: 'Jika Anda telah membayar sesuai dengan metode pembayaran di atas, silakan unggah voucher yang relevan, seperti mentransfer tangkapan layar, dll., mendukung format jpg, png, Gambar tidak boleh lebih besar dari 3M',
    扫码支付: 'Pindai kode untuk membayar',
    删除: 'hapus',
    删除成功: 'Berhasil dihapus',
    删除地址: 'hapus alamat',
    删除订单: 'Hapus pesanan',
    删除订单成功: 'Pesanan berhasil dihapus',
    删除失效商品: 'Hapus produk yang tidak valid',
    删除选中商品: 'Hapus produk yang dipilih',
    商品: 'Produk',
    商品单价: 'Harga satuan produk',
    商品分类: 'Kategori Produk',
    商品合计: ' Total produk',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询: 'Alamat pengembalian produk akan diberitahukan melalui pesan teks atau ditanyakan dalam catatan aplikasi setelah peninjauan dilewati.',
    商品件数: 'Jumlah item',
    商品信息: 'Informasi produk',
    商品总额: 'Total volume barang dagangan',
    商品总价: 'Total harga produk',
    上传成功: 'Unggah berhasil',
    上传凭证: 'Unggah sertifikat',
    上传失败: 'Upload gagal',
    上传头像图片大小不能超过2MB: 'Ukuran gambar avatar yang diunggah tidak boleh melebihi 2MB! ',
    上传头像图片只能是JPG格式: 'Gambar avatar yang diunggah hanya boleh dalam format JPG!',
    申请时间: 'Waktu lamaran',
    申请信息: 'Informasi aplikasi',
    失效宝贝: 'Bayi yang gagal',
    时: 'waktu',
    实付: 'pembayaran sebenarnya',
    实付金额: 'Jumlah yang sebenarnya dibayarkan',
    实付款: 'Pembayaran aktual',
    是否删除: 'Hapus atau tidak',
    是否删除地址: 'Apakah akan menghapus alamat',
    是否选择该地址: 'Apakah akan memilih alamat ini',
    收货地址: 'Alamat pengiriman',
    收货信息: 'Informasi penerimaan',
    收件地址: 'alamat penerimaan',
    收件人: 'penerima',
    手机号: 'Nomor ponsel',
    手机号码: 'Nomor ponsel',
    手机验证: 'Verifikasi ponsel',
    首页: 'Rumah',
    售后单号: 'Nomor pesanan purna jual',
    售后方式: 'Metode purna jual',
    售后服务: 'Layanan purna jual',
    售后商品: 'Produk purna jual',
    售后申请: 'Aplikasi purna jual',
    售后申请提交成功: 'Permohonan purna jual berhasil dikirimkan',
    售后申请提交失败: 'Pengajuan aplikasi purna jual gagal',
    售后完成: 'Purna jual selesai',
    售后信息: 'Informasi purna jual',
    售后专员可能与您电话沟通请保持手机畅通: 'Spesialis purna jual dapat berkomunikasi dengan Anda melalui telepon, harap biarkan ponsel Anda tetap terbuka',
    数量: 'kuantitas',
    搜索: 'Cari',
    提交: 'kirim',
    提交订单: 'Kirim pesanan',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通: 'Setelah mengirimkan pesanan layanan, spesialis purna jual dapat berkomunikasi dengan Anda melalui telepon. Harap tetap buka ponsel Anda',
    提交申请: 'Kirim lamaran',
    提示: 'cepat',
    天: 'langit',
    添加超时: 'Tambahkan batas waktu',
    添加成功: 'Berhasil ditambahkan',
    添加地址: 'Tambahkan alamat',
    添加购物车: 'Masukkan ke keranjang',
    添加失败库存不足: 'Gagal menambahkan, inventaris tidak mencukupi',
    x条回复: '{num} balasan',
    通过电子邮件向我发送新闻和优惠: 'Kirimi saya email berita dan penawaran',
    推荐产品: 'Produk yang direkomendasikan',
    退出登录: 'Keluar',
    退货处理成功后退款金额将原路返回到您的支持账户中: 'Setelah pengembalian berhasil diproses, jumlah pengembalian dana akan dikembalikan ke akun dukungan Anda melalui jalur asli.',
    退货金额: 'Jumlah pengembalian',
    退货类型: 'Jenis Pengembalian',
    退货理由: 'Alasan pengembalian',
    退货申请中: 'Pengembalian aplikasi sedang berlangsung',
    退货数量: 'Kuantitas pengembalian',
    退货原因: 'Alasan pengembalian',
    退款成功: 'Pengembalian dana berhasil',
    退款金额: 'Jumlah pengembalian dana',
    退款失败: 'Pengembalian dana gagal',
    退款说明: 'Instruksi pengembalian dana',
    退款完成: 'Pengembalian Dana Selesai',
    退款原因: 'Alasan pengembalian dana',
    退款中: 'Pengembalian dana sedang berlangsung',
    完成: 'selesai',
    完整地址: 'Alamat lengkap',
    忘记密码: 'Lupa kata sandi',
    微信: 'WeChat',
    微信支付: 'Pembayaran WeChat',
    为了帮助您更好的解决问题请上传图片: 'Untuk membantu Anda menyelesaikan masalah dengan lebih baik, harap unggah gambar',
    为确认身份我们需验证您的安全手机: 'Untuk mengonfirmasi identitas Anda, kami perlu memverifikasi telepon aman Anda',
    未读消息: 'Pesan belum dibaca',
    未发货: 'Tidak terkirim',
    未选择地址或没有地址: 'Tidak ada alamat yang dipilih atau tidak ada alamat',
    温馨提示: 'Pengingat hangat',
    文件过大: 'File terlalu besar',
    问题描述: 'Deskripsi masalah',
    我的订单: 'Pesanan saya',
    我是新用户: 'Saya pengguna baru',
    我要留言: 'Saya ingin meninggalkan pesan',
    我要退货退款: 'Saya ingin mengembalikan barang untuk di refund',
    我要退款: 'Saya ingin pengembalian dana',
    下单结算: 'Penyelesaian pesanan',
    下单时间: 'Waktu pemesanan',
    下一步: 'Langkah selanjutnya',
    线下支付: 'Pembayaran offline',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货: 'Pembayaran offline tidak mendukung layanan purna jual online. Jika memerlukan layanan purna jual silahkan menghubungi toko. Jika sudah membayar harap bersabar dan menunggu toko mengkonfirmasi penerimaan dan pengiriman',
    详细地址: 'Alamat detail',
    消息通知: 'Pemberitahuan pesan',
    销量从低到高: 'Volume penjualan dari rendah ke tinggi',
    销量从高到低: 'Volume penjualan dari tinggi ke rendah',
    销量高低: 'Volume penjualan',
    小计: 'Subtotal',
    新密码不能和旧密码相同: 'Password baru tidak boleh sama dengan password lama',
    新增地址: 'Tambahkan alamat',
    性别: 'jenis kelamin',
    姓: 'nama keluarga',
    姓名: 'Nama',
    修改成功: 'Modifikasi berhasil',
    修改密码: 'Ubah kata sandi',
    修改邮箱: 'Ubah email',
    需勾选协议: 'Perjanjian perlu diperiksa',
    选择成功: 'Pilih sukses!',
    选择地址: 'Pilih alamat',
    选择以下支付方式付款: 'Pilih metode pembayaran berikut untuk membayar',
    验证码: 'Kode verifikasi',
    验证码2: 'Kode verifikasi',
    验证码登录: 'Login kode verifikasi',
    验证邮箱: 'Email verifikasi',
    已读消息: 'Baca pesan',
    已发货: 'dikirim',
    已关闭: 'tertutup',
    已经有一个账户: 'Sudah punya akun?',
    已取消: 'Dibatalkan',
    已取消删除: 'Tidak terhapus',
    已失效商品: 'Produk kadaluwarsa',
    已收到货需要退还收到的货物: 'Barang sudah diterima dan barang yang diterima perlu dikembalikan',
    已完成: 'Selesai',
    已下架: 'Dihapus',
    已有账号: 'Sudah punya akun',
    已阅读并同意以下协议: 'Telah membaca dan menyetujui perjanjian berikut',
    隐私政策: 'Kebijakan Privasi',
    应付总额: 'Jumlah total yang harus dibayar',
    用户服务协议: 'Perjanjian Layanan',
    用户服务协议及法律声明: 'Perjanjian Layanan Pengguna dan Pernyataan Hukum',
    优惠卷抵扣: 'Pengurangan kupon',
    邮箱: 'surat',
    邮箱必须输入: 'Email harus dimasukkan',
    邮箱地址: 'alamat email',
    邮箱修改: 'Modifikasi email',
    邮箱验证: 'Verifikasi email',
    邮政编码: 'Kode Pos',
    语言: 'bahasa',
    运费: 'Pengangkutan',
    再次输入新密码: 'Masukkan kata sandi baru lagi',
    在线支付: 'Pembayaran online',
    暂无地址信息: 'Belum ada informasi alamat',
    暂无订单信息: 'Belum ada informasi pesanan',
    暂无留言信息: 'Belum ada pesan pesan',
    暂无商品: 'Tidak ada produk saat ini',
    暂无售后信息: 'Tidak ada informasi purna jual',
    暂无消息通知: 'Belum ada notifikasi pesan',
    长度在1到20个字符: 'Panjang antara 1 dan 20 karakter',
    长度在1到8个字符: 'Panjang antara 1 dan 8 karakter',
    长度在2到100个字符: 'Panjang antara 2 dan 100 karakter',
    长度在2到10个字符: 'Panjang antara 2 dan 10 karakter',
    长度在8到16个字符: 'Panjang antara 8 dan 16 karakter',
    找回密码: 'Ambil kata sandi,',
    支付宝: 'Alipay',
    支付成功: 'Pembayaran berhasil',
    支付方式: 'Metode pembayaran',
    支付凭证: 'Voucher Pembayaran',
    支付失败: 'Pembayaran gagal',
    直接登录: 'Login langsung',
    重新发送验证码: 'Kirim ulang kode verifikasi',
    重新申请: 'Mengajukan permohonan kembali',
    注册: 'Daftar',
    注册成功: 'Pendaftaran berhasil',
    综合排序: 'Penyortiran komprehensif',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件: 'Anda dapat mengunggah hingga 5 gambar, ukuran setiap gambar tidak melebihi 5M, dan mendukung file berformat bmp, gif, jpg, png, dan jpeg',
    最多上传五张: 'Unggah hingga lima foto',
    最多上传一张: 'Unggah paling banyak satu bagian',
    分享: 'bagikan',
    分享至: 'Bagikan ke',
    推荐店铺给你: 'Rekomendasikan toko kepada Anda',
    分享店铺: 'Bagikan toko',
    复制成功: 'Berhasil disalin',
    加载中: 'memuat',
    至: 'ke',
    币种: 'Komentar',
    变动理由: 'alasan untuk berubah',
    不记得: 'tidak ingat',
    充值: 'isi ulang',
    打款凭证: 'Voucher pembayaran',
    打款说明: 'Instruksi pembayaran',
    打款信息: 'Informasi Pembayaran',
    打款状态: 'Status pembayaran',
    待打款: 'Menunggu Pembayaran',
    待审核: 'Tinjauan tertunda',
    到账方式: 'Cara Pembayaran',
    到账银行卡号: 'Nomor kartu bank setoran',
    冻结中: 'Pembekuan',
    国家: 'bangsa',
    获取验证码: 'dapatkan kode verifikasi',
    记得: 'Ingat',
    记录时间: 'Rekor waktu',
    拒绝打款: 'Menolak untuk membayar',
    开户人: 'Pemilik akun',
    开户支行: 'Cabang pembukaan rekening',
    可提现: 'Dapat ditarik',
    可提现金额: 'Jumlah uang tunai yang tersedia',
    立即提现: 'Tarik segera',
    流水号: 'nomor seri',
    没有适合此币种的银行卡请先添加: 'Tidak ada kartu bank yang cocok untuk mata uang ini, harap tambahkan terlebih dahulu',
    没有收款码请先添加: 'Tidak ada kode pembayaran, silakan tambahkan terlebih dahulu',
    每天最多提现x次单次最高提现金额x: 'Jumlah maksimum penarikan per hari adalah *, dan jumlah maksimum penarikan per waktu*',
    您是否记得账号当前使用的支付密码: 'Apakah Anda ingat kata sandi pembayaran yang saat ini digunakan oleh akun Anda?',
    您未设置支付密码请先设置: 'Anda belum menetapkan kata sandi pembayaran, silakan atur terlebih dahulu',
    钱包余额: 'saldo dompet',
    请输入开户人: 'Silakan masukkan pemegang akun',
    请输入开户支行: 'Silakan masuk ke cabang tempat pembukaan rekening',
    请输入收款名称: 'Silakan masukkan nama pembayaran',
    请输入提现金额: 'Silakan masukkan jumlah penarikan',
    请输入新支付密码6位数字: 'Silakan masukkan kata sandi pembayaran 6 digit yang baru',
    请输入新支付密码: 'Silakan masukkan kata sandi pembayaran baru',
    请输入银行卡号: 'Silakan masukkan nomor kartu bank',
    请输入原支付密码: 'Silakan masukkan kata sandi pembayaran asli',
    请输入支付密码6位数字: 'Silakan masukkan kata sandi pembayaran, 6 digit',
    请输入支付密码: 'Silakan masukkan kata sandi pembayaran',

    请选择币种: 'Silakan pilih mata uang',
    请选择大洲: 'Silakan pilih benua',

    请选择结束时间: 'Silakan pilih waktu berakhir:',
    请选择开始时间: 'Silakan pilih waktu mulai',
    请选择收款码类型: 'Silakan pilih jenis kode pembayaran',
    请选择银行: 'Silakan pilih bank',
    请选择银行名称: 'Silakan pilih nama bank',
    请再次输入新支付密码: 'Silakan masukkan kembali kata sandi pembayaran baru',
    请再次输入支付密码: 'Silakan masukkan kembali kata sandi pembayaran baru',
    去设置: 'Pergi ke pengaturan',
    全部提现: 'Semua penarikan:',
    确定删除收款码吗: 'Konfirmasi untuk menghapus kode pembayaran?',
    确定删除银行卡吗: 'Apakah Anda yakin ingin menghapus kartu bank?',
    确定提现: 'Konfirmasikan penarikan',
    确认打款: 'Konfirmasi pembayaran:',
    确认新密码: 'Konfirmasikan kata sandi baru',
    确认支付密码: 'Konfirmasikan kata sandi pembayaran',
    上传的收款二维码需要清晰无遮挡: 'Kode QR pembayaran yang diunggah harus jelas dan tidak terhalang',
    审核备注: 'Tinjau komentar',
    审核不通过: 'Audit tidak lulus',
    审核结果: 'Hasil audit',
    审核通过: 'ujian lulus',
    审核信息: 'Tinjau informasi',
    时间: 'waktu',
    实际到账金额: 'Jumlah aktual yang diterima',
    收款方式: 'Cara Pembayaran',
    收款管理: 'Manajemen koleksi',
    收款码: 'Kode pembayaran',
    收款码管理: 'Manajemen kode pembayaran',
    收款码类型: 'Jenis kode pembayaran',
    收款名称: 'Nama penerima pembayaran',
    收款账号: 'Nomor rekening pembayaran',
    手续费: 'Biaya penanganan',
    提现: 'menarik',
    提现币种: 'Mata uang penarikan',
    提现成功: 'Penarikan berhasil',
    提现记录: 'Catatan penarikan',
    提现金额: 'Jumlah penarikan',
    提现失败: 'Penarikan gagal',
    提现时间: 'Waktu penarikan',
    提现手续费: 'Biaya penarikan',
    提现详情: 'Detail penarikan',
    提现中: 'Penarikan',
    提现状态: 'Status penarikan',
    添加收款码: 'Tambahkan kode pembayaran',
    添加银行卡: 'Tambahkan kartu bank',
    退款: 'Pengembalian dana',
    忘记支付密码: 'Lupa kata sandi pembayaran Anda?',
    尾号: 'nomor ekor',
    我的钱包: 'dompet saya',
    我的余额: 'saldo saya',
    详情: 'Detail',
    消费: 'Konsumsi',
    新支付密码: 'Kata sandi pembayaran baru',
    修改支付密码: 'Ubah kata sandi pembayaran',
    选择收款方式: 'Pilih metode pembayaran',
    银行卡: 'kartu bank',
    银行卡管理: 'Manajemen kartu bank',
    银行卡号: 'Nomor kartu bank',
    银行名称: 'nama Bank',
    余额记录: 'Catatan saldo',
    余额数据: 'data saldo',
    原支付密码: 'Kata sandi pembayaran asli',
    暂无数据: 'Tidak ada data',
    支持币种: 'Mata uang yang didukung',
    支付密码: 'Kata sandi pembayaran',
    只可上传一张图片图片大小不超过5M支持xxx格式文件: 'Hanya satu gambar yang dapat diunggah, ukuran gambar tidak melebihi 5M, mendukung file berformat jpg, png, jpeg',
    账户余额: 'Saldo rekening',
    钱包服务: 'Layanan dompet',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'Jumlah maksimum penarikan per hari adalah {num} kali, jumlah maksimum penarikan per waktu adalah {icon}{money}, dan jumlah minimum penarikan adalah {icon}{money2}',
    确认: 'mengonfirmasi',
    输入金额超过可提现余额: 'Jumlah masukan melebihi saldo yang dapat ditarik',
    日期选择: 'pemilihan tanggal',
    备注: 'Komentar',
    当前没有余额记录: 'Saat ini tidak ada catatan saldo!',
    当前没有提现记录: 'Saat ini tidak ada catatan penarikan!',
    还没有绑定银行卡: 'Belum ada kartu bank yang diikat',
    还没有添加收款码: 'Belum ada kode pembayaran yang ditambahkan!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'Tip: Hanya satu gambar yang dapat diunggah, ukuran gambar tidak boleh melebihi 5M, dan file berformat jpg, png, dan jpeg didukung. Kode QR pembayaran yang diunggah harus jelas dan tidak terhalang.',
    请上传收款码: 'Silakan unggah kode pembayaran',
    请输入收款码名称: 'Silakan masukkan nama kode pembayaran',
    打款成功: 'Pembayaran berhasil',
    开始日期: 'mulai tanggal',
    结束日期: 'tanggal akhir',
    请再次输入新密码: 'Silakan masukkan kata sandi baru lagi',
    修改: 'Merevisi',
    请选择收款方式: 'Silakan pilih metode pembayaran',
    收款类型: 'Tipe pembayaran',
    编辑收款码: 'Edit kode pembayaran',
    编辑银行卡: 'Sunting kartu bank',
    收款码名称: 'Nama kode pembayaran',
    订单ID: 'jumlah order',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'Maaf, toko yang Anda kunjungi mungkin telah dihapus, namanya diubah, atau mungkin tidak ada...',
    请选择支付货币: 'Silakan pilih mata uang pembayaran',
    请选择收货地址: 'Tidak ada alamat yang ditambahkan, harap tambahkan terlebih dahulu',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit} {money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "prioritas utama{num}kg {currencyUnit} {money}，Perbarui berat badan{num2}kg {currencyUnit} {money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "barang pertama {num3}bagian-bagian{currencyUnit} {money3}，kelanjutan{num4}bagian-bagian{currencyUnit} {money4}",
    支持配送区域: '（Mendukung area pengiriman：{diqu}。）',
    配送费: '配送费',
    暂无配送区域: 'Belum ada wilayah pengiriman',
    该商品在当前地区暂不支持配送: 'Produk ini saat ini tidak mendukung pengiriman di wilayah saat ini',
    查看运费: 'Lihat biaya pengiriman',
    运费方式: 'Metode pengiriman',
    价格: 'harga',
    请选择配送方式: 'Silahkan pilih metode pengiriman',
    配送方式: 'Metode Pengiriman',
    配送费说明: 'Deskripsi biaya pengiriman',
    我明白了: 'Jadi begitu',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'Jika beberapa produk menggunakan template pengiriman yang sama, biaya pengiriman akan dihitung berdasarkan aturan metode pengiriman yang sama.',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'Jika beberapa produk tidak memiliki template biaya pengiriman yang sama, biaya pengiriman adalah jumlah dari harga beberapa template.',
    快捷登录登录即表示同意协议: 'Dengan masuk, Anda menyetujui perjanjian tersebut',
    打款中: 'Pembayaran sedang berlangsung',
    银行编码: 'Kode Bank',
    卢比支付: 'pembayaran IDR',
    比索支付: 'pembayaran PHP',
    泰铢支付: 'pembayaran THB',
    越南盾支付: 'pembayaran VND',
    请输入大于1的正整数: 'Silakan masukkan bilangan bulat positif yang lebih besar dari 1',
    设置密码: 'mengatur kata sandi',
    登录密码: 'kata sandi masuk',
    设置成功: 'Penyiapan berhasil',
    当前仅支持xxx的金额: 'Saat ini hanya mendukung jumlah dari {currency} {min}-{currency} {max}',
    查看运费: 'Lihat biaya pengiriman',
    运费方式: 'Metode pengiriman',
    价格: 'harga',
    请选择配送方式: 'Silahkan pilih metode pengiriman',
    配送方式: 'Metode Pengiriman',
    配送费说明: 'Deskripsi biaya pengiriman',
    我明白了: 'Jadi begitu',
    "若多个商品选用同一个运费模板，则运费是按同一个运费方式的规则进行计算。": 'Jika beberapa produk menggunakan template pengiriman yang sama, biaya pengiriman akan dihitung berdasarkan aturan metode pengiriman yang sama.',
    '若多个商品不是同一个运费模板，则运费是多个模板的价格之和。': 'Jika beberapa produk tidak memiliki template biaya pengiriman yang sama, biaya pengiriman adalah jumlah dari harga beberapa template.',
    '快捷登录，登录即表示同意协议': 'Dengan masuk, Anda menyetujui perjanjian tersebut',
    促销广告: 'iklan promosi',
    最近新品: 'Produk baru',
    更多: 'Lagi',
    全部分类: 'semua Kategori',
    推荐商品: 'Produk yang direkomendasikan',


    //     1.6
    我的优惠券:'Kupon diskon saya',
    历史记录:'Catatan sejarah',
    已使用:'Digunakan',
    已过期:'kedaluwarsa',
    优惠券:'kupon',
    领取:'Menerima',
    已领取:'Diterima',
    有效期:'Masa berlaku：',
    活动优惠详情:'Detail diskon acara',
    可用商品:'Item yang tersedia',
    单独购买:'Harga asli',
    发起拼团:'Harga grup',
    拼团价:'Harga grup',
    可参与的拼团:'Pembelian grup yang tersedia',
    '再邀X人即可拼单成功':'Bagikan kepada {num}orang lagi agar pesanan berhasil>',
    拼单详情:'Detail pembelian grup',
    待分享:'menunggu untuk dibagikan',
    '若拼团失败，货款将原路退回':'Jika pembelian grup gagal, pembayaran akan dikembalikan.',
    促销价:'Harga penjualan',
    距离结束时间:'End in',
    满XX减XX:"Beli{currencyUnit}{priceStart}dapat potongan{currencyUnit}{reducePrice}{couponType==2?'%':''} harga",
    满XX减X:'Beli{currencyUnit}{priceStart}dapat potongan{reducePrice}% harga',
    分享好友X人成团立省X:'Pembelian grup untuk<span style="color: #8D4BBB;">{num}</span>orang, hemat<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'Bagikan dengan teman',
    '这些人正在拼，立即参与 ':'Pembelian grup sedang berlangsung, bergabunglah sekarang',
    满x可用:'Tersedia untuk pesanan di atas {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'Pembelian grup sedang berlangsung, bergabunglah sekarang',
    限领x张:'{receiveCount} kupon per batas pengguna',
    满XX送赠品:'Hadiah gratis untuk pesanan lebih dari {currencyUnit}{priceStart}',
    去使用:'menggunakan',
    销量:'Penjualan',
    赠品:'Hadiah Gratis',
    限时优惠:'Penawaran terbatas',
    距结束:'End in',
    拼团好物:'Pembelian Kelompok',
    当前优惠券可以购买以下商品:'Kupon saat ini dapat membeli produk berikut:',
    以下商品:'Produk berikut',
    满XX减XX可用:"Beli{currencyUnit}{priceStart}dapat potongan{currencyUnit}{reducePrice}harga",
    满XX减X可用:'Beli{currencyUnit}{priceStart}dapat potongan{reducePrice}% harga',
    再买x可用:'Masih perlu membeli {currencyUnit}{difPrice}',
    合计:'Total',
    优惠:'Diskon',
    去购物车:'kereta Belanja',
    拼团:'Pembelian Kelompok',
    满XX可用:'Tersedia untuk pesanan di atas {currencyUnit}{priceStart}',
    满减满赠:'Diskon penuh/hadiah penuh',
    成团发货:'Tunggu pengiriman',
    还差xx人成团:'Masih membutuhkan <span style="color: #FF4545;">{num}</span>orang untuk berhasil memesan',
    拼团结束:'Pembelian kelompok berakhir',
    拼团成功:'Pembelian grup berhasil',
    有成员未付款请等待付款成团:'Ada yang belum bayar, mohon tunggu pembayarannya',
    我正在参与的拼团:'Berpartisipasi dalam pembelian kelompok',
    满减:'Diskon penuh',
    满赠:'Hadiah penuh',
    规格选择:'Pilih spesifikasi',
    凑单:'Buat pesanan bersama',
    切换其他优惠:'Pilih tawaran lain',
    超过最大购买数:'Melebihi jumlah pembelian maksimum',
    商品限购x件:'Batasi {num} buah',
    剩余:'剩余',
    结束:'结束',
    参与拼团:'Bergabung',
    拼单失败:'Pembelian grup gagal',
    可用优惠券:'Kupon yang tersedia',
    免费:'bebas',
    已赠完:'stok Habis',
    已领完:"Digunakan",
    请勾选删除商品: 'Harap centang untuk menghapus produk',
    活动未开始:'Acara belum dimulai',

    已满X可减X:'Dapatkan diskon {currencyUnit}{reducePrice} bila Anda membelanjakan {currencyUnit}{difPrice} atau lebih ',
    已满X可减XX:'Dapatkan diskon {reducePrice}% bila Anda membelanjakan {currencyUnit}{difPrice} atau lebih',

    再买X可减XX:'Beli lagi {currencyUnit}{difPrice} dan dapatkan diskon {reducePrice}%',
    再买X送赠品:'Beli {currencyUnit}{difPrice} lagi dan dapatkan hadiah gratis',
    已满X送赠品:'Hadiah telah diberikan',
    限X件:'Batas pembelian {num} buah',
    您还没有优惠券:'Anda tidak memiliki kupon',
    x件: '{num}bagian-bagian',


    退货积分:'Poin pengembalian',
    积分:'integral',
    用户注册:'Pendaftaran pengguna',
    手动增加:'Tingkatkan secara manual',
    积分兑换退款:'Pengembalian dana poin',
    下单购物:'Lakukan pemesanan untuk berbelanja',
    过期积分:'Poin kadaluwarsa',
    兑换商品:'Tebus barang',
    手动减少:'Pengurangan manual',
    订单退款:'Pengembalian dana pesanan',
    积分明细:'Detail poin',
    已兑:'Sudah ditebus',
    积分商城:'Poin Mall',
    规则:'aturan',
    可用积分:'Poin Tersedia',
    冻结积分:'titik beku',
    我的兑换:'Pertukaran saya',
    售后:'Setelah terjual',
    分销中心:'Pusat distribusi',
    立即兑换:'Tukarkan sekarang',
    已兑完:'Sudah ditebus',
    "积分不足，无法兑换":'Poin yang tidak mencukupi tidak dapat ditukarkan',
    兑换数量:'Kuantitas pertukaran',
    "限兑*件":'Penebusan terbatas {num} item',
    积分抵扣:'Pengurangan poin',
    "共*分":'Jumlah {num} poin',
    全部订单:'Semua perintah',
    兑换须知:'Instruksi penebusan',
    已达兑换上限:'Batas penukaran telah tercapai',
    我的积分:'Skor saya',
    积分规则:'Aturan poin',
    兑换时间:'Waktu penebusan',

    我的佣金:'komisi saya',
    佣金数据:'Data komisi',
    分销申请协议:'Perjanjian Aplikasi Distribusi',
    申请成为分销员:'Lamar menjadi distributor',
    "已申请，请等待审核":'Sudah diterapkan, harap tunggu peninjauannya',
    "审核拒绝，继续申请":'Ditolak melalui peninjauan, lanjutkan melamar',
    可提佣金:'Komisi penarikan',
    冻结佣金:'Membekukan komisi',
    分销订单:'Urutan distribusi',
    佣金明细:'Detail komisi',
    我的下级:'Bawahan saya',
    推广商品:'Mempromosikan produk',
    推广店铺:'Promosikan toko',
    暂无记录:'Tidak ada catatan',
    一级佣金收入:'Pendapatan komisi tingkat pertama',
    一级佣金退款:'Pengembalian komisi tingkat pertama',
    二级佣金收入:'Pendapatan komisi sekunder',
    二级佣金退款:'Pengembalian komisi tingkat kedua',
    下单用户:'Pengguna penempatan pesanan',
    订单总价:'Harga total pesanan',
    分销利润层级:'Tingkat distribusi',
    结算状态:'status penyelesaian',
    佣金:'Komisi',
    一级:'Level 1',
    二级:'Level 2',
    已结算:'Mapan',
    请输入用户昵称或手机号:'Silakan masukkan nama panggilan pengguna atau nomor ponsel',
    二级团队:'Tim tingkat kedua',
    分享链接:'membagikan tautan',
    可推广至:'Dapat diperluas ke',
    分享最高可赚x:'Hasilkan hingga {icon}{num} dengan berbagi',
    分享推广:'Bagikan dan promosikan',
    用户昵称或手机号:'Nama panggilan pengguna atau nomor ponsel',
    复制:'menyalin',
    商品名称:'nama Produk',
    最高可得:'Tertinggi yang tersedia',
    可获得积分:'poin yang diperoleh',
    总获佣金:'Total komisi yang diperoleh',
    请选择银行卡: 'Silakan pilih kartu bank',
    请选择收款码: 'Silakan pilih kode pembayaran',
    不能超过最小提现额度:'umlah penarikan minimum tidak dapat dilampaui!',

    普通订单:'Pesanan biasa',
    售货机:'mesin penjual otomatis',
    订单类型:'Jenis pesanan',
    请选择类型:'Silakan pilih jenis',
    '房间号/桌号':'Nomor kamar/nomor meja'
}
//印尼
