import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import store from '@/store'
import { removetoken } from '@/utils/auth'
// import Cookies from 'js-cookie'
Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/',
    component: Home,
    meta: { title: '首页' },
    props: { storeID: localStorage.getItem('storeID') }
  },
  {
    path: '/usermy',
    component: () => import('../views/myUser'),
    meta: { title: '个人中心' },
    redirect: '/usermy/personalUser',
    props: { storeID: localStorage.getItem('storeID') },
    children: [
      {
        path: 'personalUser',
        component: () => import('../views/myUser/personalUser'),
        meta: { title: '个人信息' }
      },
      {
        path: 'myOorder',
        component: () => import('../views/myUser/myOorder'),
        meta: { title: '我的订单' }
      },
      {
        path: 'myCoupon',
        component: () => import('../views/myUser/myCoupon'),
        meta: { title: '我的优惠券' }
      },
      {
        path: 'afterSale',
        component: () => import('../views/myUser/afterSale'),
        meta: { title: '售后服务' }
      },
      {
        path: 'addressAdministration',
        component: () => import('../views/myUser/addressAdministration'),
        meta: { title: '地址管理' }
      },
      {
        path: 'leaveMessage',
        component: () => import('../views/myUser/leaveMessage'),
        meta: { title: '留言管理' }
      },
      {
        path: 'messageUser',
        component: () => import('../views/myUser/messageUser'),
        meta: { title: '消息通知' }
      },
      {
        path: 'wallet',
        component: () => import('../views/myUser/wallet'),
        meta: { title: '我的钱包' }
      },
      {
        path: 'pointsMall',
        component: () => import('../views/myUser/pointsMall/pointsMall'),
        meta: { title: '积分商城' }
      },
      {
        path: 'distributionCenter',
        component: () => import('../views/myUser/distributionCenter/application'),
        meta: { title: '分销中心' }
      },

      {
        path: 'distribution',
        component: () => import('../views/myUser/distributionCenter/distribution'),
        meta: { title: '分销中心' }
      }
    ]
  },
  // {
  //   path: '/yjWithdrawalDetails',
  //   component: () => import('../views/myUser/distributionCenter/withdrawalDetails.vue'),
  //   meta: { title: '佣金提现详情' }
  // },
  // {
  //   path: '/yjWithdrawal',
  //   component: () => import('../views/myUser/distributionCenter/withdrawal.vue'),
  //   meta: { title: '佣金提现' }
  // },

  {
    path: '/integralDetails',
    component: () => import('../views/myUser/pointsMall/details.vue'),
    meta: { title: '积分商品详情' }
  },
  {
    path: '/integralRule',
    component: () => import('../views/myUser/pointsMall/integralRule.vue'),
    meta: { title: '积分规则' }
  },
  {
    path: '/AfterParticulars',
    component: () => import('../views/myUser/AfterParticulars'),
    meta: { title: '售后详情' }
  },
  {
    path: '/ExternalWord',
    component: () => import('@/views/ExternalWord'),
    meta: { title: '更多' }
  },
  {
    path: '/search',
    component: () => import('../views/searchList'),
    meta: { title: '搜索' }
  },
  {
    path: '/AfterApplication',
    component: () => import('../views/myUser/AfterApplication'),
    meta: { title: '售后申请' }
  },
  {
    path: '/submitAfterapp',
    component: () => import('../views/myUser/AfterApplication/submitAfterapp'),
    meta: { title: '售后提交成功' }
  },
  {
    path: '/mesageBox',
    component: () => import('../views/myUser/messageUser/mesageBox'),
    meta: { title: '消息详情' }
  },
  {
    path: '/lavex',
    component: () => import('../views/myUser/leaveMessage/lavex'),
    meta: { title: '留言详情' }
  },
  {
    path: '/AboutUs',
    component: () => import('../views/AboutUs'),
    meta: { title: '关于我们' }
  },
  {
    path: '/contactWay',
    component: () => import('../views/contactWay'),
    meta: { title: '联系我们' }
  },
  {
    path: '/activityProducts',
    component: () => import('../views/Allgoods/activityProducts'),
    meta: { title: '可用商品' }
  },
  {
    path: '/addLocation',
    component: () =>
      import('../views/myUser/addressAdministration/addLocation'),
    meta: { title: '添加地址' }
  },
  {
    path: '/selectAddress',
    component: () => import('../views/selectAddress'),
    meta: { title: '选择地址' }
  },
  {
    path: '/Login',
    component: () => import('../views/login'),
    meta: { title: '登录' }
  },
  {
    path: '/register',
    component: () => import('../views/login/register'),
    meta: { title: '注册' }
  },
  {
    path: '/404',
    component: () => import('../views/myUser404.vue'),
    meta: { title: '404' }
  },
  {
    path: '/modifIcation',
    component: () => import('../views/modifIcation'),
    meta: { title: '修改密码' }
  },
  {
    path: '/goods',
    component: () => import('../views/Allgoods'),
    meta: { title: '全部商品' }
  },
  {
    path: '/leaveWangt',
    component: () => import('../views/myUser/leaveMessage/leaveWangt'),
    meta: { title: '我要留言' }
  },

  {
    path: '/productDetails',
    component: () => import('../views/productDetails'),
    meta: { title: '产品详情' }
  },
  {
    path: '/ShoppingCart',
    component: () => import('../views/ShoppingCart'),
    meta: { title: '购物车' }
  },
  {
    path: '/placeOrder',
    component: () => import('../views/placeOrder'),
    meta: { title: '下单' }
  },
  {
    path: '/onlinePayment',
    component: () => import('../views/onlinePayment'),
    meta: { title: '在线支付或货到付款' }
  },
  {
    path: '/paryMent',
    component: () => import('../views/onlinePayment/paryment/index'),
    meta: { title: '二维码支付' }
  },
  {
    path: '/bcel',
    component: () => import('../views/onlinePayment/paryment/bcel'),
    meta: { title: '是否成功支付' }
  },
  {
    path: '/Successhtml',
    component: () => import('../views/onlinePayment/Successhtml'),
    meta: { title: '支付失败与成功' }
  },
  {
    path: '/orderDetails',
    component: () => import('../views/orderDetails'),
    meta: { title: '订单详情' }
  },
  {
    path: '/activityDetails',
    component: () => import('../views/groupBuy'),
    meta: { title: '拼团详情' }
  },
  {
    path: '/logisticsDetails',
    component: () => import('../views/logisticsDetails'),
    meta: { title: '物流详情' }
  },
  {
    path: '/AgreementMassage',
    component: () => import('@/views/AgreementMassage'),
    meta: { title: '协议详情' }
  },
  {
    path: '/404',
    component: () => import('@/views/myUser404'),
    meta: { title: '404' }
  }
]
let needLogin = [
  '/orderDetails',
  '/lavex',
  '/mesageBox',
  '/submitAfterapp',
  '/AfterApplication',
  '/AfterParticulars',
  '/usermy/personalUser',
  '/usermy/myOorder',
  '/usermy/afterSale',
  '/usermy/addressAdministration',
  '/usermy/leaveMessage',
  '/usermy/messageUser',
  '/myUser/wallet'
]

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes: constantRoutes,
  mode: 'history'
})
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
 // 如果当前路径已经是404页面，则不再执行后续逻辑
 console.log('这里拦截了么',to.path);
 if (to.path === '/404') {
  return next();
  }
if (!to.query.storeId&&!localStorage.getItem('storeID')) {
  // 如果storeID不存在，直接跳转到404页面
  router.push('/404');
  return;
  }
  if (to.query.storeId) {
    if(to.query.storeId !=localStorage.getItem('storeID')){
      localStorage.removeItem('parentUserId')
    }
    if (
      localStorage.getItem('userData') &&
      JSON.parse(localStorage.getItem('userData')).storeId != to.query.storeId
    ) {
      removetoken()
      localStorage.removeItem('userData')
      store.dispatch('user/removetoken')
      store.dispatch('order/Seaddress', null)
      // router.push('/login?storeId='+localStorage.getItem("storeID"))
      store.commit('locshoppingcar/lookloacshoppingcarnum')
    }
    localStorage.setItem('storeID', to.query.storeId)
  } else {
    // 如果storeID为空，则设置一个默认值
    to.query.storeId = localStorage.getItem('storeID')
  }

  store.commit('shoppingcar/SET_PREVIOUS_PATH', from.fullPath)

  if (from.path === '/login') {
    needLogin.forEach(item => {
      if (item === to.path) {
        next('/')
      } else {
        next()
      }
    })
  } else {
    next()
  }
})
export default router
