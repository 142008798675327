<template>
  <div id="app" class="app">
    <div class="header" v-show="logins != '1'">
      <Layui :logo="logo"></Layui>
    </div>
    <div class="bodyss">
      <router-view></router-view>
    </div>
    <div class="footer" v-show="logins != '1'">
      <footerBox :logo="logo"></footerBox>
    </div>
    <div class="uptop" @click="cltop" v-show="isVisable">
      <i class="el-icon-arrow-up"></i>
      <div>up</div>
    </div>
  </div>
</template>

<script>
import Layui from '@/views/layou'
import footerBox from '@/views/footerBox'
import { mapGetters } from 'vuex'
import { getStoreIndexInfo } from '@/API/listFront'
import { getStoreUrl } from '@/API/Language'
import { environments } from '@/utils/env'
export default {
  name: 'App',
  components: {
    Layui,
    footerBox
  },
  computed: {
    ...mapGetters(['logins'])
  },
  data () {
    return {
      isVisable: false,
      abox: false,
      logo: {}
    }
  },
  created () {
    if (
      this.$route.query.storeId !== undefined ||
      this.parseUrlParams(location.href).storeId !== undefined
    ) {
      localStorage.setItem(
        'storeID',
        this.$route.query.storeId || this.parseUrlParams(location.href).storeId
      )
    }
    if (
      this.$route.query.lang !== undefined ||
      this.parseUrlParams(location.href).lang !== undefined
    ) {
      localStorage.setItem(
        'lang',
        this.$route.query.lang || this.parseUrlParams(location.href).lang
      )
    }
    if (
      this.$route.query.currency !== undefined ||
      this.parseUrlParams(location.href).currency !== undefined
    ) {
      localStorage.setItem(
        'currency',
        this.$route.query.currency ||
          this.parseUrlParams(location.href).currency
      )
    }
    this.redirect()
    this.creadersize()
    localStorage.setItem('customizeId', '')
    localStorage.setItem('classId1', '')
    localStorage.setItem('classId2', '')
    localStorage.setItem('classId3', '')
    this.creadlocalSshopcart()
    this.gainsoildid()
    // this.logotable()
    // 在 created 生命周期钩子中注册 beforeunload 事件监听器
    // window.addEventListener('beforeunload', this.handleBeforeUnload)
    window.addEventListener('scroll', this.windowScrollListener)
    if (localStorage.getItem('lang')) {
      this.$changeLanguage(localStorage.getItem('lang'))
    }
  },
  beforeunload () {
    this.handleBeforeUnload()
  },
  beforeDestroy () {
    this.handleBeforeUnload()
  },

  mounted () {
    const savedPage = localStorage.getItem('currentPage')
    if (typeof savedPage === 'string') {
      const currentPagePath = this.$route.fullPath
      const savedPagePath = JSON.parse(savedPage).path
      if (currentPagePath !== savedPagePath) {
        // 如果当前页不是保存页，则跳转到保存页面
        this.$router.push(savedPagePath)
      } else {
        localStorage.removeItem('currentPage')
      }
    }
  },
  methods: {
    async redirect () {
      if (location.pathname === '/404'||!localStorage.getItem('storeID')) {
        return
      }
      const newPath = this.getPagePathWithQuery()

      if (!this.shouldRedirect()) {
        return
      }
      let url, isMobile, route
      try {
        const response = await getStoreUrl({
          merchantId: localStorage.getItem('storeID')
        })
        if (!response.data) {
          throw new Error('No data returned')
        }

        url = response.data.web_url.slice(0, -1)
        isMobile = window.innerWidth < 768
        if (isMobile) {
          url = response.data.mobile_url.slice(0, -1)
        }
      } catch (err) {
        console.error(`Failed to retrieve store URL with error: ${err.message}`)
        return
      }
      route = window.location.pathname
      console.log(`${url}${route}${newPath}`,  '-----------------------',window.location.origin,url)
      sessionStorage.setItem('redirected', 'true')
      if (url == window.location.origin) {
        return
      }
      if (environments.apiNow != 'sb') {
        window.location.replace(`${url}${route}${newPath}`)
      }
    },

    shouldRedirect () {
      const redirected = sessionStorage.getItem('redirected')
      return redirected === null || redirected === 'false'
    },

    getPagePathWithQuery () {
      //  window.location.pathname
      return window.location.search
    },
    // 监听屏幕宽度
    creadersize () {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      let w = window.innerWidth
      let a = false
      if (w > 767) {
        a = false
      } else {
        a = true
      }
      this.abox = a
    },
    // 获取店铺logo
    async logotable () {
      if(location.pathname === '/404'||!localStorage.getItem('storeID')){
        return false
      }
      let data1 = {
        storeId: localStorage.getItem('storeID')
      }
      let { data } = await getStoreIndexInfo(data1)
      console.log(data,'阿伟请问饿请问饿请我');
      document.title = data.storeIndexInfo.title
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = data.storeIndexInfo.storeLogo
      document.getElementsByTagName('head')[0].appendChild(link)
      this.logo = data.storeIndexInfo
      localStorage.setItem('logotable', JSON.stringify(data))
    },
    handleBeforeUnload () {
      const savedPage = localStorage.getItem('currentPage')
      if (typeof savedPage === 'string') {
        const savedPagePath = JSON.parse(savedPage).path
        if (savedPagePath !== '/') {
          // 如果已经保存了路径且不是首页，则保存原先访问的页面路径
          localStorage.setItem(
            'currentPage',
            JSON.stringify({ path: this.$route.fullPath })
          )
        } else {
          // 如果已经保存了路径且是首页，则清除本地存储
          localStorage.removeItem('currentPage')
        }
      } else {
        // 当没有匹配到任何路由时，将当前页的路由信息存储到本地
        if (this.$route.matched.length === 0) {
          localStorage.setItem('currentPage', JSON.stringify({ path: '/' }))
        }
      }
    },
    windowScrollListener () {
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop
      if (scrollTop >= 100) {
        this.isVisable = true //大于480时显示元素
      }
      if (scrollTop < 100) {
        this.isVisable = false //小于480时隐藏元素
      }
    },
    // 第一种方法
    parseUrlParams (url) {
      const params = {}
      if (!url || url === '' || typeof url !== 'string') {
        return params
      }
      const paramsStr = url.split('?')[1]
      if (!paramsStr) {
        return params
      }
      const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
      for (let i = 0; i < paramsArr.length / 2; i++) {
        const value = paramsArr[i * 2 + 1]
        params[paramsArr[i * 2]] =
          value === 'true' ? true : value === 'false' ? false : value
      }
      return params
    },
    // 获取店铺id
    gainsoildid () {
      let url = location.href
      this.$store.dispatch('loginfalse/updataorder', '0')
      if (url.indexOf('/?storeId=') !== -1) {
        localStorage.setItem('menutable', '')
      }
      if (
        this.$route.query.classId1 !== undefined ||
        this.parseUrlParams(url).classId1
      ) {
        localStorage.setItem(
          'customizeId',
          this.$route.query.classId1 || this.parseUrlParams(url).classId1
        )
        this.$store.dispatch(
          'user/TablistID',
          this.$route.query.classId1 || this.parseUrlParams(url).classId1
        )
        localStorage.setItem(
          'classId1',
          this.$route.query.classId1 || this.parseUrlParams(url).classId1
        )
      } else {
        // localStorage.setItem("menutable", '');
      }
      if (
        this.$route.query.classId2 !== undefined ||
        this.parseUrlParams(url).classId2
      ) {
        localStorage.setItem(
          'customizeId',
          this.$route.query.classId2 || this.parseUrlParams(url).classId2
        )
        this.$store.dispatch(
          'user/TablistID',
          this.$route.query.classId2 || this.parseUrlParams(url).classId2
        )
        localStorage.setItem(
          'classId2',
          this.$route.query.classId2 || this.parseUrlParams(url).classId2
        )
      }
      if (
        this.$route.query.classId3 !== undefined ||
        this.parseUrlParams(url).classId3
      ) {
        localStorage.setItem(
          'customizeId',
          this.$route.query.classId3 || this.parseUrlParams(url).classId3
        )
        this.$store.dispatch(
          'user/TablistID',
          this.$route.query.classId3 || this.parseUrlParams(url).classId3
        )
        localStorage.setItem(
          'classId3',
          this.$route.query.classId3 || this.parseUrlParams(url).classId3
        )
      }
      if (
        this.$route.query.customizeId !== undefined ||
        this.parseUrlParams(url).customizeId !== undefined
      ) {
        localStorage.setItem(
          'customizeId',
          this.$route.query.customizeId || this.parseUrlParams(url).customizeId
        )
        this.$store.dispatch(
          'user/TablistID',
          this.$route.query.customizeId || this.parseUrlParams(url).customizeId
        )
      } else {
        // localStorage.setItem("menutable", '');
        return
      }
    },
    cltop () {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    tablanmess () {
      localStorage.setItem('tabMessage', '')
    },

    // 购物车信息
    creadlocalSshopcart () {
      let c = JSON.parse(localStorage.getItem('locshoppingcart'))
      if (c === null) {
        let a = []
        localStorage.setItem('locshoppingcart', JSON.stringify(a))
      } else {
        return
      }
    }
  }
}
</script>

<style lang="scss">
.row-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-s {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.row-e {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.row-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-d {
  flex-direction: column;
}

.row-a {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.app {
  .header {
    width: 100%;
    background-color: #fff;
    margin-top: 0px;
    z-index: 99;
  }

  .bodyss {
    padding-top: 00px;
    min-height: 700px;
  }

  .uptop {
    position: fixed;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #6e4faa;
    right: 20px;
    bottom: 140px;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;
    align-items: center;
  }
}

/*  移动端 */
@media screen and (max-width: 767px) {
  .app {
    .header {
      width: 100%;
      background-color: #fff;
      position: fixed;
      margin-top: 0px;
      z-index: 99;
    }

    .bodyss {
      min-height: 600px;
      padding-top: 40px;
    }

    .uptop {
      position: fixed;
      width: 30px;
      height: 30px;
      border-radius: 10px;
      cursor: pointer;
      background-color: #6e4faa;
      right: 10px;
      bottom: 130px;
      color: #fff;
      display: flex;
      justify-content: center;
      font-size: 12px;
      align-items: center;
    }
  }
}
.el-step__head.is-success {
    color: #8D4BBB !important;
    border-color: #8D4BBB !important;
}
.el-step__title.is-success {
  color: #8D4BBB !important;

}
</style>
