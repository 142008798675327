import request from '@/utils/request'
// 验证码
export function Language(data) {
  return request({
    url: '/userConsumer/store/getLanguageListByStoreId',
    method: 'get',
    data
  })
}
export function getStoreUrl(data) {
  return request({
    url: '/userConsumer/store/getStoreUrl',
    method: 'get',
    data
  })
}

export function currency(data){
  return request({
    url: '/userConsumer/store/listCurrencyStore4StoreId',
    method: 'get',
    data
  })
}

// 获取客服
export function StoreFindContact(data){
  return request({
    url: '/threeTerminalCon/StoreCustomAdmin/StoreFindContact',
    method: 'get',
    data
  })
}

// 默认区号
export function AreaCodeDefault(data){
  return request({
    url: '/threeTerminalCon/areaCode/AreaCodeDefault',
    method: 'get',
    data
  })
}