// 英语
export default {
    返回: 'Back',
    用户服务协议及法律声明: 'User Service Agreement and Legal Statement',
    用户服务协议: 'User Service Agreement',
    法律声明: 'Legal Statement',
    隐私政策: 'Privacy Policy',
    商品分类: 'Product Category',
    请选择: 'Please select',
    暂无商品: 'No products',
    请选择商品分类: 'Please select a product category',
    全部: 'All',
    销量从高到低: 'Sales from high to low',
    销量从低到高: 'Sales from low to high',
    价格从高到低: 'Price from high to low',
    价格从低到高: 'Price from low to high',
    推荐产品: 'Recommendations',
    首页: 'Home',
    全部产品: 'All Products',
    搜索: 'Search',
    请输入内容: 'Please enter the details',
    免费注册: 'Sign up',
    邮箱: 'Email',
    请输入邮箱: 'Please enter your email address',
    密码: 'Password',
    请输入密码: 'Please enter a password',
    请设置位密码需包字母及数字: '8-16 characters, including letters and numbers',
    手机号: 'Phone Number',
    验证码: 'Verification Code',
    验证码2: 'Code',
    请勾选商品: 'Please tick the item',

    已阅读并同意以下协议: 'I have read and agreed to',
    注册: 'Sign up',
    已有账号: 'Already have an account',
    直接登录: 'sign in now',
    时: 'hour',
    秒后重新发送: 'Resend in {seconds} seconds',
    发送验证码: 'verification code',
    邮箱必须输入: 'Email is necessary',
    请输入正确的邮箱: 'Please enter a valid email',
    密码必须输入: 'Password is necessary',
    密码最少8位最多16位: 'Password within 8-16 characters',
    请输入正确的密码格式必须包含两种字符: 'Please enter the correct password format must contain two characters',
    请输入正确的手机号: 'Please enter a valid phone number',
    请输入正确验证码: 'Please enter the correct verification code',
    注册成功: 'Sign up successfully',
    需勾选协议: 'Tick the agreement',
    发送成功: 'Sent successfully',
    重新发送验证码: 'verification code',
    登录: 'Sign in',
    密码登录: 'Password',
    验证码登录: 'Verification Code',
    请输入邮箱手机号: 'Please enter your email / phone number',
    请输入账号密码: 'Please enter your account & password',
    立即登录: 'Sign in now',
    我是新用户: "I'm a new user",
    立即注册: 'sign in now',
    忘记密码: 'Forget Password',
    欢迎登录: 'Welcome to',
    取消: 'Cancel',
    确定: 'Confirm',
    验证邮箱: 'Verified Email',
    修改邮箱: 'Change Email',
    手机验证: 'Verify Phone Number',
    邮箱验证: 'Verify Email',
    邮箱修改: 'Change email address',
    完成: 'Finish',
    为确认身份我们需验证您的安全手机: 'To confirm identity, we need to verify your phone number',
    如果您当前的手机号已无法使用请联系客服: 'If your phone number is no longer available, please contact customer service in time',
    当前安全手机号: 'Your Phone Number',
    恭喜您邮箱地址验证成功: 'Congratulations! Email verified successfully',
    请在下次使用邮箱地址进行登录: 'Please sign in with email next time',
    下一步: 'Next',
    返回个人中心: 'Back to personal center',
    修改密码: 'Change Password',
    再次输入新密码: 'Please enter the new password again',
    立即修改: 'Change now',
    请再次输入密码: 'Please enter the password again',
    两次输入密码不一致: 'The passwords entered twice are inconsistent',
    请输入旧密码: 'Please enter the password',
    长度在8到16个字符: '8-16 characters',
    请输入新密码: 'Please enter a new password',
    必须包含两种字符: 'including 2 character types',
    新密码不能和旧密码相同: 'The new password cannot be the same as the old password',
    修改成功: 'Changed successfully',
    找回密码: 'Retrieve password',
    立即提交: 'Submit now',
    姓: 'Surname',
    名: 'Given Name',
    国家区域: 'Country / Region',
    城市: 'City',
    邮政编码: 'Zip Code',
    详细地址: 'Detailed Address',
    默认地址: 'Default Address',
    新增地址: 'Add New Address',
    请输入名: 'Please enter first name',
    长度在1到20个字符: 'Length with 1-20 characters',
    长度在1到8个字符: 'Length with 1-8 characters',
    请输入姓: 'Please enter a last name',
    请选择国家: 'Please select a country',
    请选择城市: 'Please select a state/city/county',
    请输入邮编: 'Please enter a zip code',
    请输入详细地址: 'Please enter a detailed address',
    请输入电话号码: 'Please enter a phone number',
    编辑地址: 'edit address',
    收货地址: 'Shipping Address',
    地址最多10条还能保存xxx条: 'At most 10 addresses. Remaining {seconds} addresses',

    编辑: 'Edit',
    删除地址: 'Delete Address',
    暂无地址信息: 'No addresses yet',
    是否删除地址: 'Confirm to delete the address',
    删除成功: 'Deleted successfully',
    已取消删除: 'Cancel Deletion',
    是否删除: 'Confirm to delete',
    售后申请: 'Request for After-sales',
    售后申请提交成功: 'After-sales requested successfully',
    售后申请提交失败: 'After-sales application submission failed',
    售后专员可能与您电话沟通请保持手机畅通: 'The after-sales specialist may contact you by phone. Please keep your lines open',
    温馨提示: 'Warm tips',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询: 'The return address will be notified by text messages after request approved, or you can check it in the application records。',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通: 'After submitting the request, the after-sales specialist may contact you by phone. Please keep your lines open。',
    退货处理成功后退款金额将原路返回到您的支持账户中: 'The refund will be delivered to your paid account if return is successfully processed；',
    联系客服: 'Customer Service',
    商品信息: 'Product Info',
    单价: 'Unit Price',
    数量: 'Qty',
    小计: 'Subtotal',
    实付: 'Actually paid',
    申请信息: 'Application Information',
    退货类型: 'Return Type',
    我要退款: 'I want a refund',
    我要退货退款: 'I want a return & refund',
    没收到货或与店铺协商同意不用退货只退款: 'Goods undelivered. You can ask for refund only after negotiating with the seller',
    已收到货需要退还收到的货物: 'Goods delivered. Goods need to be returned',
    退货金额: 'Refund Amount',
    不可修改最多: 'Unmodifiable, up to',
    含发货邮费: 'Shipping postage included',
    退货理由: 'Reason for Return',
    请选择退货原因: 'Please select a reason for return',
    问题描述: 'Problem Description',
    上传凭证: 'Upload Vouchers',
    为了帮助您更好的解决问题请上传图片: 'To help you better solve the problem, please upload pictures',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件: 'Upload at most 5 pictures (bmp, gif, jpg, png or jpeg, each within 5M)',
    联系方式: 'Contact',
    联系人: 'Contact',
    请输入联系人: 'Please enter a contact name',
    联系电话: 'Contact Number',
    确认提交: 'Confirm to submit',
    客服电话: 'Hotline',
    客服邮箱: 'Email',
    请输入名字: 'Please enter a name',
    长度在2到10个字符: 'Length with 2-10 characters',
    请输入电话: 'Please enter a phone number',
    请描述问题: 'Please describe the issue',
    最多上传五张: 'Up to five uploads',
    最多上传一张: 'Up to one uploads',
    上传成功: 'Uploaded successfully',
    文件过大: 'file too large',
    售后单号: 'After-sales Order No.',
    申请时间: 'Application Time',
    重新申请: 'Re-apply',
    取消售后: 'Canceled after-sales',
    退款失败: 'Refund failed',
    退款中: 'Refunding',
    退款完成: 'Refund completed',
    提交申请: 'Submit request',
    店铺处理: 'In Progress',
    店铺退款: 'Refunding',
    售后完成: 'After-sales completed',
    售后商品: 'After-sales Product',
    售后信息: 'After-sales Info',
    售后方式: 'After-Sales Method',
    退款金额: 'Refund Amount',
    退款原因: 'Refund Reason',
    退款说明: 'Refund Instructions',
    购买信息: 'Purchase Information',
    商品单价: 'Unit Price',
    件: 'pieces',
    实付金额: 'Amount Paid',
    发货件数: 'Shipped Pieces',
    发货状态: 'Delivery Status',
    订单编号: 'Order No.',
    等待店铺审核: "Waiting for seller's review",
    留言内容: 'Message Details',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料: 'You can leave a message to the seller here. To protect your rights and interests, please upload detailed information as more as possible',
    发表留言: 'left a comment',
    店: 'S',
    客: 'C',
    客户: 'The customer',
    店铺: 'Seller',
    退货原因: 'reasons for return',
    退货数量: 'Return Qty',
    请输入反馈内容: 'Please enter your feedback',
    取消成功: 'Canceled successfully',
    发表成功: 'Successfully published',
    添加超时: 'add timeout',
    下单时间: 'Order Time',
    查看详情: 'Check Details',
    服务单号: '服务单号',
    暂无售后信息: 'No after-sale orders yet.',
    留言回复: 'Reply',
    x条回复: '{num}replies',
    留言回复长度xxx个字符: 'Reply with 2-100 words',
    提交: 'Submit',
    长度在2到100个字符: 'Length with 2-100 characters',
    评论成功: 'Commented successfully',
    手机号码: 'Phone Number',
    邮箱地址: 'Email Address',
    立即留言: 'Leave a message now',
    请填写留言内容: 'Please enter your message',
    留言成功: 'Messaged successfully',
    留言管理: 'Message management',
    我要留言: 'Comment',
    回复: 'Reply',
    查看全部: 'View all',
    查看全部x条回复: 'View all {num} replies',
    暂无留言信息: 'No comments yet.',
    全部消息: 'All Messages',
    已读消息: 'Read',
    未读消息: 'Unread',
    标记已读: 'Marked as read',
    删除: 'Delete',
    暂无消息通知: 'No notifications yet.',
    请勾选信息: 'Please tick messages',
    请选择删除项需已读才能删除: 'Please select items first. Only read messages can be deleted.',
    待付款: 'Unpaid',
    待发货: 'Unshipped',
    待收货: 'Undelivered',
    已完成: 'Completed',
    订单号: 'Order No.',
    订单金额: 'Order Amount',
    已关闭: 'Closed',
    部分订单取消: '部分订单取消',
    部分退款完成: '部分退款完成',
    部分退款: '部分退款',
    部分收货: '部分收货',
    交易异常: 'transaction exception',
    部分发货: 'Partial shipment',
    发货时间: 'Shipping Time',
    退款成功: 'Refunded successfully',
    取消原因: 'Reason for Cancellation',
    暂无订单信息: 'No orders yet.',
    删除订单成功: 'Order deleted successfully',
    订单已超时: 'Order has timed out',
    确认收货成功: 'Delivered successfully',
    取消订单成功: "Order canceled successfully",
    当前头像: 'Current Avatar',
    点击上传: 'Click to upload',
    昵称: 'Alias',
    保密: 'Private',
    男: 'Male',
    女: 'Female',
    昵称不能为空: 'No empty alias',
    退出登录: 'Sign out',
    上传失败: 'upload failed',
    上传头像图片只能是JPG格式: 'Only JPG avatar image allowed!',
    上传头像图片大小不能超过2MB: 'The size of the uploaded avatar picture cannot exceed 2MB!',
    个人信息: 'Personal Info',
    我的订单: 'My Order',
    售后服务: 'After-sales service',
    地址管理: 'Address Management',
    扫码支付: 'Scan to Pay',
    消息通知: 'Notifications',
    微信支付: 'WeChat Pay',
    交易单号: 'Transaction No.',
    支付成功: 'Paid successfully',
    支付失败: 'payment failed',
    查看订单: 'View orders',
    返回首页: 'Back to Home',
    应付总额: 'Amount Due',
    请您在: 'Please pay it within',
    内完成支付否则订单会被自动取消: ', otherwise the order will be automatically canceled.',
    确定放弃支付吗: 'Are you sure you want to give up the payment?',
    订单提交成功请尽快付款: 'Order submitted successfully, please pay as soon as possible！',
    选择以下支付方式付款: 'Select payment ways below',
    继续支付: 'continue to pay',
    残忍离开: 'leave cruelly',
    提示: 'hint',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货: "Order paid offline won't support online after-sales service. If you need, please contact the seller. For paid order, please wait for payment delivered to the seller and shipment patiently！",
    您可以参考如下线下付款方式: 'Preferably payment ways below',
    支付凭证: 'Payment Voucher',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持xxx格式图片不大于3M: 'If paid by the above ways, please upload relevant vouchers, e.g. transfer screenshots (jpg, png supported, each no larger than 3M).',
    订单已取消: 'order has been canceled',
    分: 'minute',
    秒: 'second',
    请选择付款方式: 'Please select a payment method',
    实付款: 'Actually paid',
    请在xxx内完成支付否则订单会被自动取消: 'Please complete the payment within {seconds}, otherwise the order will be automatically canceled',
    请您在x内完成支付否则订单会被自动取消: 'Please complete the payment within<span style="color: red;">{num}</span>, otherwise the order will be automatically canceled',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消: 'You are currently paying offline, please wait patiently for the store to confirm receipt！If the store does not confirm the payment after<span style="color: red;">{num}</span>, the order will be automatically canceled!',
    您当前是线下支付请耐心等待店铺确认收款若: 'You are currently paying offline, please wait patiently for the store to confirm receipt！If the store does not confirm the payment after',
    后店铺未确认收款订单将自动取消: ' , the order will be automatically canceled!',
    退货申请中: 'return request',
    删除订单: 'Delete Order',
    确认收货: 'Confirm delivery',
    取消订单: 'Cancel',
    去付款: 'Pay',
    支付方式: 'Payment Way',
    支付宝: 'Alipay',
    微信: 'WeChat',
    线下支付: 'Offline Payment',
    商品总额: 'Grand Total',
    运费: 'Freight Charge',
    收货信息: 'Consignee Info',
    创建时间: 'Creation Time',
    付款时间: 'Payment Time',
    订单取消时间: 'Cancellation Time',
    收件人: 'Recipient',
    地址: 'Address',
    部分商品正在退款中: 'Some items are being refunded',
    已经有一个账户: 'already have an account？',
    天: 'day',
    联系信息: 'contact information',
    收件地址: 'Shipping Address',
    切换地址: 'Switch Address',
    电子邮件地址: 'Email address',
    通过电子邮件向我发送新闻和优惠: 'Email me news and offers',
    姓名: 'Name',
    完整地址: 'Complete address',
    电话: 'Telephone',
    还没有收货地址哦: 'No shipping address yet',
    添加地址: 'Add new address',
    在线支付: 'Online Payment',
    商品: 'Product',
    备注说明: 'Remark',
    订单: 'Order',
    商品件数: 'Product Qty.',
    商品总价: 'Grand Total',
    活动优惠: 'Discount',
    优惠卷抵扣: 'Deduction',
    提交订单: 'Submit Order',
    库存: 'Stock',
    描述文字: 'description text',
    发货地: 'Shipping Place',
    缺少库存: 'out of stock',
    添加购物车: 'Add to cart',
    立即购买: 'Buy now',
    此宝贝已下架: 'out of stock',
    描述: 'Description',
    添加成功: 'Added successfully',
    添加失败库存不足: 'Add failed Insufficient stock',
    请选择规格: 'Please select specifications',
    请输入商品名称: 'Please enter a product name',
    综合排序: 'Comprehensive Rank',
    销量高低: 'Sort by Sales',
    价格高低: 'Sort by Price',
    没有找到搜索的内容: 'No search results',
    选择地址: 'Select address',
    是否选择该地址: 'Confirm to select the address',
    选择成功: 'choose success!',
    未选择地址或没有地址: 'No address selected or no address',
    已取消: 'Cancelled',
    操作: 'Operation',
    全选: 'Select All',
    已失效商品: 'Expired Items',
    已下架: 'Removed',
    删除选中商品: 'Delete selected items',
    共x件商品已选择x件: 'Total {num} items, {num2} items selected',
    商品合计: ' Grand Total',
    下单结算: 'Settlement',
    失效宝贝: 'Expired Items',
    删除失效商品: 'Delete invalid items',
    您的购物车还没有商品哟: 'No products in your cart yet.',
    继续逛逛: 'Go for more',
    性别: 'Gender',
    确认已付款: 'Paid already',
    立即支付: 'Pay immediately',
    个人中心: 'Personal center',
    确定删除选中的商品吗: 'Are you sure you want to delete the selected item?',
    未发货: 'Unshipped',
    已发货: 'Shipped',
    请输入下方图形验证码: 'Please enter verification code',
    看不清换一张: 'Change one',
    KBZ支付: 'KBZPay',
    BCEL支付: 'BCEL',
    复制成功: 'Copied successfully',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名: 'Please enter the content of the message. If you use BCEL or KBZPay to pay, please enter the bank card number and account name used to receive the refund here',
    查看支付是否成功: 'Check if the payment is successful>>>',
    查询: 'Check',
    语言: 'Language',
    保存: 'Save',
    请选择语言: 'Please select a language',
    货币: 'Currency',
    请选择展示货币: 'Please select display currency',
    您未支付请重新支付: 'You have not paid, please pay again',
    确定已输入接收退款的银行卡号和户名: 'Make sure you have entered the bank card number and account name to receive the refund',
    分享店铺: 'Share store',
    分享至: 'Share to',
    绑定: 'Bind',
    绑定邮箱: 'Bind email',
    海报: 'poster',
    分享: 'Share',
    推荐店铺给你: 'Recommended mall to you',
    分享店铺至: '分享店铺至',

    至: 'to',
    币种: 'Currency',
    变动理由: 'Reason for change',
    不记得: "Don't remember",
    充值: 'Recharge',
    打款凭证: 'Payment Voucher',
    打款说明: 'Payment Instructions',
    打款信息: 'Payment information',
    打款状态: 'Payment status',
    待打款: 'Pending Withdrawal',
    待审核: 'Unreviewed',
    到账方式: 'Payment method',
    到账银行卡号: 'Receiving Bank',
    冻结中: 'Freezing',
    国家: 'Nation',
    获取验证码: 'Get verification code',
    记得: 'Remember',
    记录时间: 'Recording time',
    拒绝打款: 'Refuse to pay',
    开户人: 'Account holder',
    开户支行: 'Issuing Bank Branch',
    可提现: 'Withdrawal available',
    可提现金额: 'Withdrawable Amount',
    立即提现: 'Withdraw immediately',
    流水号: 'Serial No.',
    没有适合此币种的银行卡请先添加: 'There is no bank card suitable for this currency, please add it first',
    没有收款码请先添加: 'No payment code, please add it first ',
    您是否记得账号当前使用的支付密码: 'Do you remember the payment password currently used by your account?',
    您未设置支付密码请先设置: 'You have not set a payment password, please set it first',
    钱包余额: 'wallet balance',
    请输入开户人: 'Please enter the account holder',
    请输入开户支行: 'Please enter issuing bank branch',
    请输入收款名称: 'Please enter the payment name',
    请输入提现金额: 'Please enter the withdrawal amount',
    请输入新支付密码6位数字: 'Please enter the new payment password, 6 digits',
    请输入新支付密码: 'Please enter new payment password',
    请输入银行卡号: 'Please enter the bank card number',
    请输入原支付密码: 'Please enter the original payment password',
    请输入支付密码6位数字: 'Please enter the payment password, 6 digits',
    请输入支付密码: 'Please enter the payment password ',
    请选择币种: 'Please select currency',
    请选择大洲: 'Please select a continent',
    请选择结束时间: 'Please select an end date',
    请选择开始时间: 'Please select a start date',
    请选择收款码类型: 'Please select the payment code type',
    请选择银行: 'Please select a bank',
    请选择银行名称: 'Please select bank name',
    请再次输入新支付密码: 'Please enter the new payment password again',
    请再次输入支付密码: 'Please enter the payment password again',
    去设置: 'Go to settings',
    全部提现: 'Withdraw All',
    确定删除收款码吗: 'Are you sure you want to delete the payment code?',
    确定删除银行卡吗: 'Are you sure you want to delete the bank card?',
    确定提现: 'Confirm withdrawal',
    确认打款: 'Confirm Payment',
    确认新密码: 'Please enter the new payment password again',
    确认支付密码: 'Confirm payment password',
    上传的收款二维码需要清晰无遮挡: 'The uploaded payment QR code must be clear and unobstructed',
    审核备注: 'Review Remarks',
    审核不通过: 'Audit not passed',
    审核结果: 'Review Result',
    审核通过: 'Review Passed',
    审核信息: 'Review information',
    时间: 'Time',
    实际到账金额: 'Actual amount received',
    收款方式: 'payment method',
    收款管理: 'Collection management',
    收款码: 'Payment code',
    收款码管理: 'Payment code management',
    收款码类型: 'Payment code management',
    收款名称: 'Payee name',
    收款账号: 'Payment account number',
    手续费: 'Withdrawal Fee',
    提现: 'Withdraw',
    提现币种: 'Withdrawal currency',
    提现成功: 'Successful Withdrawal',
    提现记录: 'Withdrawals record',
    提现金额: 'Withdrawal Amount',
    提现失败: 'Withdrawal Failed',
    提现时间: 'Withdrawal time',
    提现手续费: 'Withdrawal fee',
    提现详情: 'Withdrawal details',
    提现中: 'Withdrawing',
    提现状态: 'Withdrawal Status',
    添加收款码: 'Add payment code',
    添加银行卡: 'Add a bank card',
    退款: 'Refund',
    忘记支付密码: 'Forgot your payment password?',
    尾号: 'tail number',
    我的钱包: 'My purse',
    我的余额: 'My balance',
    详情: 'Details',
    消费: 'Consumption',
    新支付密码: 'New payment password',
    修改支付密码: 'Change payment password',
    选择收款方式: 'Select payment method',
    银行卡: 'Bank Card',
    银行卡管理: 'Bank card management',
    银行卡号: 'Card No.',
    银行名称: 'Bank name',
    余额记录: 'Balance record',
    余额数据: 'Balance data',
    原支付密码: 'Original payment password',
    暂无数据: 'No data',
    支持币种: 'Currencies Supported',
    支付密码: 'Payment password',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件: 'Only one image can be uploaded, the image size does not exceed 5M, supports jpg, png, jpeg format files',
    账户余额: 'Account Balance',
    钱包服务: 'Wallet service',

    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'The maximum withdrawal amount per day is {num} times, the maximum withdrawal amount per time is {icon}{money}, and the minimum withdrawal amount is {icon}{money2}',
    确认: 'Confirm',
    输入金额超过可提现余额: 'The input amount exceeds the withdrawable balance',
    日期选择: 'date selection',
    备注: 'Remark',
    当前没有余额记录: 'There is currently no balance record!',
    当前没有提现记录: 'There is currently no withdrawal record!',
    还没有绑定银行卡: 'No bank card has been bound yet!',
    还没有添加收款码: 'No payment code has been added yet!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'Tip: Only one picture can be uploaded, the size of the picture should not exceed 5M, and jpg, png, and jpeg format files are supported. The uploaded payment QR code must be clear and unobstructed.',
    请上传收款码: 'Please upload the payment code',
    请输入收款码名称: 'Please enter the payment code name',
    打款成功: 'Payment successful',
    开始日期: 'start date',
    结束日期: 'end date',
    请再次输入新密码: 'Please enter the new password again',
    请输入验证码: 'Please enter the phone verification code',
    修改: 'Modify',
    请选择收款方式: 'Please select payment method',
    收款类型: 'Payment type',
    编辑银行卡: 'Edit bank card',
    编辑收款码: 'Edit payment code',
    收款码名称: 'Payment code name',
    订单ID: 'Order ID',
    加载中: 'Loading',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'Sorry, the store you visited may have been deleted, its name has been changed, or it may not exist...',
    请选择支付货币: 'Please select payment currency',
    请选择收货地址: 'No address added, please add it first',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit} {money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "First Standard Weight{num}kg {currencyUnit} {money}，Additional Weight{num2}kg {currencyUnit} {money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "First Parcel{num3}pieces{currencyUnit} {money3}，Additional Parcels{num4}pieces{currencyUnit} {money4}",
    支持配送区域: '（Support delivery area：{diqu}。）',
    配送费: '配送费',
    暂无配送区域: 'No delivery area yet',
    该商品在当前地区暂不支持配送: 'This product does not currently support delivery in the current region',
    查看运费: 'View shipping cost',
    运费方式: 'Shipping method',
    价格: 'price',
    请选择配送方式: 'Please select a shipping method',
    配送方式: 'Delivery Method',
    配送费说明: 'Delivery fee description',
    我明白了: 'I see',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'If multiple products use the same freight template, the freight will be calculated according to the rules of the same freight method.',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'If multiple products do not have the same shipping fee template, the shipping fee is the sum of the prices of the multiple templates.',
    快捷登录登录即表示同意协议: 'By logging in you agree to the agreement',
    打款中: 'Payment in progress',
    银行编码: 'Bank code',
    卢比支付: 'IDR payment',
    比索支付: 'PHP payment',
    泰铢支付: 'THB payment',
    越南盾支付: 'VND payment',
    请输入大于1的正整数: 'Please enter a positive integer greater than 1',
    设置密码: 'Set password',
    登录密码: 'Login password',
    设置成功: 'Setup successful',
    当前仅支持xxx的金额: 'Currently only supports amounts from {currency} {min}-{currency} {max}',
    查看运费: 'View shipping costs',
    运费方式: 'Shipping method',
    价格: 'price',
    请选择配送方式: 'Please select a shipping method',
    配送方式: 'Delivery Method',
    配送费说明: 'Delivery fee description',
    我明白了: 'I see',
    '若多个商品选用同一个运费模板，则运费是按同一个运费方式的规则进行计算。': 'If multiple products use the same freight template, the freight will be calculated according to the rules of the same freight method.',
    '若多个商品不是同一个运费模板，则运费是多个模板的价格之和。': 'If multiple products do not have the same shipping fee template, the shipping fee is the sum of the prices of the multiple templates.',
    '快捷登录，登录即表示同意协议': 'By logging in you agree to the agreement',
    促销广告: 'Promotional advertising',
    最近新品: 'New Products',
    更多: 'More',
    全部分类: 'All Categories',
    推荐商品: 'Recommendations',

    //     1.6
    我的优惠券:'My discount coupon',
    历史记录:'History record',
    已使用:'Used',
    已过期:'Expired',
    优惠券:'Coupon',
    领取:'Receive',
    已领取:'Received',
    有效期:'Validity period：',
    活动优惠详情:'Event discount details',
    可用商品:'Available items',
    单独购买:'Original price',
    发起拼团:'Group price',
    拼团价:'Group price',
    可参与的拼团:'Available group buyings',
    '再邀X人即可拼单成功':'Share with {num} more people for successful order>',
    拼单详情:'Group purchase details',
    待分享:'To be shared',
    '若拼团失败，货款将原路退回':'If the group purchase fails, the payment will be refunded.',
    促销价:'Promotional price',
    距离结束时间:'End in',
    满XX减XX:"Spend {currencyUnit}{priceStart}get{currencyUnit}{reducePrice}{couponType==2?'%':''} off" ,
    满XX减X:'Spend{currencyUnit}{priceStart}get{reducePrice}% off',
    分享好友X人成团立省X:'Group purchase for<span style="color: #8D4BBB;">{num}</span>people，save<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'Share with friends',
    '这些人正在拼，立即参与 ':'Group buying in progress, join now ',
    满x可用:'Available for orders over {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'Group buying in progress, join now ',
    限领x张:'{receiveCount} coupons per user limit',
    满XX送赠品:'Spend{currencyUnit}{priceStart}get free gift',
    去使用:'To use',
    销量:'Sales',
    赠品:'Giveaway',
    限时优惠:'Limited Time Offer',
    距结束:'End in',
    拼团好物:'Group Buying',
    当前优惠券可以购买以下商品:'The current coupon can purchase the following products:',
    以下商品:'The following products',
    满XX减XX可用:"Spend {currencyUnit}{priceStart} get {currencyUnit}{reducePrice}off",
    满XX减X可用:'Spend {currencyUnit}{priceStart} get {reducePrice}% off',
    再买x可用:'Available for orders over {currencyUnit}{difPrice}',
    合计:'Total',
    优惠:'Discount',
    去购物车:'To cart',
    拼团:'Group Buying',
    满XX可用:'Available for orders over {currencyUnit}{priceStart}',
    满减满赠:'Full discount/full gift',
    成团发货:'Wait for delivery',
    还差xx人成团:'Still need<span style="color: #FF4545;">{num}</span>people to place orders successfully',
    拼团结束:'Group buying ends',
    拼团成功:'Group purchase successful',
    有成员未付款请等待付款成团:'Someone has not paid, please wait for payment',
    我正在参与的拼团:'Participating in group buying',
    满减:'Full discount',
    满赠:'Full gift',
    规格选择:'Select specifications',
    凑单:'Make an order together',
    切换其他优惠:'Select another offer',
    超过最大购买数:'Exceeded maximum number of purchases',
    商品限购x件:'Limit {num} pieces',
    剩余:'剩余',
    结束:'结束',
    参与拼团:'Join',
    拼单失败:'Group purchase failed',
    可用优惠券:'Available coupons',
    详情:'详情',
    免费:'Free',
    已赠完:'Out of stock',
    已领完:"Used up",
    请勾选删除商品: 'Please tick the items that need to be deleted',
    活动未开始:'The event has not started',

    已满X可减X:'Discount {currencyUnit}{difPrice} for purchases over {currencyUnit}{reducePrice}',
    已满X可减XX:'Discount {reducePrice}% for purchases over {currencyUnit}{difPrice}',

    再买X可减XX:'Buy another {currencyUnit}{difPrice} and get {reducePrice}% off',
    再买X送赠品:'Buy another {currencyUnit}{difPrice} and get a free gift',
    已满X送赠品:'Gifts have been given',
    限X件:'Purchase limit {num} pieces',
    您还没有优惠券:"You don't have a coupon",
    x件: '{num}pieces',


    退货积分:'Return points',
    积分:'points',
    用户注册:'User registration',
    手动增加:'Manually increase',
    积分兑换退款:'Points refund',
    下单购物:'Place an order for shopping',
    过期积分:'Expired points',
    兑换商品:'Redeem goods',
    手动减少:'Manual reduction',
    订单退款:'Order refund',
    积分明细:'Points details',
    已兑:'Already redeemed',
    积分商城:'Points Mall',
    规则:'rule',
    可用积分:'Points Available',
    冻结积分:'freeze points',
    我的兑换:'My exchange',
    售后:'After-sales',
    分销中心:'Distribution center',
    立即兑换:'Redeem now',
    已兑完:'Already redeemed',
    "积分不足，无法兑换":'Insufficient points cannot be redeemed',
    兑换数量:'Exchange quantity',
'限兑*件':'Limited redemption {num} items',
    积分抵扣:'Points deduction',
'共*分':'Total {num} points',
    全部订单:'All orders',

兑换须知:'Redemption instructions',
    已达兑换上限:'The redemption limit has been reached',
    我的积分:'My scores',
    积分规则:'Points rules',
    兑换时间:'Redemption time',
    我的佣金:'my commission',
    佣金数据:'Commission data',
    分销申请协议:'Distribution Application Agreement',
    申请成为分销员:'Apply to become a distributor',
    "已申请，请等待审核":'Already applied, please wait for review',
    "审核拒绝，继续申请":'Rejected by review, continue to apply',
    可提佣金:'Withdrawal commission',
    冻结佣金:'Freeze commission',
    分销订单:'Distribution order',
    佣金明细:'Commission details',
    我的下级:'My subordinates',
    推广商品:'Promote products',
    推广店铺:'Promote store',
    暂无记录:'No records',
    一级佣金收入:'First level commission income',
    一级佣金退款:'First level commission refund',
    二级佣金收入:'Secondary commission income',
    二级佣金退款:'Second level commission refund',
    下单用户:'Order placing user',
    订单总价:'Order total price',
    分销利润层级:'Distribution level',
    结算状态:'settlement status',
    佣金:'commission',
    一级:'Level 1',
    二级:'Level 2',
    已结算:'Settled',
    请输入用户昵称或手机号:'Please enter user nickname or mobile phone number',
    二级团队:'Second level team',
    分享链接:'Share link',
    可推广至:'Can be extended to',
    分享最高可赚x:'Earn up to {icon}{num} by sharing',
    分享推广:'Share and promote',
    用户昵称或手机号:'User nickname or mobile phone number',
    复制:'copy',
    商品名称:'product name',
    最高可得:'Highest available',
    可获得积分:'Points earned',
    总获佣金:'Total commission earned',
    请选择银行卡: 'Please select a bank card',
    请选择收款码: 'Please select payment code',
    不能超过最小提现额度:'The minimum withdrawal amount cannot be exceeded!',

    普通订单:'Ordinary order',
    售货机:'vending machine',
    订单类型:'Order type',
    请选择类型:'Please select type',
    '房间号/桌号':'Room number/table number'
}
//英语
