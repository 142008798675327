import request from '@/utils/request'

// 添加商品进购物车
export function userSaveGoodsCart(data) {
  return request({
    url: '/userConsumer/userGoodsCart/userSaveGoodsCart',
    method: 'post',
    data
  })
}

// 用户购物车查询
export function listGoodsCart4user(params) {
  return request({
    url: '/userConsumer/userGoodsCart/listGoodsCart4user',
    method: 'post',
    params
  })
}

// 删除购物车商品
export function removeGoodsCart(params) {
  return request({
    url: '/userConsumer/userGoodsCart/removeGoodsCart',
    method: 'post',
    params
  })
}

// 修改购物车商品件数
export function updateGoodsCart2Num(params) {
  return request({
    url: '/userConsumer/userGoodsCart/updateGoodsCart2Num',
    method: 'post',
    params
  })
}