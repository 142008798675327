import jweixin from "jweixin-module";
import {
    wechatShare
} from '@/API/user'

export default {
    async weiXinShareFn(title, description, resource) {
        // 获取url
        console.log(window.location);
        let linkUrl = window.location.origin + window.location.pathname
        if (window.location.search.indexOf('?token=') > -1) {
            linkUrl = window.location.origin + window.location.pathname
        } else {
            linkUrl = window.location.href
        }
        console.log(linkUrl);

        const u = navigator.userAgent
        const isWeixin = u.toLowerCase().indexOf('micromessenger') !== -1

        // const pages = getCurrentPages()
        // const page = pages[pages.length - 1]
        const pageTitle = ''
        // let self = this

        // 判断当前是否在微信浏览器内
        if (isWeixin) {
            let res = await wechatShare({
                url: decodeURIComponent(linkUrl)
            })
            if (res.code == 200) {

                jweixin.config({
                    debug: false, // 调试模式
                    appId: res.data.appId, // 公众平台申请的id
                    timestamp: res.data.timestamp, // 时间戳
                    nonceStr: res.data.noncestr, // 随机字符串
                    signature: res.data.signature, // 签名
                    jsApiList: [ // 申请需要使用的api
                        'updateAppMessageShareData',
                        'updateTimelineShareData'
                    ]
                })
                jweixin.ready(() => {
                    const shareData = {
                        title: title ? title : pageTitle, // 标题
                        desc: description ? description : '', //详情
                        link: decodeURIComponent(linkUrl), // h5链接
                        imgUrl: resource ? resource :
                            'https://shopweb.bawanli.com/assets/img/share_logo.jpg', // 图片链接 只能是网络连接 https://shopweb.bawanli.com/assets/img/share_logo.jpg
                        success: function () {
                            // alert(title)
                            // 设置成功
                        }

                    }
                    //分享给朋友
                    jweixin.updateAppMessageShareData(shareData)
                    //分享到朋友圈
                    jweixin.updateTimelineShareData(shareData)
                })
            }
        }
    }
}
