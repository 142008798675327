import Cookies from 'js-cookie'

const TokenKey= 'Admin_token'

export function gettoken() {
  return Cookies.get(TokenKey)
}

export function settoken(value) {
  let values='Bearer '+value
  return Cookies.set(TokenKey,values)
}
export function removetoken() {
  return Cookies.remove(TokenKey)
}
