export const environments = {
    test: {
        apiUrl: 'https://gateway-test.bawanli.com',
        paymentUrl: 'https://saas-zf-test.bawanli.com/'
    },
    prod: {
        apiUrl: 'https://gateway.whatsbuy.com',
        paymentUrl: 'https://saas-zf.bawanli.com/'
    },
    uat: {
        apiUrl: 'https://gateway-uat.whatsbuy.com',
        paymentUrl: 'https://saas-zf-uat.bawanli.com/'
    },
    sb: {
        // 本地
        apiUrl: 'http://192.168.110.36:8098', //本地欧阳
        // apiUrl: 'http://192.168.110.154:8098', //本地磊哥
        // apiUrl:'http://192.168.110.248:8098', //本地玉杰
        paymentUrl: 'https://saas-zf-test.bawanli.com/'
    },
    apiNow: 'prod'
  }
