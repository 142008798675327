// 马来西亚
export default {
    BCEL支付: 'BCEL',
    KBZ支付: 'KBZPay',
    保密: 'simpan rahsia',
    备注说明: 'Arahan manual',
    必须包含两种字符: 'Mesti mengandungi dua aksara',
    编辑: 'edit',
    编辑地址: 'edit alamat',
    标记已读: 'tanda sebagai telah dibaca',
    不可修改最多: 'Tidak boleh diubah suai, sehingga',
    部分订单取消: 'pembatalan separa pesanan',
    部分发货: 'Penghantaran separa',
    部分商品正在退款中: 'Beberapa item sedang dipulangkan',
    部分收货: 'separa resit',
    部分退款: 'Bayaran balik separa',
    部分退款完成: 'Bayaran balik separa selesai',
    残忍离开: 'pergi dengan kejam',
    操作: 'beroperasi',
    查看订单: 'semak pesanan',
    查看全部: 'lihat semua',
    查看全部x条回复: 'Lihat semua {num} balasan',
    查看详情: 'semak butiran',
    查看支付是否成功: 'Semak sama ada pembayaran berjaya>>>',
    查询: 'Siasatan',
    城市: 'Bandar',
    创建时间: 'masa penciptaan',
    此宝贝已下架: 'kehabisan stok',
    待发货: 'untuk dihantar',
    待付款: 'Pembayaran belum selesai',
    待收货: 'Akan Diterima',
    单价: 'harga seunit',
    当前安全手机号: 'Nombor telefon keselamatan semasa',
    当前头像: 'avatar semasa',
    地址: 'alamat',
    地址管理: 'pengurusan alamat',
    地址最多10条还能保存xxx条: 'Maksimum 10 alamat, tinggal {seconds} alamat',
    登录: 'Log masuk',
    等待店铺审核: 'Menunggu semakan kedai',
    点击上传: 'Klik untuk memuat naik',
    电话: 'nombor telefon',
    电子邮件地址: 'Alamat emel',
    店: 'S',
    店铺: 'kedai',
    店铺处理: 'pengendalian kedai',
    店铺退款: 'bayaran balik kedai',
    订单: 'Pesanan',
    订单编号: 'nombor pesanan',
    订单号: 'nombor pesanan',
    订单金额: ' jumlah pesanan',
    订单取消时间: 'Masa Pembatalan Pesanan',
    订单提交成功请尽快付款: 'Pesanan berjaya dihantar, sila bayar secepat mungkin',
    订单已超时: 'Pesanan telah tamat masa',
    订单已取消: 'pesanan telah dibatalkan',
    发表成功: 'Disiarkan dengan jayanya',
    发表留言: 'tinggalkan pesanan',
    发货地: 'tempat penghantaran',
    发货件数: 'Bilangan penghantaran',
    发货时间: 'masa penghantaran',
    发货状态: 'status penghantaran',
    发送成功: 'Berjaya Dihantar',
    发送验证码: 'Hantar kod pengesahan ',
    法律声明: 'Notis Undang-undang',
    返回: 'Kembali',
    返回个人中心: 'kembali ke pusat peribadi',
    返回首页: 'pulang ke rumah',
    分: 'titik',
    服务单号: 'Nombor pesanan perkhidmatan',
    付款时间: 'Masa pembayaran',
    个人信息: 'maklumat peribadi',
    个人中心: 'pusat peribadi',
    恭喜您邮箱地址验证成功: 'tahniah! Pengesahan alamat e-mel berjaya',
    共x件商品已选择x: 'Sebanyak {num} item, {num2} item telah dipilih',
    购买信息: 'maklumat pembelian',
    国家区域: 'Negara/Wilayah',
    还没有收货地址哦: 'Belum ada alamat penghantaran',
    含发货邮费: 'termasuk kos penghantaran',
    欢迎登录: 'Selamat datang ke Log Masuk',
    回复: 'balas',
    活动优惠: 'Promosi',
    货币: 'mata wang',
    继续逛逛: 'terus membeli-belah',
    继续支付: 'terus bayar',
    价格从低到高: 'item tamat tempoh',
    价格从高到低: 'harga dari rendah ke tinggi',
    价格高低: 'paras harga',
    交易单号: 'nombor transaksi',
    交易异常: 'pengecualian transaksi',
    看不清换一张: 'Tidak dapat melihat dengan jelas',
    客: 'C',
    客服电话: 'talian hotline pengguna',
    客服邮箱: 'E-mel Perkhidmatan Pelanggan',
    客户: 'pelanggan',
    库存: 'dalam stok',
    立即登录: 'log masuk segera',
    立即购买: 'Beli sekarang',
    立即留言: 'Tinggalkan mesej sekarang',
    立即提交: 'hantar sekarang',
    立即修改: 'Ubah suai sekarang',
    立即支付: 'bayar segera',
    立即注册: 'Daftarlah sekarang',
    联系电话: 'nombor telefon',
    联系方式: 'Maklumat perhubungan',
    联系客服: 'Hubungi Khidmat Pelanggan',
    联系人: 'orang yang boleh dihubungi',
    联系信息: 'Maklumat perhubungan',
    两次输入密码不一致: 'Kata laluan yang dimasukkan dua kali tidak konsisten',
    留言成功: 'Mesej berjaya',
    留言管理: 'pengurusan mesej',
    留言回复: 'tinggalkan maklum balas',
    留言回复长度xxx个字符: 'Balasan mesej, panjang 2-100 aksara',
    留言内容: 'Kandungan mesej',
    没收到货或与店铺协商同意不用退货只退款: 'Tidak menerima barang, atau berunding dengan kedai untuk bersetuju untuk tidak memulangkan barang tetapi untuk memulangkan',
    没有找到搜索的内容: 'carian tidak ditemui',
    密码: 'Kata laluan',
    密码必须输入: 'Kata laluan mesti dimasukkan',
    密码登录: 'log masuk kata laluan',
    密码最少8位最多16位: 'Kata laluan minimum 8 bit dan maksimum 16 bit',
    免费注册: 'Pendaftaran percuma',
    描述: 'huraikan',
    描述文字: 'teks penerangan',
    秒: 'Kedua',
    秒后重新发送: 'Hantar semula selepas {seconds} saat ',
    名: 'nama',
    默认地址: 'alamat lalai',
    男: 'lelaki',
    请您在x内完成支付否则订单会被自动取消: 'Sila lengkapkan pembayaran dalam <span style="color: red;">{num}</span>, jika tidak pesanan akan dibatalkan secara automatik',
    昵称: 'nama panggilan',
    昵称不能为空: 'Nama panggilan harus diisi',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消: 'Anda sedang membayar di luar talian, sila tunggu dengan sabar untuk kedai mengesahkan penerimaan! Jika kedai tidak mengesahkan penerimaan selepas <span style="color: red;">{num}</span>, pesanan akan dibatalkan secara automatik!',
    您的购物车还没有商品哟: 'Tiada produk dalam troli beli-belah anda~',
    您可以参考如下线下付款方式: 'Anda boleh merujuk kepada kaedah pembayaran luar talian berikut',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料: 'Anda boleh meninggalkan mesej kepada kedai di sini, untuk melindungi hak dan kepentingan anda, sila muat naik maklumat mesej sedetail mungkin',
    您未支付请重新支付: 'Anda belum bayar, sila bayar lagi',
    女: 'perempuan',
    评论成功: 'Komen itu berjaya',
    切换地址: 'tukar alamat',
    请勾选商品: 'Sila tandakan item tersebut',
    请勾选信息: 'Jualan dari tinggi ke rendah',
    请描述问题: 'sila huraikan masalahnya',
    请设置位密码需包字母及数字: 'Sila tetapkan kata laluan 8-16 digit, huruf dan nombor diperlukan',
    请输入电话: 'Sila masukkan nombor telefon anda',
    请输入电话号码: 'Sila masukkan nombor telefon anda',
    请输入反馈内容: 'Sila masukkan kandungan maklum balas',
    请输入旧密码: 'Sila masukkan kata laluan lama',
    请输入联系人: 'Sila masukkan nama kenalan',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名: 'Sila masukkan kandungan mesej. Jika anda membayar menggunakan BCEL atau KBZPay, sila masukkan nombor kad bank dan nama akaun yang digunakan untuk menerima bayaran balik di sini.',
    请输入密码: 'Sila masukkan kata laluan',
    请输入名: 'sila masukkan nama pertama',
    请输入名字: 'Sila masukkan nama',
    请输入内容: 'Sila masukkan kandungan',
    请输入商品名称: 'Sila masukkan nama produk',
    请输入下方图形验证码: 'Sila masukkan kod pengesahan grafik di bawah',
    请输入详细地址: 'Sila masukkan alamat terperinci',
    请输入新密码: 'Sila masukkan kata laluan baharu',
    请输入姓: 'Sila masukkan nama keluarga',
    请输入验证码: 'sila masukkan kod pengesahan',
    请输入邮编: 'Sila masukkan poskod',
    请输入邮箱: 'Sila masukkan peti e-mel',
    请输入邮箱手机号: 'Sila masukkan e-mel/nombor mudah alih',
    请输入账号密码: 'Sila masukkan kata laluan akaun',
    请输入正确的密码格式必须包含两种字符: 'Sila masukkan format kata laluan yang betul mesti mengandungi dua aksara',
    请输入正确的手机号: 'Sila masukkan nombor telefon yang betul',
    请输入正确的邮箱: 'sila masukkan e-mel kosong anda',
    请输入正确验证码: 'Sila masukkan kod pengesahan yang betul',
    请填写留言内容: 'Sila isi kandungan mesej',
    请选择: 'sila pilih',
    请选择城市: 'Sila pilih bandar',
    请选择付款方式: 'Sila pilih kaedah pembayaran',
    请选择规格: 'Sila pilih spesifikasi',
    请选择国家: 'Sila pilih negara',
    请选择删除项需已读才能删除: 'Sila pilih item untuk dipadam, ia perlu dibaca untuk dipadam',
    请选择商品分类: 'Sila pilih kategori produk',
    请选择退货原因: 'Sila pilih sebab untuk kembali',
    请选择语言: 'Sila pilih bahasa',
    请选择展示货币: 'Sila pilih mata wang paparan',
    请再次输入密码: 'sila masukkan kata laluan sekali lagi',
    请在下次使用邮箱地址进行登录: 'Sila gunakan alamat e-mel anda untuk log masuk lain kali',
    取消: 'Batal',
    取消成功: 'Batalkan kejayaan',
    取消订单: 'membatalkan pesanan',
    取消订单成功: "Pesanan berjaya dibatalkan",
    取消售后: 'Pembatalan selepas jualan',
    取消原因: 'Sebab Pembatalan',
    去付款: 'bayar',
    全部: 'semua',
    全部产品: 'Semua produk',
    全部商品: 'semua produk',
    全部消息: 'semua berita',
    全选: 'pilih semua',
    缺少库存: 'kehabisan stok',
    确定: 'pasti',
    确定放弃支付吗: 'Adakah anda pasti mahu melepaskan pembayaran?',
    确定删除选中的商品吗: 'Adakah anda pasti mahu memadamkan item yang dipilih?',
    确定已输入接收退款的银行卡号和户名: 'Pastikan anda telah memasukkan nombor kad bank dan nama akaun untuk menerima bayaran balik',
    确认收货: 'mengesahkan penerimaan barang',
    确认收货成功: 'Sahkan penerimaan berjaya',
    确认提交: 'mengesahkan penyerahan',
    确认已付款: 'mengesahkan pembayaran',
    如果您当前的手机号已无法使用请联系客服: 'Jika nombor telefon bimbit semasa anda tidak lagi tersedia, sila hubungi perkhidmatan pelanggan',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持xxx格式图片不大于3M: 'Jika anda telah membayar mengikut kaedah pembayaran di atas, sila muat naik baucar yang berkaitan, seperti tangkapan skrin pemindahan, dsb., sokongan jpg, format png dan gambar tidak boleh lebih besar daripada 3M',
    扫码支付: 'pembayaran kod imbasan',
    删除: 'padam',
    删除成功: 'berjaya dipadamkan',
    删除地址: 'padam alamat',
    删除订单: 'memadamkan pesanan',
    删除订单成功: 'Pesanan berjaya dipadamkan',
    删除失效商品: 'Padamkan item yang tidak sah',
    删除选中商品: 'memadam item yang dipilih',
    商品: 'komoditi',
    商品单价: 'Harga komoditi',
    商品分类: 'Kategori',
    商品合计: 'Jumlah produk',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询: 'Alamat pemulangan produk akan dimaklumkan melalui mesej teks selepas kelulusan, atau bertanya dalam rekod permohonan',
    商品件数: 'barang',
    商品信息: 'informasi produk',
    商品总额: 'Jumlah barangan',
    商品总价: 'Amaun Keseluruhan',
    上传成功: 'berjaya dimuat naik',
    上传凭证: 'muat naik sijil',
    上传失败: 'muatnaik gagal',
    上传头像图片大小不能超过2MB: 'Saiz gambar avatar yang dimuat naik tidak boleh melebihi 2MB!',
    上传头像图片只能是JPG格式: 'Gambar avatar yang dimuat naik hanya boleh dalam format JPG',
    申请时间: 'masa permohonan',
    申请信息: 'Maklumat Permohonan',
    失效宝贝: 'Gagal sayang',
    时: 'jam',
    实付: 'Jumlah yang sebenarnya dibayar',
    实付金额: 'Jumlah yang sebenarnya dibayar',
    实付款: 'bayaran sebenar',
    是否删除: 'padam atau tidak',
    是否删除地址: 'Sama ada hendak memadam alamat',
    是否选择该地址: 'Adakah anda memilih alamat ini',
    收货地址: 'Alamat penghantaran',
    收货信息: 'maklumat penghantaran',
    收件地址: 'Alamat penerima',
    收件人: 'penerima',
    手机号: 'Nombor telefon bimbit',
    手机号码: 'nombor telefon',
    手机验证: 'Pengesahan telefon',
    首页: 'muka depan',
    售后单号: 'Nombor pesanan selepas jualan',
    售后方式: 'Kaedah selepas jualan',
    售后服务: 'Perkhidmatan selepas jualan',
    售后商品: 'Barangan selepas pasaran',
    售后申请: 'Permohonan selepas jualan',
    售后申请提交成功: 'Permohonan selepas jualan berjaya dihantar',
    售后申请提交失败: 'Penyerahan permohonan selepas jualan gagal',
    售后完成: 'Siap selepas jualan',
    售后信息: 'Maklumat selepas jualan',
    售后专员可能与您电话沟通请保持手机畅通: 'Pakar selepas jualan boleh berkomunikasi dengan anda melalui telefon, sila pastikan telefon anda tidak disekat',
    数量: 'kuantiti',
    搜索: 'cari',
    提交: 'menyerahkan',
    提交订单: 'Hantar pesanan',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通: 'Selepas menyerahkan pesanan perkhidmatan, pakar selepas jualan boleh berkomunikasi dengan anda melalui telefon, sila pastikan telefon anda tidak disekat',
    提交申请: 'mengemukakan permohonan',
    提示: 'petunjuk',
    天: 'langit',
    添加超时: 'tambah tamat masa',
    添加成功: 'Berjaya ditambahkan',
    添加地址: 'tambah alamat',
    添加购物车: 'tambah ke troli',
    添加失败库存不足: 'Tambah gagal Stok tidak mencukupi',
    x条回复: 'x balas',
    通过电子邮件向我发送新闻和优惠: 'E-mel saya berita dan tawaran',
    推荐产品: 'Produk yang Disyorkan',
    退出登录: 'log keluar',
    退货处理成功后退款金额将原路返回到您的支持账户中: 'Selepas pemulangan berjaya diproses, jumlah bayaran balik akan dikembalikan ke akaun sokongan anda dengan cara yang sama',
    退货金额: 'jumlah pulangan',
    退货类型: 'jenis pulangan',
    退货理由: 'Sebab balik',
    退货申请中: 'Pemulangan permohonan sedang dijalankan',
    退货数量: 'Kuantiti Pulangan',
    退货原因: 'alasan untuk kembali',
    退款成功: 'Bayaran balik berjaya',
    退款金额: 'jumlah bayaran balik',
    退款失败: 'Bayaran balik gagal',
    退款说明: 'Arahan bayaran balik',
    退款完成: 'bayaran balik selesai',
    退款原因: 'sebab balik',
    退款中: 'Membayar balik',
    完成: 'Selesai',
    完整地址: 'alamat lengkap',
    忘记密码: 'lupa kata laluan',
    微信: 'WeChat',
    微信支付: 'Pembayaran WeChat',
    为了帮助您更好的解决问题请上传图片: 'Untuk membantu anda menyelesaikan masalah dengan lebih baik, sila muat naik gambar',
    为确认身份我们需验证您的安全手机: 'Untuk mengesahkan identiti, kami perlu mengesahkan telefon selamat anda',
    未读消息: 'mesej yang belum dibaca',
    未发货: 'tidak dihantar',
    未选择地址或没有地址: 'Tiada alamat dipilih atau tiada alamat',
    温馨提示: 'Petua yang baik',
    文件过大: 'fail terlalu besar',
    问题描述: 'masalah Penerangan',
    我的订单: 'Arahan saya',
    我是新用户: 'Saya pengguna baru',
    我要留言: 'Saya ingin meninggalkan mesej',
    我要退货退款: 'Saya mahu kembali untuk bayaran balik',
    我要退款: 'Saya mahukan bayaran balik',
    下单结算: 'Penyelesaian pesanan',
    下单时间: 'masa pesanan',
    下一步: 'Langkah seterusnya',
    线下支付: 'Pembayaran luar talian',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货: 'Pembayaran luar talian tidak menyokong perkhidmatan selepas jualan dalam talian, sila hubungi peniaga jika anda memerlukan perkhidmatan selepas jualan. Jika anda telah membayar, sila tunggu dengan sabar untuk pedagang mengesahkan pembayaran dan menghantarnya!',
    详细地址: 'Alamat',
    消息通知: 'pemberitahuan',
    销量从低到高: 'harga dari tinggi ke rendah',
    销量从高到低: 'Jualan dari rendah ke tinggi',
    销量高低: 'Tahap jualan',
    小计: 'jumlah kecil',
    新密码不能和旧密码相同: 'Kata laluan baharu tidak boleh sama dengan kata laluan lama',
    新增地址: 'tambah alamat',
    性别: 'jantina',
    姓: 'nama keluarga',
    姓名: 'Nama',
    修改成功: 'Berjaya diubah suai',
    修改密码: 'tukar kata laluan',
    修改邮箱: 'Ubah suai e-mel',
    需勾选协议: 'Perlu menyemak perjanjian',
    选择成功: 'pilih kejayaan',
    选择地址: 'pilih alamat',
    选择以下支付方式付款: 'Pilih kaedah pembayaran berikut untuk membayar',
    验证码: 'kod pengesahan',
    验证码2: 'kod pengesahan',
    验证码登录: 'Log masuk kod pengesahan',
    验证邮箱: 'Mengesahkan E-mel',
    已读消息: 'membaca mesej',
    已发货: 'Dihantar',
    已关闭: 'tertutup',
    已经有一个账户: 'Sudah mempunyai akaun？',
    已取消: 'Dibatalkan',
    已取消删除: 'Tidak dipadamkan',
    已失效商品: 'item tamat tempoh',
    已收到货需要退还收到的货物: 'Menerima barang dan perlu memulangkan barang yang diterima',
    已完成: 'selesai',
    已下架: 'Telah dikeluarkan',
    已有账号: 'Sudah mempunyai akaun',
    已阅读并同意以下协议: 'Telah membaca dan bersetuju dengan perjanjian berikut',
    隐私政策: 'Dasar Privasi',
    应付总额: 'Jumlah yang perlu dibayar',
    用户服务协议: 'Perjanjian Perkhidmatan Pengguna',
    用户服务协议及法律声明: 'Perjanjian Perkhidmatan Pengguna dan Pernyataan Undang-undang',
    优惠卷抵扣: 'Potongan kupon',
    邮箱: 'Peti E-Mel',
    邮箱必须输入: 'Peti e-mel mesti dimasukkan',
    邮箱地址: 'alamat emel',
    邮箱修改: 'Pengubahsuaian e-mel',
    邮箱验证: 'Pengesahan email',
    邮政编码: 'poskod',
    语言: 'bahasa',
    运费: 'penghantaraan',
    再次输入新密码: 'Sila masukkan kata laluan baharu sekali lagi',
    在线支付: 'Pembayaran Dalam Talian',
    暂无地址信息: 'Tiada maklumat alamat',
    暂无订单信息: 'Tiada maklumat pesanan',
    暂无留言信息: 'Tiada mesej',
    暂无商品: 'Tiada produk',
    暂无售后信息: 'Tiada maklumat selepas jualan',
    暂无消息通知: 'Tiada pemberitahuan berita',
    长度在1到20个字符: 'Panjang 1 hingga 20 aksara',
    长度在1到8个字符: 'Panjang 1 hingga 8 aksara',
    长度在2到100个字符: 'Panjang 2 hingga 100 aksara',
    长度在2到10个字符: 'Panjang 2 hingga 10 aksara',
    长度在8到16个字符: 'Panjang 8 hingga 16 aksara',
    找回密码: 'dapatkan kata laluan',
    支付宝: 'Alipay',
    支付成功: 'pembayaran berjaya',
    支付方式: 'kaedah pembayaran',
    支付凭证: 'baucar bayaran',
    支付失败: 'pembayaran gagal',
    直接登录: 'Log Masuk Terus',
    重新发送验证码: 'Hantar semula Kod',
    重新申请: 'Mohon semula',
    注册: 'Pendaftaran',
    注册成功: 'pendaftaran berjaya',
    综合排序: 'jenis komprehensif',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件: 'Sehingga 5 gambar boleh dimuat naik, dan saiz setiap gambar tidak melebihi 5M, menyokong fail format bmp, gif, jpg, png, jpeg',
    复制成功: 'salin dengan jayanya',
    最多上传五张: 'Sehingga 5 muat naik',
    最多上传一张: 'Sehingga 1 muat naik',
    分享店铺: 'Kongsi kedai',
    分享至: 'Kongsi ke',
    海报: 'poster',
    分享: 'Kongsi',
    绑定: 'mengikat',
    保存: 'jimat',
    绑定邮箱: 'Ikat peti mel',
    推荐店铺给你: 'Pusat beli-belah yang disyorkan kepada anda',
    分享店铺至: '分享店铺至',

    至: 'kepada',
    币种: 'mata wang',
    变动理由: 'Sebab perubahan',
    不记得: 'tidak ingat',
    充值: 'Caj semula',
    打款凭证: 'Sijil pembayaran',
    打款说明: 'Arahan pembayaran',
    打款信息: 'Maklumat pembayaran',
    打款状态: 'Status pembayaran',
    待打款: 'pembayaran belum selesai',
    待审核: 'untuk disemak',
    到账方式: 'Kaedah deposit',
    到账银行卡号: 'kad bank',
    冻结中: 'Pembekuan',
    国家: 'bangsa',
    获取验证码: 'Dapatkan kod pengesahan',
    记得: 'ingat',
    记录时间: 'masa rakaman',
    拒绝打款: 'Enggan bayar',
    开户人: 'Pemegang akaun',
    开户支行: 'Cawangan pembukaan akaun',
    可提现: 'Pengeluaran tersedia',
    可提现金额: 'Jumlah yang boleh dikeluarkan',
    立即提现: 'Tarik diri serta-merta',
    流水号: 'nombor siri',
    没有适合此币种的银行卡请先添加: 'Tiada kad bank yang sesuai untuk mata wang ini, sila tambah dahulu',
    没有收款码请先添加: 'Tiada kod pembayaran, sila tambah dahulu',
    每天最多提现x次单次最高提现金额x: 'Bilangan pengeluaran maksimum setiap hari, jumlah pengeluaran maksimum setiap hari*',
    您是否记得账号当前使用的支付密码: 'Adakah anda masih ingat kata laluan pembayaran yang digunakan oleh akaun anda pada masa ini?',
    您未设置支付密码请先设置: 'Anda belum menetapkan kata laluan pembayaran, sila tetapkannya dahulu',
    钱包余额: 'Baki Wallet',
    请输入开户人: 'Sila masukkan pemegang akaun',
    请输入开户支行: 'Sila masukkan cawangan pembukaan akaun',
    请输入收款名称: 'Sila masukkan nama pembayaran',
    请输入提现金额: 'Sila masukkan jumlah pengeluaran',
    请输入新支付密码6位数字: 'Sila masukkan kata laluan pembayaran baharu, 6 digit',
    请输入新支付密码: 'Sila masukkan kata laluan pembayaran baharu',
    请输入银行卡号: 'Sila masukkan nombor kad bank',
    请输入原支付密码: 'Sila masukkan kata laluan pembayaran asal',
    请输入支付密码6位数字: 'Sila masukkan kata laluan pembayaran, 6 digit',
    请输入支付密码: 'Sila masukkan kata laluan pembayaran',
    请选择币种: 'Sila pilih mata wang',
    请选择大洲: 'Sila pilih benua',
    请选择结束时间: 'Sila pilih tarikh tamat',
    请选择开始时间: 'Sila pilih tarikh mula',
    请选择收款码类型: 'Sila pilih jenis kod pembayaran',
    请选择银行: 'Sila pilih nama bank',
    请选择银行名称: 'Sila masukkan kata laluan pembayaran baharu sekali lagi',
    请再次输入新支付密码: 'Sila masukkan kata laluan pembayaran baharu sekali lagi',
    请再次输入支付密码: 'Sila masukkan kata laluan pembayaran sekali lagi',
    去设置: 'Pergi ke tetapan',
    全部提现: 'Tarik balik semua',
    确定删除收款码吗: 'Adakah anda pasti anda sedang memadamkan kod pembayaran',
    确定删除银行卡吗: 'Adakah anda pasti memadamkan kad bank',
    确定提现: 'Sahkan pengeluaran',
    确认打款: 'Sahkan pembayaran',
    确认新密码: 'Sila masukkan kata laluan pembayaran baharu sekali lagi',
    确认支付密码: 'Sahkan kata laluan pembayaran',
    上传的收款二维码需要清晰无遮挡: 'Kod QR pembayaran yang dimuat naik perlu jelas dan tidak terhalang',
    审核备注: 'Nota Semakan',
    审核不通过: 'Gagal lulus semakan',
    审核结果: 'Keputusan audit',
    审核通过: 'peperiksaan lulus',
    审核信息: 'Maklumat audit',
    时间: 'masa',
    实际到账金额: 'Jumlah sebenar diterima',
    收款方式: 'Kaedah pembayaran',
    收款管理: 'Pengurusan Resit',
    收款码: 'kod resit',
    收款码管理: 'Pengurusan kod resit',
    收款码类型: 'jenis kod resit',
    收款名称: 'Nama resit',
    收款账号: 'Akaun resit',
    手续费: 'Yuran pengeluaran',
    提现: 'menarik diri',
    提现币种: 'Mata wang pengeluaran',
    提现成功: 'Pengeluaran yang berjaya',
    提现记录: 'Rekod pengeluaran',
    提现金额: 'Jumlah Pengeluaran',
    提现失败: 'Pengeluaran gagal',
    提现时间: 'Masa pengeluaran',
    提现手续费: 'Yuran pengeluaran',
    提现详情: 'Butiran pengeluaran',
    提现中: 'Mengeluarkan',
    提现状态: 'Status pengeluaran',
    添加收款码: 'Tambah kod pembayaran',
    添加银行卡: 'Tambah kad bank',
    退款: 'Bayar Balik',
    忘记支付密码: 'Terlupa kata laluan pembayaran anda?',
    尾号: 'Nombor ekor',
    我的钱包: 'dompet saya',
    我的余额: 'Baki saya',
    详情: 'butiran',
    消费: 'penggunaan',
    新支付密码: 'Kata laluan pembayaran baharu',
    修改支付密码: 'Tukar kata laluan pembayaran',
    选择收款方式: 'Pilih kaedah pembayaran',
    银行卡: 'kad bank',
    银行卡管理: 'Pengurusan Kad Bank',
    银行卡号: 'Nombor kad bank',
    银行名称: 'Nama bank',
    余额记录: 'Rekod baki',
    余额数据: 'Data baki',
    原支付密码: 'Kata laluan pembayaran asal',
    暂无数据: 'Tiada data',
    支持币种: 'Mata wang sokongan',
    支付密码: 'Kata laluan pembayaran',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件: 'Hanya satu gambar boleh dimuat naik dan saiz gambar tidak melebihi 5M dan jpg, png dan jpeg fail format disokong',
    账户余额: 'Baki akaun',
    钱包服务: 'Perkhidmatan dompet',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'Jumlah pengeluaran maksimum setiap hari ialah {num} kali, jumlah pengeluaran maksimum setiap masa ialah {icon}{money}, dan jumlah pengeluaran minimum ialah {icon}{money2}',
    确认: 'mengesahkan',
    输入金额超过可提现余额: 'Jumlah input melebihi baki yang boleh dikeluarkan',
    日期选择: 'pemilihan tarikh',
    备注: 'Teguran',
    当前没有余额记录: 'Pada masa ini tiada rekod baki!',
    当前没有提现记录: 'Pada masa ini tiada rekod pengeluaran!',
    还没有绑定银行卡: 'Belum ada kad bank yang diikat!',
    还没有添加收款码: 'Tiada kod pembayaran telah ditambahkan lagi!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'Petua: Hanya satu gambar boleh dimuat naik, saiz gambar tidak boleh melebihi 5M dan fail format jpg, png dan jpeg disokong. Kod QR pembayaran yang dimuat naik mestilah jelas dan tidak terhalang.',
    请上传收款码: 'Sila muat naik kod pembayaran',
    请输入收款码名称: 'Sila masukkan nama kod pembayaran',
    打款成功: 'Pembayaran berjaya',
    开始日期: 'tarikh mula',
    结束日期: 'tarikh tamat',
    请再次输入新密码: 'Sila masukkan kata laluan baharu sekali lagi',
    修改: 'Semak semula',
    请选择收款方式: 'Sila pilih kaedah pembayaran',
    收款类型: 'Jenis pembayaran',
    编辑银行卡: 'Edit kad bank',
    编辑收款码: 'Edit kod pembayaran',
    收款码名称: 'Nama kod pembayaran',
    订单ID: 'nombor pesanan',
    加载中: 'Memuatkan',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'Maaf, kedai yang anda lawati mungkin telah dipadamkan, namanya telah ditukar atau mungkin tidak wujud...',
    请选择支付货币: 'Sila pilih mata wang pembayaran',
    请选择收货地址: 'Tiada alamat ditambahkan, sila tambahkannya dahulu',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit} {money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "berat pertama{num}kg {currencyUnit} {money}，sambungan{num2}kg {currencyUnit} {money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "item pertama{num3}keping{currencyUnit} {money3}，sambungan{num4}keping{currencyUnit} {money4}",
    支持配送区域: '（Menyokong kawasan penghantaran：{diqu}。）',
    配送费: '配送费',
    暂无配送区域: 'Tiada kawasan penghantaran lagi',
    该商品在当前地区暂不支持配送: 'Produk ini tidak menyokong penghantaran di rantau semasa pada masa ini',
    打款中: 'Pembayaran sedang dijalankan',
    银行编码: 'Kod bank',
    卢比支付: 'pembayaran IDR',
    比索支付: 'pembayaran PHP',
    泰铢支付: 'pembayaran THB',
    越南盾支付: 'pembayaran VND',
    请输入大于1的正整数: 'Sila masukkan integer positif lebih daripada 1',
    设置密码: 'tetapkan kata laluan',
    登录密码: 'kata laluan log masuk',
    设置成功: 'Persediaan berjaya',
    当前仅支持xxx的金额: 'Pada masa ini hanya menyokong jumlah daripada {currency} {min}-{currency} {max}',
    查看运费: 'Lihat kos penghantaran',
    运费方式: 'Cara penghantaran',
    价格: 'harga',
    请选择配送方式: 'Sila pilih kaedah penghantaran',
    配送方式: 'Kaedah Penyampaian',
    配送费说明: 'Penerangan yuran penghantaran',
    我明白了: 'saya faham',
    "若多个商品选用同一个运费模板，则运费是按同一个运费方式的规则进行计算。": 'Jika berbilang produk menggunakan templat tambang yang sama, tambang akan dikira mengikut peraturan kaedah tambang yang sama.',
    '若多个商品不是同一个运费模板，则运费是多个模板的价格之和。': 'Jika berbilang produk tidak mempunyai templat yuran penghantaran yang sama, yuran penghantaran ialah jumlah harga bagi berbilang templat.',
    '快捷登录，登录即表示同意协议': 'Dengan log masuk anda bersetuju menerima perjanjian itu',
    促销广告: 'Pengiklanan promosi',
    最近新品: 'Produk baru',
    更多: 'Lagi',
    全部分类: 'Semua Kategori',
    推荐商品: 'Produk yang Disyorkan',


    //     1.6
    我的优惠券:'Kupon diskaun saya',
    历史记录:'Rekod sejarah',
    已使用:'terpakai',
    已过期:'tamat tempoh',
    优惠券:'kupon',
    领取:'Menerima',
    已领取:'Menerima',
    有效期:'Tempoh sah：',
    活动优惠详情:'Butiran diskaun acara',
    可用商品:'Item yang ada',
    单独购买:'Harga asal',
    发起拼团:'Harga kumpulan',
    拼团价:'Harga kumpulan',
    可参与的拼团:'Pembelian berkumpulan tersedia',
    '再邀X人即可拼单成功':'Kongsikan dengan {num} orang lagi untuk tempahan yang berjaya>',
    拼单详情:'Butiran pembelian kumpulan',
    待分享:'menunggu untuk berkongsi',
    '若拼团失败，货款将原路退回':'Jika pembelian kumpulan gagal, bayaran akan dikembalikan ke laluan asal.',
    促销价:'Harga jualan',
    距离结束时间:'End in',
    满XX减XX:"Belanja {currencyUnit}{priceStart} dapat {currencyUnit}{reducePrice}{couponType==2?'%':''} potongan harga",
    满XX减X:'Belanja {currencyUnit}{priceStart} dapat {reducePrice}% potongan harga',
    分享好友X人成团立省X:'Belian berkumpulan untuk <span style="color: #8D4BBB;">{num}</span>orang, jimat<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'Kongsi dengan kawan-kawan',
    '这些人正在拼，立即参与 ':'Pembelian kumpulan sedang dijalankan, sertai sekarang',
    满x可用:'Tersedia untuk pesanan melebihi {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'Pembelian kumpulan sedang dijalankan, sertai sekarang ',
    限领x张:'{receiveCount} kupon setiap had pengguna',
    满XX送赠品:'Hadiah percuma untuk tempahan melebihi {currencyUnit}{priceStart}',
    去使用:'untuk menggunakan',
    销量:'Jualan',
    赠品:'Memberi',
    限时优惠:'Tawaran Masa Terhad',
    距结束:'End in',
    拼团好物:'Beli Berkumpulan',
    当前优惠券可以购买以下商品:'Kupon semasa boleh membeli produk berikut:',
    以下商品:'Produk berikut',
    满XX减XX可用:"Belanja {currencyUnit}{priceStart} dapat {currencyUnit}{reducePrice} potongan harga",
    满XX减X可用:'Belanja {currencyUnit}{priceStart} dapat {reducePrice}% potongan harga',
    再买x可用:'Masih perlu membeli {currencyUnit}{difPrice}',
    合计:'Jumlah',
    优惠:'Diskaun',
    去购物车:'Troli beli-belah',
    拼团:'Beli Berkumpulan',
    满XX可用:'Tersedia untuk pesanan melebihi {currencyUnit}{priceStart}',
    满减满赠:'Diskaun penuh/hadiah penuh',
    成团发货:'Tunggu penghantaran',
    还差xx人成团:'Masih memerlukan <span style="color: #FF4545;">{num}</span> orang untuk membuat tempahan dengan jayanya',
    拼团结束:'Pembelian kumpulan tamat',
    拼团成功:'Pembelian kumpulan berjaya',
    有成员未付款请等待付款成团:'Ada yang belum bayar, sila tunggu pembayaran',
    我正在参与的拼团:'Mengambil bahagian dalam pembelian berkumpulan',
    满减:'Diskaun penuh',
    满赠:'Hadiah penuh',
    规格选择:'Pilih spesifikasi',
    凑单:'Buat pesanan bersama',
    切换其他优惠:'Pilih tawaran lain',
    超过最大购买数:'Melebihi bilangan pembelian maksimum',
    商品限购x件:'Hadkan {num} keping',
    剩余:'剩余',
    结束:'结束',
    参与拼团:'Sertai',
    拼单失败:'Pembelian kumpulan gagal',
    可用优惠券:'Kupon yang tersedia',
    免费:'Percuma',
    已赠完:'kehabisan stok',
    已领完:"Digunakan",
    请勾选删除商品: 'Sila semak untuk memadam produk',
    活动未开始:'Acara belum bermula',

    已满X可减X:'Dapatkan potongan {currencyUnit}{reducePrice} apabila anda membelanjakan {currencyUnit}{difPrice} atau lebih',
    已满X可减XX:'Dapatkan potongan {reducePrice}% apabila anda membelanjakan {currencyUnit}{difPrice} atau lebih',

    再买X可减XX:'Beli {currencyUnit}{difPrice} lagi dan dapatkan potongan {reducePrice}%',
    再买X送赠品:'Beli lagi {currencyUnit}{difPrice} dan dapat hadiah percuma',
    已满X送赠品:'Hadiah telah diberikan',
    限X件:'Had pembelian {num} keping',
    您还没有优惠券:'Anda tidak mempunyai kupon',
    x件: '{num} keping',

    退货积分:'Pulangan mata',
    积分:'integral',
    用户注册:'Pendaftaran pengguna',
    手动增加:'Meningkatkan secara manual',
    积分兑换退款:'Pemulangan mata',
    下单购物:'Buat pesanan untuk membeli-belah',
    过期积分:'Mata tamat tempoh',
    兑换商品:'Tebus barang',
    手动减少:'Pengurangan manual',
    订单退款:'Bayaran balik pesanan',
    积分明细:'Butiran mata',
    已兑:'Sudah ditebus',
    积分商城:'Points Mall',
    规则:'peraturan',
    可用积分:'Mata Tersedia',
    冻结积分:'titik beku',
    我的兑换:'pertukaran saya',
    售后:'Selepas jualan',
    分销中心:'Pusat pengagihan',
    立即兑换:'Tebus sekarang',
    已兑完:'Sudah ditebus',
    "积分不足，无法兑换":'Mata yang tidak mencukupi tidak boleh ditebus',
    兑换数量:'Kuantiti tukaran',
'限兑*件':'Item penebusan terhad{num}',
    积分抵扣:'Potongan mata',
'共*分':'Jumlah {num} mata',
    全部订单:'Semua pesanan',

兑换须知:'Arahan penebusan',
    已达兑换上限:'Had penebusan telah dicapai',
    我的积分:'Markah saya',
    积分规则:'Peraturan mata',
    兑换时间:'Masa penebusan',
    我的佣金:'komisen saya',
    佣金数据:'Data komisen',
    分销申请协议:'Perjanjian Permohonan Pengedaran',
    申请成为分销员:'Mohon untuk menjadi pengedar',
    "已申请，请等待审核":'Sudah memohon, sila tunggu untuk semakan',
    "审核拒绝，继续申请":'Ditolak melalui semakan, teruskan memohon',
    可提佣金:'Komisen pengeluaran',
    冻结佣金:'Membekukan komisen',
    分销订单:'Perintah pengedaran',
    佣金明细:'Butiran komisen',
    我的下级:'orang bawahan saya',
    推广商品:'Mempromosikan produk',
    推广店铺:'Promosikan kedai',
    暂无记录:'Tiada rekod',
    一级佣金收入:'Pendapatan komisen peringkat pertama',
    一级佣金退款:'Bayaran balik komisen peringkat pertama',
    二级佣金收入:'Pendapatan komisen sekunder',
    二级佣金退款:'Bayaran balik komisen peringkat kedua',
    下单用户:'Pengguna meletakkan pesanan',
    订单总价:'Jumlah harga pesanan',
    分销利润层级:'Tahap pengedaran',
    结算状态:'status penyelesaian',
    佣金:'komisen',
    一级:'Tahap 1',
    二级:'Tahap 2',
    已结算:'Selesai',
    请输入用户昵称或手机号:'Sila masukkan nama panggilan pengguna atau nombor telefon mudah alih',
    二级团队:'Pasukan peringkat kedua',
    分享链接:'kongsi pautan',
    可推广至:'Boleh dipanjangkan ke',
    分享最高可赚x:'Dapatkan sehingga {icon}{num} dengan berkongsi',
    分享推广:'Kongsi dan promosikan',
    用户昵称或手机号:'Nama panggilan pengguna atau nombor telefon bimbit',
    复制:'salinan',
    商品名称:'Nama Produk',
    最高可得:'Tertinggi tersedia',
    可获得积分:'mata yang diperolehi',
    总获佣金:'Jumlah komisen yang diperolehi',
    请选择银行卡: 'Sila pilih kad bank',
    请选择收款码: 'Sila pilih kod pembayaran',
    不能超过最小提现额度:'Jumlah pengeluaran minimum tidak boleh melebihi!',

    普通订单:'Pesanan biasa',
    售货机:'mesin layan diri',
    订单类型:'Jenis pesanan',
    请选择类型:'Sila pilih jenis',
    '房间号/桌号':'Nombor bilik/nombor meja'
}
// 马来西亚
