import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'
import { el } from './locale'

Vue.use(VueI18n)

// 拿到用户的语言选择，如果没有默认中文。
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh_CN',
  messages: {
    zh_CN: {
      ...zhLocale
    },
    en_US: {
      ...enLocale
    },
    en_ID: {
      ...enLocale
    },
    ...el
  },
})

export default i18n
