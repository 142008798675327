import Vue from 'vue'
import App from './App.vue'
import { detectZoom } from '@/utils/index';
import router from './router/index'
import ElementUI from 'element-ui';
import Ei18n from './utils/langs/i18n' // element多语言配置
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import store from './store/index'
import sessvalue from "@/utils/sessvalue";
import cos from 'cos-js-sdk-v5';
import VueI18n from 'vue-i18n';
import zhCN from './lang/zh-CN.js'
import enUS from './lang/en-US.js'
import loLO from './lang/lo-Lo.js'
import viVN from './lang/vi-VN.js'
import thTH from './lang/th-TH.js'
import myMM from './lang/my-MM.js'
import msMY from './lang/ms-MY.js'
import kmKH from './lang/km-KH.js'
import amET from './lang/am-ET.js'
import EN from './lang/YN.js'

Vue.use(VueI18n)
const messages = {
  'zh_CN': zhCN,
  'en_US': enUS,
  'lo_LA': loLO,
  'vi_VN':viVN,
  'th_TH':thTH,
  'my_MM':myMM,
  'ms_MY':msMY,
  'km_KH':kmKH,
  'am_ET':amET,
  'en_ID':EN
}

const i18n = new VueI18n({
  locale: navigator.language || 'zh_CN',
  fallbackLocale: 'zh_CN',
  messages,
  interpolate: true,
  missing: (key) => {

    return key;
  },
  // 分隔符自定义
  // 自定义分隔符为 $$
  delimiters: ['{{', '}}'], // 检查分隔符选项
  silentTranslationWarn: true,
  formatFallbackMessages: true,
})

Vue.prototype.$changeLanguage = function (lang) {
  this.$i18n.locale = lang // 更新当前的语言环境为 lang
}

// 将i18n注入到全局变量中
window.$i18n = i18n

// 解决pc端屏幕显示缩放比例125%,150%对页面布局的影响
const m = detectZoom();
document.body.style.zoom = 100 / Number(m);

// 初始化 COS 对象
const cosClient = new cos({
  SecretId: 'AKIDNrBQVULeppjU4bz96rPktx08D6B2tcAE',
  SecretKey: 'b675Vt6bzIZkvvhDfzEPPK8qMjx3vY4O'
});

// 英文方法，隔开
Vue.prototype.$formatNumber=function(num) {
  // 如果 num 为 null 或 undefined，则直接返回空字符串或其他默认值
  if (num == null) {
    return "";
    }
  // 如果传入的已经是带逗号的格式，则直接返回原数据
  if (typeof num === 'string' && num.includes(',')) {
    return num;
  }

  let strNum = typeof (num) == 'number' ? num?.toFixed(2).toString() :  num; // 将数字转换为字符串类型
  let arrNum = strNum.split("."); // 分离整数和小数部分
  let intPart = arrNum[0]; // 整数部分
  let decimalPart = arrNum.length > 1 ? "." + arrNum[1] : ""; // 小数部分（如果有）
  let regExp = /(\d)(?=(\d{3})+$)/g; // 正则表达式，匹配每三位数字
  intPart = intPart.replace(regExp, "$1,"); // 将整数部分按照正则表达式替换成“逗号+数字”的形式
  // 去掉小数部分末尾的多余的 0
  if (decimalPart !== "") {
    decimalPart = decimalPart.replace(/0+$/, "");
    if (decimalPart === ".") {
      decimalPart = "";
    }
  }
  return intPart + decimalPart;
}

Vue.prototype.$getImageUrl = function(url, width, height, format) {
  if (!cosClient) {
    console.error('COS 对象未初始化');
    return url;
  }
  // 获取图片后缀名
  // const extName = url.substr(url.lastIndexOf('.') + 1);

  // 根据图片格式参数设置压缩格式
  let compressFormat = '';
  if (format === 'webp') {
    compressFormat = '/format/webp';
  } else if (format === 'jpeg') {
    compressFormat = '/format/jpg';
  }

  // 根据图片尺寸参数设置缩放大小
  let resizeParam = '';
  if (width && height) {
    resizeParam = `?imageMogr2/thumbnail/${width}x${height}!/quality/95`;
  } else if (width) {
    resizeParam = `?imageMogr2/thumbnail/${width}!/quality/95`;
  } else if (height) {
    resizeParam = `?imageMogr2/thumbnail/${height}!/quality/95`;
  }

  // 拼接处理后的图片 URL
  const processedUrl = `${url}${resizeParam}${compressFormat}`;
  // 返回经过处理的图片 URL
  return processedUrl;
};

Vue.prototype.$getTimeZoneOffset=function (date, inx = null, format = 'yyyy-MM-dd hh:mm:ss') {
  // 此处的i为8是北京东八区的时间，如果是西n区，则此处为：-n
  const i = -(new Date().getTimezoneOffset() / 60)
  // 东八区，中国时间，不做处理
  if (!date || i === 8) {
    return date
  }
  date = new Date(date)

  if (typeof date === 'string') {
    date = date.replace(/-/g, '/')
  }
  // 北京时间戳
  const bjDate =date.getTime();
  // 当地时间戳
  const ddDate = (i - 8) * 60 * 60 * 1000 + bjDate;
  date = new Date(ddDate);

  // let d = new Date(date)
  // //得到1970年一月一日到现在的秒数
  // let len = d.getTime()
  // //本地时间与GMT时间的时间偏移差
  // let offset = d.getTimezoneOffset() * 60000
  // //得到现在的格林尼治时间
  // let utcTime = len + offset
  // date = new Date(utcTime + 3600000 * (inx ? inx : i))

  if (!format) {
    return date
  }

  let o = {
    'M+': date.getMonth() + 1, // month
    'd+': date.getDate(), // day
    'h+': date.getHours(), // hour
    'm+': date.getMinutes(), // minute
    's+': date.getSeconds(), // second
    'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
    'S': date.getMilliseconds() // millisecond
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}
// function convertTimeZone(dateTimeString, fromTimeZone, toTimeZone) {
//   // 解析原始日期时间字符串为UTC时间
//   const date = new Date(dateTimeString);
//
//   // 创建Intl.DateTimeFormat对象用于格式化日期
//   const optionsFrom = {
//     timeZone: fromTimeZone,
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//     hour12: false
//   };
//   const optionsTo = {
//     timeZone: toTimeZone,
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//     hour12: false
//   };
//
//   // 使用fromTimeZone格式化日期，但结果仍然是UTC时间
//   const formattedFrom = new Intl.DateTimeFormat(undefined, optionsFrom).format(date);
//
//   // 解析格式化后的UTC日期字符串为Date对象
//   const utcDate = new Date(formattedFrom);
//
//   // 使用toTimeZone格式化UTC日期对象，得到目标时区的日期时间
//   const formattedTo = new Intl.DateTimeFormat(undefined, optionsTo).format(utcDate);
//
//   return formattedTo;
// }


// 引入并启用 vConsole 库
// const script = document.createElement('script')
// script.type = 'text/javascript'
// script.async = true
// script.src = 'https://cdn.bootcss.com/vConsole/3.9.5/vconsole.min.js'
// script.onload = function () {
//   const vConsole = new window.VConsole();
//   vConsole.setSwitchPosition(80, 80)
// }
// document.body.appendChild(script)

Vue.use(sessvalue);

Vue.use(Vant);
Vue.use(ElementUI,{
  size: localStorage.getItem('lang') || 'small', // set element-ui default size
  i18n: (key, value) => Ei18n.t(key, value)
});
Vue.config.productionTip = false
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
