export const el = {
  lo_LA: {
    el: {
      colorpicker: {
        confirm: 'ໝັ້ນໝາຍ',
        clear: 'ລົບລ້າງ'
      },
      datepicker: {
        now: 'ດຽວນີ້',
        today: 'ມື້​ນີ້',
        cancel: 'ຍົກເລີກ',
        clear: 'ລົບລ້າງ',
        confirm: 'ໝັ້ນໝາຍ',
        selectDate: 'ເລືອກວັນທີ',
        selectTime: 'ໄລ​ຍະ​ການ​ຄັດ​ເລືອກ​',
        startDate: 'ວັນທີເລີ່ມຕົ້ນ',
        startTime: 'ເວລາເລີ່ມຕົ້ນ',
        endDate: 'ວັນທີເລີກ',
        endTime: 'ເວລາສິ້ນສຸດ',
        prevYear: 'ປີກ່ອນ',
        nextYear: 'ປີຮື',
        prevMonth: 'ເດືອນແລ້ວ',
        nextMonth: 'ເດື່ອນ​ຫນ້າ',
        year: 'ປັກວາດ',
        month1: 'ມັງກອນ',
        month2: 'ເດືອນກຸມພາ',
        month3: 'ມີນາ',
        month4: 'ເດືອນເມສາ',
        month5: 'ພຶດສະພາ',
        month6: 'ເດືອນມິຖຸນາ',
        month7: 'ກໍລະກົດ',
        month8: 'ສິງຫາ',
        month9: 'ກັນຍາ',
        month10: 'ຕຸລາ',
        month11: 'ເດືອນພະຈິກ',
        month12: 'ເດືອນທັນວາ',
        // week: '周次',
        weeks: {
          sun: 'ວອທ',
          mon: 'ວຈ',
          tue: 'ວອຄ',
          wed: 'ວພ',
          thu: 'ວພຫ',
          fri: 'ວສ',
          sat: 'ວສ'
        },
        months: {
          jan: 'ມັງກອນ',
          feb: 'ເດືອນກຸມພາ',
          mar: 'ມີນາ',
          apr: 'ເດືອນເມສາ',
          may: 'ພຶດສະພາ',
          jun: 'ເດືອນມິຖຸນາ',
          jul: 'ກໍລະກົດ',
          aug: 'ສິງຫາ',
          sep: 'ກັນຍາ',
          oct:  'ຕຸລາ',
          nov:  'ເດືອນພະຈິກ',
          dec:  'ເດືອນທັນວາ',
        }
      },
      select: {
        loading: 'ກຳລັງດາວໂຫຼດ',
        noMatch: 'ບໍ່ມີຂໍ້ມູນທີ່ກົງກັນ',
        noData: 'ບໍ່​ມີ​ຂໍ້​ມູນ',
        placeholder: 'ກະລຸນາເລືອກ'
      },
      cascader: {
        noMatch: 'ບໍ່ມີຂໍ້ມູນທີ່ກົງກັນ',
        loading: 'ກຳລັງດາວໂຫຼດ',
        placeholder: 'ກະລຸນາເລືອກ',
        noData: 'ບໍ່​ມີ​ຂໍ້​ມູນ'
      },
      pagination: {
        goto: 'ໄປ​ຫາ',
        pagesize: '/ໜ້າ',
        total: 'ລວມ {total} ລາຍການ',
        pageClassifier: 'ໜ້າ'
      },
      messagebox: {
        title: 'ສະເໜີແນະ',
        confirm: 'ໝັ້ນໝາຍ',
        cancel: 'ຍົກເລີກ',
        error: 'ຂໍ້ມູນທີ່ປ້ອນເຂົ້າບໍ່ຖືກຕ້ອງ!'
      },
      upload: {
        deleteTip: 'ກົດ delete ເພື່ອລຶບ',
        delete: 'ລຶບ',
        preview: 'ເບິ່ງຮູບ',
        continue: 'ສືບຕໍ່ການອັບໂຫຼດ'
      },
      table: {
        emptyText: 'ບໍ່​ມີ​ຂໍ້​ມູນ',
        confirmFilter: 'ຮ່ອນ',
        resetFilter: 'ຣີເຊັດ',
        clearFilter: 'ທັງໝົດ',
        sumText: 'ທັງໝົດ'
      },
      tree: {
        emptyText: 'ບໍ່​ມີ​ຂໍ້​ມູນ'
      },
      transfer: {
        noMatch: 'ບໍ່ມີຂໍ້ມູນທີ່ກົງກັນ',
        noData: 'ບໍ່​ມີ​ຂໍ້​ມູນ',
        titles: ['ລາຍການ1', 'ລາຍການ2'],
        filterPlaceholder: 'ໃສ່ເນື້ອຫາຄົ້ນຫາ',
        noCheckedFormat: 'ລວມ {total} ລາຍການ',
        hasCheckedFormat: 'ເລືອກ {checked}/{total} ລາຍການ'
      },
      image: {
        error: 'ໂຫຼດບໍ່ສຳເລັດ'
      },
      pageHeader: {
        title: 'ກັບຄືນ'
      },
      popconfirm: {
        confirmButtonText: 'ໝັ້ນໝາຍ',
        cancelButtonText: 'ຍົກເລີກ'
      },
      empty: {
        description: 'ບໍ່​ມີ​ຂໍ້​ມູນ'
      }
    }
  },
  km_KH: {
    el: {
      colorpicker: {
        confirm: 'ប្រាកដ',
        clear: 'ច្បាស់'
      },
      datepicker: {
        now: 'នៅពេលនេះ',
        today: 'ថ្ងៃនេះ',
        cancel: 'បោះបង់',
        clear: 'ច្បាស់',
        confirm: 'ប្រាកដ',
        selectDate: 'ជ្រើសរើសកាលបរិច្ឆេទ',
        selectTime: 'រយៈពេលជ្រើសរើស',
        startDate: 'ថ្ងៃ​ចាប់ផ្តើម',
        startTime: 'ពេលវេលាចាប់ផ្តើម',
        endDate: 'កាលបរិច្ឆេទបញ្ចប់',
        endTime: 'ពេលវេលាបញ្ចប់',
        prevYear: 'ឆ្នាំមុន',
        nextYear: 'ឆ្នាំក្រោយ',
        prevMonth: 'ខែមុន',
        nextMonth: 'ខែក្រោយ',
        year: 'ឆ្នាំ',
        month1: 'មករា',
        month2: 'ខែកុម្ភៈ',
        month3: 'ខែមីនា',
        month4: 'មេសា',
        month5: 'ឧសភា',
        month6: 'ខែមិថុនា',
        month7: 'ខែកក្កដា',
        month8: 'សីហា',
        month9: 'ខែកញ្ញា',
        month10: 'តុលា',
        month11: 'ខែវិច្ឆិកា',
        month12: 'ខែធ្នូ',
        // week: '周次',
        weeks: {
          sun: 'ថ្ងៃ',
          mon: 'មួយ។',
          tue: 'ពីរ',
          wed: 'បី',
          thu: 'បួន',
          fri: 'ប្រាំ',
          sat: 'ប្រាំមួយ។'
        },
        months: {
          jan: 'មករា',
          feb: 'ខែកុម្ភៈ',
          mar: 'ខែមីនា',
          apr: 'មេសា',
          may: 'ឧសភា',
          jun: 'ខែមិថុនា',
          jul: 'ខែកក្កដា',
          aug: 'សីហា',
          sep: 'ខែកញ្ញា',
          oct:  'តុលា',
          nov:  'ខែវិច្ឆិកា',
          dec:  'ខែធ្នូ',
        }
      },
      select: {
        loading: 'កំពុងផ្ទុក',
        noMatch: 'មិនមានទិន្នន័យដែលត្រូវគ្នាទេ។',
        noData: 'គ្មាន​ទិន្នន័យ',
        placeholder: 'សូម​ជ្រើសរើស'
      },
      cascader: {
        noMatch: 'មិនមានទិន្នន័យដែលត្រូវគ្នាទេ។',
        loading: 'កំពុងផ្ទុក',
        placeholder: 'សូម​ជ្រើសរើស',
        noData: 'គ្មាន​ទិន្នន័យ'
      },
      pagination: {
        goto: 'ទៅ',
        pagesize: 'អត្ថបទ/ទំព័រ',
        total: 'ធាតុសរុប {total}',
        pageClassifier: 'ទំព័រ'
      },
      messagebox: {
        title: 'ព័ត៌មានជំនួយ',
        confirm: 'ប្រាកដ',
        cancel: 'បោះបង់',
        error: 'ទិន្នន័យដែលបានបញ្ចូលមិនត្រឹមត្រូវ!'
      },
      upload: {
        deleteTip: 'ចុចលុបដើម្បីលុប',
        delete: 'លុប',
        preview: 'មើលរូបភាព',
        continue: 'បន្ត​បង្ហោះ'
      },
      table: {
        emptyText: 'គ្មាន​ទិន្នន័យ',
        confirmFilter: 'តម្រង',
        resetFilter: 'កំណត់ឡើងវិញ',
        clearFilter: 'ទាំងអស់។',
        sumText: 'សរុប'
      },
      tree: {
        emptyText: 'គ្មាន​ទិន្នន័យ'
      },
      transfer: {
        noMatch: 'មិនមានទិន្នន័យដែលត្រូវគ្នា។',
        noData: 'គ្មាន​ទិន្នន័យ',
        titles: ['បញ្ជី ១', 'បញ្ជី ២'],
        filterPlaceholder: 'បញ្ចូលមាតិកាស្វែងរក',
        noCheckedFormat: 'ធាតុសរុប {total}',
        hasCheckedFormat: 'បានជ្រើសរើសធាតុ {checked}/{total}'
      },
      image: {
        error: 'បរាជ័យក្នុងការផ្ទុក'
      },
      pageHeader: {
        title: 'ត្រឡប់មកវិញ'
      },
      popconfirm: {
        confirmButtonText: 'ប្រាកដ',
        cancelButtonText: 'បោះបង់'
      },
      empty: {
        description: 'គ្មាន​ទិន្នន័យ'
      }
    }
  },
  ms_MY: {
    el: {
      colorpicker: {
        confirm: 'pasti',
        clear: 'Jelas'
      },
      datepicker: {
        now: 'pada saat ini',
        today: 'hari ini',
        cancel: 'Batal',
        clear: 'Jelas',
        confirm: 'pasti',
        selectDate: 'Pilih tarikh',
        selectTime: 'tempoh pemilihan',
        startDate: 'tarikh mula',
        startTime: 'Masa mula',
        endDate: 'tarikh tamat',
        endTime: 'Masa tamat',
        prevYear: 'tahun sebelumnya',
        nextYear: 'tahun hadapan',
        prevMonth: 'bulan lepas',
        nextMonth: 'bulan depan',
        year: 'tahun',
        month1: 'Januari',
        month2: 'Februari',
        month3: 'Mac',
        month4: 'April',
        month5: 'Mungkin',
        month6: 'Jun',
        month7: 'Julai',
        month8: 'Ogos',
        month9: 'September',
        month10: 'Oktober',
        month11: 'November',
        month12: 'Disember',
        // week: '周次',
        weeks: {
          sun: 'hari',
          mon: 'satu',
          tue: 'dua',
          wed: 'tiga',
          thu: 'Empat',
          fri: 'lima',
          sat: 'enam'
        },
        months: {
          jan: 'Januari',
          feb: 'Februari',
          mar: 'Mac',
          apr: 'April',
          may: 'Mungkin',
          jun: 'Jun',
          jul: 'Julai',
          aug: 'Ogos',
          sep: 'September',
          oct:  'Oktober',
          nov:  'November',
          dec:  'Disember',
        }
      },
      select: {
        loading: 'Memuatkan',
        noMatch: 'tiada data yang sepadan',
        noData: 'tiada data',
        placeholder: 'sila pilih'
      },
      cascader: {
        noMatch: 'tiada data yang sepadan',
        loading: 'Memuatkan',
        placeholder: 'sila pilih',
        noData: 'Tiada data'
      },
      pagination: {
        goto: 'pergi ke',
        pagesize: 'Artikel/Halaman',
        total: 'Jumlah {total} item',
        pageClassifier: 'Halaman'
      },
      messagebox: {
        title: 'petunjuk',
        confirm: 'pasti',
        cancel: 'Batal',
        error: 'Data yang dimasukkan adalah haram!'
      },
      upload: {
        deleteTip: 'Tekan padam untuk memadam',
        delete: 'padam',
        preview: 'lihat gambar',
        continue: 'teruskan memuat naik'
      },
      table: {
        emptyText: 'Tiada data',
        confirmFilter: 'penapis',
        resetFilter: 'set semula',
        clearFilter: 'semua',
        sumText: 'jumlah'
      },
      tree: {
        emptyText: 'Tiada data'
      },
      transfer: {
        noMatch: 'tiada data yang sepadan',
        noData: 'tiada data',
        titles: ['senarai1', 'senarai2'],
        filterPlaceholder: 'Masukkan kandungan carian',
        noCheckedFormat: '{total} item secara keseluruhan',
        hasCheckedFormat: '{checked}/{total} item dipilih'
      },
      image: {
        error: 'Gagal memuatkan'
      },
      pageHeader: {
        title: 'kembali'
      },
      popconfirm: {
        confirmButtonText: 'pasti',
        cancelButtonText: 'Batal'
      },
      empty: {
        description: 'Tiada data'
      }
    }
  },
  th_TH: {
    el: {
      colorpicker: {
        confirm: 'แน่นอน',
        clear: 'ว่างเปล่า'
      },
      datepicker: {
        now: 'ในขณะนี้',
        today: 'วันนี้',
        cancel: 'ยกเลิก',
        clear: 'ว่างเปล่า',
        confirm: 'แน่นอน',
        selectDate: 'เลือกวันที่',
        selectTime: 'ระยะเวลาการคัดเลือก',
        startDate: 'วันที่เริ่มต้น',
        startTime: 'เวลาเริ่มต้น',
        endDate: 'วันที่สิ้นสุด',
        endTime: 'เวลาสิ้นสุด',
        prevYear: 'ปีก่อน',
        nextYear: 'ปีหน้า',
        prevMonth: 'เดือนที่แล้ว',
        nextMonth: 'เดือนหน้า',
        year: 'ปี',
        month1: 'มกราคม',
        month2: 'กุมภาพันธ์',
        month3: 'มีนาคม',
        month4: 'เมษายน',
        month5: 'อาจ',
        month6: 'มิถุนายน',
        month7: 'กรกฎาคม',
        month8: 'สิงหาคม',
        month9: 'กันยายน',
        month10: 'ตุลาคม',
        month11: 'พฤศจิกายน',
        month12: 'ธันวาคม',
        // week: '周次',
        weeks: {
          sun: 'วัน',
          mon: 'หนึ่ง',
          tue: 'สอง',
          wed: 'สาม',
          thu: 'สี่',
          fri: 'ห้า',
          sat: 'หก'
        },
        months: {
          jan: 'มกราคม',
          feb: 'กุมภาพันธ์',
          mar: 'มีนาคม',
          apr: 'เมษายน',
          may: 'อาจ',
          jun: 'มิถุนายน',
          jul: 'กรกฎาคม',
          aug: 'สิงหาคม',
          sep: 'กันยายน',
          oct:  'ตุลาคม',
          nov:  'พฤศจิกายน',
          dec:  'ธันวาคม',
        }
      },
      select: {
        loading: 'กำลังโหลด',
        noMatch: 'ไม่มีข้อมูลที่ตรงกัน',
        noData: 'ไม่มีข้อมูล',
        placeholder: 'กรุณาเลือก'
      },
      cascader: {
        noMatch: 'ไม่มีข้อมูลที่ตรงกัน',
        loading: 'กำลังโหลด',
        placeholder: 'กรุณาเลือก',
        noData: 'ไม่มีข้อมูล'
      },
      pagination: {
        goto: 'ไปที่',
        pagesize: 'บทความ/หน้า',
        total: 'รวมทั้งหมด {total} รายการ',
        pageClassifier: 'หน้าหนังสือ'
      },
      messagebox: {
        title: 'คำใบ้',
        confirm: 'แน่นอน',
        cancel: 'ยกเลิก',
        error: 'ข้อมูลที่ป้อนผิดกฎหมาย!'
      },
      upload: {
        deleteTip: 'กดปุ่ม Delete เพื่อลบ',
        delete: 'ลบ',
        preview: 'ดูภาพ',
        continue: 'ดำเนินการอัปโหลดต่อไป'
      },
      table: {
        emptyText: 'ไม่มีข้อมูล',
        confirmFilter: 'กรอง',
        resetFilter: 'รีเซ็ต',
        clearFilter: 'ทั้งหมด',
        sumText: 'ทั้งหมด'
      },
      tree: {
        emptyText: 'ไม่มีข้อมูล'
      },
      transfer: {
        noMatch: 'ไม่มีข้อมูลที่ตรงกัน',
        noData: 'ไม่มีข้อมูล',
        titles: ['รายการ1', 'รายการ2'],
        filterPlaceholder: 'ป้อนเนื้อหาการค้นหา',
        noCheckedFormat: 'รวมทั้งหมด {total} รายการ',
        hasCheckedFormat: 'เลือกแล้ว {checked}/{total} รายการ'
      },
      image: {
        error: 'โหลดไม่สำเร็จ'
      },
      pageHeader: {
        title: 'กลับ'
      },
      popconfirm: {
        confirmButtonText: 'แน่นอน',
        cancelButtonText: 'ยกเลิก'
      },
      empty: {
        description: 'ไม่มีข้อมูล'
      }
    }
  },
  vi_VN: {
    el: {
      colorpicker: {
        confirm: 'Chắc chắn',
        clear: 'trống'
      },
      datepicker: {
        now: 'ngay bây giờ',
        today: 'Hôm nay',
        cancel: 'Hủy bỏ',
        clear: 'trống',
        confirm: 'Chắc chắn',
        selectDate: 'Chọn ngày',
        selectTime: 'kỳ tuyển chọn',
        startDate: 'ngày bắt đầu',
        startTime: 'Thời gian bắt đầu',
        endDate: 'ngày cuối',
        endTime: 'Thời gian kết thúc',
        prevYear: 'Năm trước',
        nextYear: 'năm sau',
        prevMonth: 'tháng trước',
        nextMonth: 'tháng tiếp theo',
        year: 'Năm',
        month1: 'Tháng Giêng ',
        month2: 'Tháng 2',
        month3: 'Bước đều',
        month4: 'Tháng tư',
        month5: 'Có thể',
        month6: 'Tháng sáu',
        month7: 'Tháng bảy',
        month8: 'Tháng tám',
        month9: 'Tháng 9',
        month10: 'Tháng Mười',
        month11: 'Tháng mười một',
        month12: 'Tháng 12',
        // week: '周次',
        weeks: {
          sun: 'ngày',
          mon: 'một',
          tue: 'hai',
          wed: 'ba',
          thu: 'bốn',
          fri: 'năm',
          sat: 'sáu'
        },
        months: {
          jan: 'Tháng Một',
          feb: 'Tháng hai',
          mar: 'Bước đều',
          apr: 'Tháng tư',
          may: 'Có thể',
          jun: 'Tháng sáu',
          jul: 'Tháng bảy',
          aug: 'Tháng tám',
          sep: 'Tháng 9',
          oct:  'Tháng Mười',
          nov:  'Tháng mười một',
          dec:  'Tháng 12',
        }
      },
      select: {
        loading: 'Đang tải',
        noMatch: 'Không có dữ liệu phù hợp',
        noData: 'không có dữ liệu',
        placeholder: 'xin vui lòng chọn'
      },
      cascader: {
        noMatch: 'Không có dữ liệu phù hợp',
        loading: 'Đang tải',
        placeholder: 'xin vui lòng chọn',
        noData: 'Không có dữ liệu'
      },
      pagination: {
        goto: 'Đi đến',
        pagesize: 'Bài viết/Trang',
        total: 'Tổng cộng có {total} mục',
        pageClassifier: 'Trang'
      },
      messagebox: {
        title: 'gợi ý',
        confirm: 'Chắc chắn',
        cancel: 'Hủy bỏ',
        error: 'Dữ liệu đã nhập là bất hợp pháp!'
      },
      upload: {
        deleteTip: 'Nhấn phím xóa để xóa',
        delete: 'xóa bỏ',
        preview: 'xem hình ảnh',
        continue: 'Tiếp tục tải lên'
      },
      table: {
        emptyText: 'Không có dữ liệu',
        confirmFilter: 'lọc',
        resetFilter: 'cài lại',
        clearFilter: 'tất cả',
        sumText: 'tổng cộng'
      },
      tree: {
        emptyText: 'Không có dữ liệu'
      },
      transfer: {
        noMatch: 'không có dữ liệu phù hợp',
        noData: 'không có dữ liệu  ',
        titles: ['danh sách 1', 'danh sách 2'],
        filterPlaceholder: 'Nhập nội dung tìm kiếm',
        noCheckedFormat: 'Tổng số {total} mặt hàng',
        hasCheckedFormat: 'Đã chọn {checked}/{total} mục'
      },
      image: {
        error: 'Không tải được'
      },
      pageHeader: {
        title: 'trở lại'
      },
      popconfirm: {
        confirmButtonText: 'Chắc chắn',
        cancelButtonText: 'Hủy bỏ'
      },
      empty: {
        description: 'Không có dữ liệu'
      }
    }
  },
  my_MM: {
    el: {
      colorpicker: {
        confirm: 'သေချာပါတယ်။',
        clear: 'ရှင်းလင်းသော'
      },
      datepicker: {
        now: 'ဒီအချိန်မှာ',
        today: 'ဒီနေ့',
        cancel: 'မလုပ်တော့',
        clear: 'ရှင်းလင်းသော',
        confirm: 'သေချာပါတယ်။',
        selectDate: 'ရက်စွဲကို ရွေးပါ။',
        selectTime: 'ရွေးချယ်ရေးကာလ',
        startDate: 'စတင်သည့်ရက်စွဲ  ',
        startTime: 'စတင်ချိန်',
        endDate: 'ကုန်ဆုံးရက်',
        endTime: 'ကုန်ဆုံးချိန်',
        prevYear: 'အရင်နှစ်',
        nextYear: 'နောက်နှစ်',
        prevMonth: 'ပြီးခဲ့သည့်လ',
        nextMonth: 'နောက်လ',
        year: 'တစ်နှစ်',
        month1: 'ဇန်နဝါရီလ',
        month2: 'ဖေဖော်ဝါရီလ',
        month3: 'မတ်လ',
        month4: 'ဧပြီလ',
        month5: 'မေ',
        month6: 'ဇွန်လ',
        month7: 'ဇူလိုင်လ',
        month8: 'သြဂုတ်လ',
        month9: 'စက်တင်ဘာ',
        month10: 'အောက်တိုဘာ',
        month11: 'နိုဝင်ဘာလ',
        month12: 'ဒီဇင်ဘာ',
        // week: '周次',
        weeks: {
          sun: 'နေ့',
          mon: 'တစ်ခု',
          tue: 'နှစ်ခု',
          wed: 'သုံး',
          thu: 'လေး',
          fri: 'ငါး',
          sat: 'ခြောက်'
        },
        months: {
          jan: 'ဇန်နဝါရီလ',
          feb: 'ဖေဖော်ဝါရီလ',
          mar: 'မတ်လ',
          apr: 'ဧပြီလ',
          may: 'မေ',
          jun: 'ဇွန်လ',
          jul: 'ဇူလိုင်လ',
          aug: 'သြဂုတ်လ',
          sep: 'စက်တင်ဘာ',
          oct:  'အောက်တိုဘာ',
          nov:  'နိုဝင်ဘာလ',
          dec:  'ဒီဇင်ဘာ',
        }
      },
      select: {
        loading: 'ဒိန်း',
        noMatch: 'ကိုက်ညီသောဒေတာမရှိပါ။',
        noData: 'ဒေတာမရှိပါ။',
        placeholder: 'ကျေးဇူးပြု။ ရွေးချယ်ပါ'
      },
      cascader: {
        noMatch: 'ကိုက်ညီသောဒေတာမရှိပါ။',
        loading: 'ဒိန်း',
        placeholder: 'ကျေးဇူးပြု။ ရွေးချယ်ပါ',
        noData: 'ဒေတာမရှိပါ။'
      },
      pagination: {
        goto: 'သွားပါ။',
        pagesize: 'ဆောင်းပါး/စာမျက်နှာ',
        total: 'စုစုပေါင်း ပစ္စည်း {total} ခု',
        pageClassifier: 'စာမျက်နှာ'
      },
      messagebox: {
        title: 'သဲလွန်စ',
        confirm: 'သေချာပါတယ်။',
        cancel: 'မလုပ်တော့',
        error: 'ထည့်သွင်းထားသောဒေတာသည် တရားမဝင်ပါ။'
      },
      upload: {
        deleteTip: 'ဖျက်ရန် ဖျက်ရန် ခလုတ်ကို နှိပ်ပါ။',
        delete: 'ဖျက်ပါ။',
        preview: 'ပုံကိုကြည့်ပါ။',
        continue: 'ဆက်တင်ရန်'
      },
      table: {
        emptyText: 'ဒေတာမရှိပါ။',
        confirmFilter: 'ဇကာ',
        resetFilter: 'ပြန်လည်သတ်မှတ်ပါ။',
        clearFilter: 'အားလုံး',
        sumText: 'စုစုပေါင်း'
      },
      tree: {
        emptyText: 'ဒေတာမရှိပါ။'
      },
      transfer: {
        noMatch: 'ကိုက်ညီသောဒေတာမရှိပါ။',
        noData: 'ဒေတာမရှိပါ။',
        titles: ['စာရင်း1', 'စာရင်း2'],
        filterPlaceholder: 'ရှာဖွေမှုအကြောင်းအရာကို ထည့်သွင်းပါ။',
        noCheckedFormat: 'စုစုပေါင်း ပစ္စည်း {total} ခု',
        hasCheckedFormat: '{checked}/{total} ဖိုင်များကို ရွေးထားသည်။'
      },
      image: {
        error: 'တင်ရန် မအောင်မြင်ပါ။'
      },
      pageHeader: {
        title: 'ပြန်လာ'
      },
      popconfirm: {
        confirmButtonText: 'သေချာပါတယ်။',
        cancelButtonText: 'မလုပ်တော့'
      },
      empty: {
        description: 'ဒေတာမရှိပါ။'
      }
    }
  },
  am_ET: {
    el: {
      colorpicker: {
        confirm: 'በእርግጠኝነት',
        clear: 'ባዶ'
      },
      datepicker: {
        now: 'በወቅቱ',
        today: 'ዛሬ',
        cancel: 'ሰርዝ',
        clear: 'ግልጽ',
        confirm: 'በእርግጠኝነት',
        selectDate: 'ቀን ይምረጡ',
        selectTime: 'የምርጫ ጊዜ',
        startDate: 'የመጀመሪያ ቀን',
        startTime: 'የመነሻ ጊዜ',
        endDate: 'የመጨረሻ ቀን',
        endTime: 'የመጨረሻ ጊዜ',
        prevYear: 'ያለፈው ዓመት',
        nextYear: 'የሚመጣው አመት',
        prevMonth: 'ባለፈው ወር',
        nextMonth: 'በሚቀጥለው ወር',
        year: 'አመት',
        month1: 'ጥር',
        month2: 'የካቲት',
        month3: 'መጋቢት',
        month4: 'ሚያዚያ',
        month5: 'ግንቦት',
        month6: 'ሰኔ',
        month7: 'ሀምሌ',
        month8: 'ነሐሴ',
        month9: 'መስከረም',
        month10: 'ጥቅምት',
        month11: 'ህዳር',
        month12: 'ታህሳስ',
        // week: '周次',
        weeks: {
          sun: 'ቀን',
          mon: 'አንድ',
          tue: 'ሁለት',
          wed: 'ሶስት',
          thu: 'አራት',
          fri: 'አምስት',
          sat: 'ስድስት'
        },
        months: {
          jan: 'ጥር',
          feb: 'የካቲት',
          mar: 'መጋቢት',
          apr: 'ሚያዚያ',
          may: 'ግንቦት',
          jun: 'ሰኔ',
          jul: 'ሀምሌ',
          aug: 'ነሐሴ',
          sep: 'መስከረም',
          oct:  'ጥቅምት',
          nov:  'ህዳር',
          dec:  'ታህሳስ',
        }
      },
      select: {
        loading: 'በመጫን ላይ',
        noMatch: 'ምንም ተዛማጅ ውሂብ የለም።',
        noData: 'ምንም ውሂብ የለም',
        placeholder: 'እባክዎን ይምረጡ'
      },
      cascader: {
        noMatch: 'ምንም ተዛማጅ ውሂብ የለም',
        loading: 'በመጫን ላይ',
        placeholder: 'እባክዎን ይምረጡ',
        noData: 'ምንም ውሂብ የለም'
      },
      pagination: {
        goto: 'መሄድ',
        pagesize: 'ጽሑፍ/ገጽ',
        total: 'ጠቅላላ {total} ንጥሎች',
        pageClassifier: 'ገጽ'
      },
      messagebox: {
        title: 'ፍንጭ',
        confirm: 'በእርግጠኝነት',
        cancel: 'ሰርዝ',
        error: 'የገባው መረጃ ህገወጥ ነው!'
      },
      upload: {
        deleteTip: 'ለማጥፋት ሰርዝን ይጫኑ',
        delete: 'ሰርዝ',
        preview: 'ምስል ይመልከቱ',
        continue: 'መስቀሉን ይቀጥሉ'
      },
      table: {
        emptyText: 'ምንም ውሂብ የለም',
        confirmFilter: 'ማጣሪያ',
        resetFilter: 'ዳግም አስጀምር',
        clearFilter: 'ሁሉም',
        sumText: 'ጠቅላላ'
      },
      tree: {
        emptyText: 'ምንም ውሂብ የለም'
      },
      transfer: {
        noMatch: 'ምንም ተዛማጅ ውሂብ የለም',
        noData: 'ምንም ውሂብ የለም',
        titles: ['ዝርዝር1', 'ዝርዝር2'],
        filterPlaceholder: 'የፍለጋ ይዘት አስገባ',
        noCheckedFormat: 'በአጠቃላይ {total} ንጥሎች',
        hasCheckedFormat: '{checked}/{total} ንጥሎች ተመርጠዋል'
      },
      image: {
        error: 'መጫን አልተሳካም።'
      },
      pageHeader: {
        title: 'መመለስ'
      },
      popconfirm: {
        confirmButtonText: 'በእርግጠኝነት',
        cancelButtonText: 'ሰርዝ'
      },
      empty: {
        description: 'ምንም ውሂብ የለም'
      }
    }
  }
}

export const eiditor = {
  lo_LA: {
    editor: {
      more: 'ເພີ່ມເຕີມ',
      justify: 'ຈັດຮຽງ',
      indent: 'ຫຍໍ້ຫນ້າ',
      image: 'ຮູບພາບ',
      video: 'ວີດີໂອ'
    },
    common: {
      ok: 'ຢືນຢັ້ນ',
      delete: 'ລົບ',
      enter: 'ຍ້ອນກັບ'
    },
    blockQuote: {
      title: 'ອ້າງອີງ'
    },
    codeBlock: {
      title: 'ລະຫັດບ໊ອກ'
    },
    color: {
      color: 'ສີຂໍ້ຄວາມ',
      bgColor: 'ສີພື້ນຫລັງ',
      default: 'ສີເລີ່ມຕົ້ນ',
      clear: 'ລ້າງສີພື້ນຫລັງ'
    },
    divider: {
      title: 'ເສັ້ນແຍກ'
    },
    emotion: {
      title: 'ສະແດງອອກ'
    },
    fontSize: {
      title: 'ຂະຫນາດຕົວອັກສອນ',
      default: 'ຂະຫນາດຕົວອັກສອນເລີ່ມຕົ້ນ'
    },
    fontFamily: {
      title: 'ຕົວອັກສອນ',
      default: 'ຕົວອັກສອນເລີ່ມຕົ້ນ'
    },
    fullScreen: {
      title: 'ເຕັມຈໍ'
    },
    header: {
      title: 'ຫົວຂໍ້',
      text: 'ຂໍ້ຄວາມ'
    },
    image: {
      netImage: 'ຮູບພາບຈາກອີນເຕີເນັດ',
      delete: 'ລົບຮູບພາບ',
      edit: 'ແກ້ໄຂຮູບພາບ',
      viewLink: 'ກວດລີ້ງ',
      src: 'ຮູບທີຢູ່',
      desc: 'ຄຳອະທິບາຍຮູບພາບ',
      link: 'ລີ້ງຮູບພາບ'
    },
    indent: {
      decrease: 'ຫລຸດຫຍໍ້ຫນ້າ',
      increase: 'ເພີ່ມຫຍໍ້ຫນ້າ'
    },
    justify: {
      left: 'ການຈັດຮຽງຂວາ',
      right: 'ການຈັດຮຽງຊ້າຍ',
      center: 'ການຈັດຮຽງກາງ',
      justify: 'ການຈັດຮຽງທັງສອງເບື້ອງ'
    },
    lineHeight: {
      title: 'ຄວາມສູງຂອງແຖວ',
      default: 'ຄວາມສູງຂອງແຖວເສັ້ນເລີ່ມຕົ້ນ'
    },
    link: {
      insert: 'ໃສ່ລີ້ງ',
      text: 'ຂໍ້ຄວາມລີ້ງ',
      url: 'ທີ່ຢູ່ລີ້ງ',
      unLink: 'ຍົກເລີກລີ້ງ',
      edit: 'ແປງລີ້ງ',
      view: 'ເບີ່ງລີ້ງ'
    },
    textStyle: {
      bold: 'ຕົວຫນາ',
      clear: 'ລົບຮູບແບບ',
      code: 'ລະຫັດໃນເສັ້ນ',
      italic: 'ຕົວອຽງ',
      sub: 'ຂີດກ້ອງ',
      sup: 'ຂີດເທີງ',
      through: 'ຍົກເລີກສາຍ',
      underline: 'ຂີດເສັ້ນໃຕ້'
    },
    undo: {
      undo: 'ຖອນຄືນ',
      redo: 'ເລີ່ມໃຫມ່'
    },
    todo: {
      todo: 'ລໍຖ້າປະຕິບັດ'
    },
    listModule: {
      unOrderedList: 'ບໍ່ມີການຈັດລຽງລຳດັບ',
      orderedList: 'ມີການຈັດລຽງລຳດັບ'
    },
    tableModule: {
      deleteCol: 'ລົບຖັນ',
      deleteRow: 'ລົບແຖວ',
      deleteTable: 'ລົບຮູບແບບ',
      widthAuto: 'ປັບຄວາມກວ້າງອັດຕະໂນມັດ',
      insertCol: 'ເພີ່ມຖັນ',
      insertRow: 'ເພີ່ມແຖວ',
      insertTable: 'ເພີ່ມຕະຕາລາງ',
      header: 'ຫົວຕະຕາລາງ'
    },
    videoModule: {
      delete: 'ລົບວີດີໂອ',
      uploadVideo: 'ອັບໂຫລດວີດີໂອ',
      insertVideo: 'ເພີ່ມວີດີໂອ',
      videoSrc: 'ຕຳແຫນ່ງວີດີໂອ',
      videoSrcPlaceHolder: 'ໄຟລ໌ວິດີໂອ/ຫຼືພາກສ່ວນທີສາມ',
      videoPoster: 'ຫນ້າປົກວີດີໂອ',
      videoPosterPlaceHolder: 'ຮູບຫນ້າປົກ',
      ok: 'ຢຶນຢັ້ນ',
      editSize: 'ປັບຂະຫນາດ',
      width: 'ຄວາມກວາງ',
      height: 'ຄວາມສູງ'
    },
    uploadImgModule: {
      uploadImage: 'ອັບໂຫລດຮູບພາບ',
      uploadError: '{{fileName}} ອັບໂຫລດຜິດພາດ'
    },
    highLightModule: {
      selectLang: 'ເລືອກພາສາ'
    }
  },
  km_KH: {
    editor: {
      more: 'ច្រើនទៀត',
      justify: 'តម្រឹម',
      indent: 'ការចូលបន្ទាត់',
      image: 'រូបភាព',
      video: 'វីដេអូ'
    },
    common: {
      ok: 'ប្រាកដ',
      delete: 'លុប',
      enter: 'រទេះរុញត្រឡប់មកវិញ'
    },
    blockQuote: {
      title: 'សម្រង់'
    },
    codeBlock: {
      title: 'ប្លុកកូដ'
    },
    color: {
      color: 'ពណ៌អត្ថបទ',
      bgColor: 'ពណ៌ផ្ទៃខាងក្រោយ',
      default: 'ពណ៌លំនាំដើម',
      clear: 'ពណ៌ផ្ទៃខាងក្រោយច្បាស់'
    },
    divider: {
      title: 'បន្ទាត់បែងចែក'
    },
    emotion: {
      title: 'កន្សោម'
    },
    fontSize: {
      title: 'ទំហំ​អក្សរ',
      default: 'ទំហំពុម្ពអក្សរលំនាំដើម'
    },
    fontFamily: {
      title: 'ពុម្ពអក្សរ',
      default: 'ពុម្ពអក្សរលំនាំដើម'
    },
    fullScreen: {
      title: 'ពេញ​អេក្រង់'
    },
    header: {
      title: 'ចំណងជើង',
      text: 'អត្ថបទ'
    },
    image: {
      netImage: 'រូបភាពគេហទំព័រ',
      delete: 'លុបរូបភាព',
      edit: 'កែរូបភាព',
      viewLink: 'មើលតំណ',
      src: 'អាសយដ្ឋានផែនទី',
      desc: 'ការពិពណ៌នារូបភាព',
      link: 'តំណភ្ជាប់រូបភាព'
    },
    indent: {
      decrease: 'កាត់បន្ថយការចូលបន្ទាត់',
      increase: 'បង្កើនការចូលបន្ទាត់'
    },
    justify: {
      left: 'តម្រឹមឆ្វេង',
      right: 'តម្រឹមស្តាំ',
      center: 'ការតម្រឹមកណ្តាល',
      justify: 'បង្ហាញភាពត្រឹមត្រូវ'
    },
    lineHeight: {
      title: 'កម្ពស់​បន្ទាត់',
      default: 'កម្ពស់បន្ទាត់លំនាំដើម'
    },
    link: {
      insert: 'បញ្ចូលតំណ',
      text: 'ភ្ជាប់អត្ថបទ',
      url: 'អាសយដ្ឋានតំណ',
      unLink: 'ផ្ដាច់',
      edit: 'កែសម្រួលតំណ',
      view: 'មើលតំណ'
    },
    textStyle: {
      bold: 'ដិត',
      clear: 'ទម្រង់ច្បាស់លាស់',
      code: 'កូដក្នុងជួរ',
      italic: 'ទ្រេត',
      sub: 'អក្សរកាត់',
      sup: 'អក្សរធំ',
      through: 'កូដកម្ម',
      underline: 'គូសបន្ទាត់ពីក្រោម'
    },
    undo: {
      undo: 'បោះបង់',
      redo: 'ធ្វើឡើងវិញ'
    },
    todo: {
      todo: 'នាពេលខាងមុខ'
    },
    listModule: {
      unOrderedList: 'បញ្ជី​ដែល​មិន​បាន​បញ្ជា',
      orderedList: 'បញ្ជីបញ្ជាទិញ'
    },
    tableModule: {
      deleteCol: 'លុបជួរឈរ',
      deleteRow: 'លុបជួរ',
      deleteTable: 'លុបតារាង',
      widthAuto: 'ទទឹងអាដាប់ធ័រ',
      insertCol: 'បញ្ចូលជួរឈរ',
      insertRow: 'បញ្ចូលជួរ',
      insertTable: 'បញ្ចូលតារាង',
      header: 'បឋមកថា'
    },
    videoModule: {
      delete: 'លុបវីដេអូ',
      uploadVideo: 'បង្ហោះវីដេអូ',
      insertVideo: 'បញ្ចូលវីដេអូ',
      videoSrc: 'អាសយដ្ឋានវីដេអូ',
      videoSrcPlaceHolder: 'url ឯកសារវីដេអូ ឬភាគីទីបី <iframe>',
      videoPoster: 'គម្របវីដេអូ',
      videoPosterPlaceHolder: 'រូបភាពគម្រប',
      ok: 'ប្រាកដ',
      editSize: 'កែប្រែទំហំ',
      width: 'ទទឹងកម្រាស់',
      height: 'កម្ពស់ខ្ពស់'
    },
    uploadImgModule: {
      uploadImage: 'បង្ហោះរូបភាពរូបភាព',
      uploadError: '{{fileName}} កំហុសក្នុងការបង្ហោះ'
    },
    highLightModule: {
      selectLang: 'ជ្រើសរើសភាសា'
    }
  },
  ms_MY: {
    editor: {
      more: 'Lagi',
      justify: 'selaraskan',
      indent: 'lekukan',
      image: 'gambar',
      video: 'video'
    },
    common: {
      ok: 'pasti',
      delete: 'padam',
      enter: 'pemulangan pengangkutan'
    },
    blockQuote: {
      title: 'petikan'
    },
    codeBlock: {
      title: 'blok kod'
    },
    color: {
      color: 'warna teks',
      bgColor: 'warna latar belakang',
      default: 'warna lalai',
      clear: 'warna latar belakang yang jelas'
    },
    divider: {
      title: 'Garis pemisah'
    },
    emotion: {
      title: 'ungkapan'
    },
    fontSize: {
      title: 'Saiz huruf',
      default: 'saiz fon lalai'
    },
    fontFamily: {
      title: 'fon',
      default: 'fon lalai'
    },
    fullScreen: {
      title: 'skrin penuh'
    },
    header: {
      title: 'tajuk',
      text: 'teks'
    },
    image: {
      netImage: 'Imej web',
      delete: 'padam gambar',
      edit: 'edit gambar',
      viewLink: 'lihat pautan',
      src: 'Alamat peta',
      desc: 'penerangan imej',
      link: 'pautan imej'
    },
    indent: {
      decrease: 'mengurangkan lekukan',
      increase: 'meningkatkan inden'
    },
    justify: {
      left: 'dijajar ke kiri',
      right: 'Sejajar ke kanan',
      center: 'penjajaran tengah',
      justify: 'Mewajarkan'
    },
    lineHeight: {
      title: 'ketinggian baris',
      default: 'Ketinggian baris lalai'
    },
    link: {
      insert: 'Sisipkan pautan',
      text: 'teks pautan',
      url: 'alamat pautan',
      unLink: 'Nyahpaut',
      edit: 'Ubah suai pautan',
      view: 'lihat pautan'
    },
    textStyle: {
      bold: 'berani',
      clear: 'format yang jelas',
      code: 'kod sebaris',
      italic: 'huruf condong',
      sub: 'subskrip',
      sup: 'Superskrip',
      through: 'tembus',
      underline: 'garis bawah'
    },
    undo: {
      undo: 'membatalkan',
      redo: 'buat semula'
    },
    todo: {
      todo: 'Akan datang'
    },
    listModule: {
      unOrderedList: 'senarai tidak teratur',
      orderedList: 'senarai pesanan'
    },
    tableModule: {
      deleteCol: 'padam lajur',
      deleteRow: 'Padamkan baris',
      deleteTable: 'padamkan borang',
      widthAuto: 'Lebar penyesuaian',
      insertCol: 'masukkan lajur',
      insertRow: 'Sisipkan baris',
      insertTable: 'Sisipkan jadual',
      header: 'Kepala'
    },
    videoModule: {
      delete: 'Padamkan video',
      uploadVideo: 'Muat naik video',
      insertVideo: 'Sisipkan video',
      videoSrc: 'Alamat video',
      videoSrcPlaceHolder: 'Url fail video atau <iframe> pihak ketiga',
      videoPoster: 'Muka depan video',
      videoPosterPlaceHolder: 'gambar muka depan',
      ok: 'pasti',
      editSize: 'Ubah suai saiz',
      width: 'Lebar ຄວາມກວ້າງ',
      height: 'ketinggianສູງ'
    },
    uploadImgModule: {
      uploadImage: 'Muat naik gambar ອັບໂຫຼດຮູບ',
      uploadError: 'Ralat muat naik {{fileName}}'
    },
    highLightModule: {
      selectLang: 'Pilih bahasa'
    }
  },
  th_TH: {
    editor: {
      more: 'มากกว่า',
      justify: 'การจัดตำแหน่ง',
      indent: 'การเยื้อง',
      image: 'รูปภาพ',
      video: 'วิดีโอ'
    },
    common: {
      ok: 'แน่นอน',
      delete: 'ลบ',
      enter: 'เข้า'
    },
    blockQuote: {
      title: 'อ้าง'
    },
    codeBlock: {
      title: 'บล็อกรหัส'
    },
    color: {
      color: 'สีข้อความ',
      bgColor: 'สีพื้นหลัง',
      default: 'สีเริ่มต้น',
      clear: 'สีพื้นหลังที่ชัดเจน'
    },
    divider: {
      title: 'เส้นแบ่ง'
    },
    emotion: {
      title: 'การแสดงออก'
    },
    fontSize: {
      title: 'ขนาดตัวอักษร',
      default: 'ขนาดตัวอักษรเริ่มต้น'
    },
    fontFamily: {
      title: 'แบบอักษร',
      default: 'แบบอักษรเริ่มต้น'
    },
    fullScreen: {
      title: 'เต็มจอ'
    },
    header: {
      title: 'ชื่อ',
      text: 'ข้อความ'
    },
    image: {
      netImage: 'ภาพจากเว็บ',
      delete: 'ลบรูปภาพ',
      edit: 'แก้ไขรูปภาพ',
      viewLink: 'ดูลิงค์',
      src: 'ที่อยู่ของแผนที่',
      desc: 'คำอธิบายรูปภาพ',
      link: 'ลิงค์รูปภาพ'
    },
    indent: {
      decrease: 'ลดการเยื้อง',
      increase: 'เพิ่มการเยื้อง'
    },
    justify: {
      left: 'จัดชิดซ้าย',
      right: 'จัดชิดขวา',
      center: 'การจัดตำแหน่งกึ่งกลาง',
      justify: 'จัดชิดขอบ'
    },
    lineHeight: {
      title: 'ความสูงของแถว',
      default: 'ความสูงของแถวเริ่มต้น'
    },
    link: {
      insert: 'ใส่ลิงค์',
      text: 'ข้อความลิงก์',
      url: 'ที่อยู่ลิงค์',
      unLink: 'ยกเลิกการเชื่อมโยง',
      edit: 'แก้ไขลิงค์',
      view: 'ดูลิงค์'
    },
    textStyle: {
      bold: 'ตัวหนา',
      clear: 'รูปแบบที่ชัดเจน',
      code: 'รหัสอินไลน์',
      italic: 'ตัวเอียง',
      sub: 'ตัวห้อย',
      sup: 'ตัวยก',
      through: 'ขีดทับ',
      underline: 'ขีดเส้นใต้'
    },
    undo: {
      undo: 'ยกเลิก',
      redo: 'ทำซ้ำ'
    },
    todo: {
      todo: 'ที่กำลังจะมีขึ้น'
    },
    listModule: {
      unOrderedList: 'รายการที่ไม่เรียงลำดับ',
      orderedList: 'รายการสั่งซื้อ'
    },
    tableModule: {
      deleteCol: 'ลบคอลัมน์',
      deleteRow: 'ลบแถว',
      deleteTable: 'ลบตาราง',
      widthAuto: 'ปรับความกว้างได้',
      insertCol: 'แทรกคอลัมน์',
      insertRow: 'แทรกแถว',
      insertTable: 'แทรกตาราง',
      header: 'หัวข้อ'
    },
    videoModule: {
      delete: 'ลบวิดีโอ',
      uploadVideo: 'อัปโหลดวิดีโอ',
      insertVideo: 'ใส่วีดีโอ',
      videoSrc: 'ที่อยู่วิดีโอ',
      videoSrcPlaceHolder: 'URL ไฟล์วิดีโอหรือ <iframe> ของบุคคลที่สาม',
      videoPoster: 'ปกวิดีโอ',
      videoPosterPlaceHolder: 'ภาพหน้าปก',
      ok: 'แน่นอน',
      editSize: 'ปรับเปลี่ยนขนาด',
      width: 'ความกว้าง ຄວາມກວ້າງ',
      height: 'ความสูงສູງ'
    },
    uploadImgModule: {
      uploadImage: 'อัพโหลดรูปภาพ ອັບໂຫຼດຮູບ',
      uploadError: 'ข้อผิดพลาดในการอัปโหลด {{fileName}}'
    },
    highLightModule: {
      selectLang: 'เลือกภาษา'
    }
  },
  vi_VN: {
    editor: {
      more: 'Hơn',
      justify: 'căn chỉnh',
      indent: 'vết lõm',
      image: 'hình ảnh',
      video: 'băng hình'
    },
    common: {
      ok: 'Chắc chắn',
      delete: 'xóa bỏ',
      enter: 'Đi vào'
    },
    blockQuote: {
      title: 'trích dẫn'
    },
    codeBlock: {
      title: 'khối mã'
    },
    color: {
      color: 'văn bản màu',
      bgColor: 'màu nền',
      default: 'Màu mặc định',
      clear: 'màu nền rõ ràng'
    },
    divider: {
      title: 'Đường phân chia'
    },
    emotion: {
      title: 'sự biểu lộ'
    },
    fontSize: {
      title: 'Cỡ chữ',
      default: 'Cỡ chữ mặc định'
    },
    fontFamily: {
      title: 'nét chữ',
      default: 'Phông chữ mặc định'
    },
    fullScreen: {
      title: 'toàn màn hình'
    },
    header: {
      title: 'tiêu đề',
      text: 'chữ'
    },
    image: {
      netImage: 'Hình ảnh trên web',
      delete: 'Xóa ảnh',
      edit: 'Chỉnh sửa hình ảnh',
      viewLink: 'Xem liên kết',
      src: 'Địa chỉ của bản đồ',
      desc: 'Mô tả hình ảnh',
      link: 'Đường kết nối tới hình ảnh'
    },
    indent: {
      decrease: 'giảm vết lõm',
      increase: 'tăng thụt lề'
    },
    justify: {
      left: 'căn lề trái',
      right: 'Sắp xếp đúng',
      center: 'căn giữa',
      justify: 'biện minh'
    },
    lineHeight: {
      title: 'chiều cao giữa các dòng',
      default: 'Chiều cao hàng mặc định'
    },
    link: {
      insert: 'Chèn đường dẫn',
      text: 'văn bản liên kết',
      url: 'địa chỉ liên kết',
      unLink: 'hủy liên kết',
      edit: 'Sửa đổi liên kết',
      view: 'Xem liên kết'
    },
    textStyle: {
      bold: 'In đậm',
      clear: 'định dạng rõ ràng',
      code: 'mã nội tuyến',
      italic: 'chữ in nghiêng',
      sub: 'chỉ số dưới',
      sup: 'chỉ số trên',
      through: 'gạch ngang',
      underline: 'gạch chân'
    },
    undo: {
      undo: 'thu hồi',
      redo: 'Làm lại'
    },
    todo: {
      todo: 'Sắp tới'
    },
    listModule: {
      unOrderedList: 'danh sách không có thứ tự',
      orderedList: 'danh sách được yêu cầu'
    },
    tableModule: {
      deleteCol: 'Xóa cột',
      deleteRow: 'Xóa hàng',
      deleteTable: 'Xóa bảng',
      widthAuto: 'Thích ứng chiều rộng',
      insertCol: 'chèn cột',
      insertRow: 'Chèn hàng',
      insertTable: 'Chèn bảng',
      header: 'tiêu đề'
    },
    videoModule: {
      delete: 'Xóa video',
      uploadVideo: 'Tải video lên',
      insertVideo: 'Chèn video',
      videoSrc: 'Địa chỉ video',
      videoSrcPlaceHolder: 'Url tệp video hoặc <iframe> của bên thứ ba',
      videoPoster: 'Bìa video',
      videoPosterPlaceHolder: 'ảnh bìa',
      ok: 'Chắc chắn',
      editSize: 'Sửa đổi kích thước',
      width: 'Chiều rộng Chiều rộng',
      height: 'chiều cao'
    },
    uploadImgModule: {
      uploadImage: 'Tải ảnh lên đi',
      uploadError: 'Lỗi tải lên {{fileName}}'
    },
    highLightModule: {
      selectLang: 'Chọn một ngôn ngữ'
    }
  },
  my_MM: {
    editor: {
      more: 'နောက်ထပ်',
      justify: 'တန်းညှိခြင်း။',
      indent: 'indentation',
      image: 'ပုံ',
      video: 'ဗီဒီယို'
    },
    common: {
      ok: 'သေချာတယ်။',
      delete: 'ဖျက်ပါ။',
      enter: 'ဝင်ပါ။'
    },
    blockQuote: {
      title: 'ကိုးကား'
    },
    codeBlock: {
      title: 'ကုဒ်တုံး'
    },
    color: {
      color: 'စာသားအရောင်',
      bgColor: 'နောက်ခံအရောင်',
      default: 'ပုံသေအရောင်',
      clear: 'နောက်ခံအရောင် ရှင်းသည်။'
    },
    divider: {
      title: 'မျဉ်းကြောင်းခွဲခြင်း။'
    },
    emotion: {
      title: 'အသုံးအနှုန်း'
    },
    fontSize: {
      title: 'ဖောင့်အရွယ်အစား',
      default: 'မူရင်းဖောင့်အရွယ်အစား'
    },
    fontFamily: {
      title: 'ဖောင့်',
      default: 'ပုံသေဖောင့်'
    },
    fullScreen: {
      title: 'မျက်နှာပြင်အပြည့်'
    },
    header: {
      title: 'ခေါင်းစဥ်',
      text: 'စာသား'
    },
    image: {
      netImage: 'ဝဘ်ပုံ',
      delete: 'ပုံဖျက်ပါ။',
      edit: 'ပုံကို တည်းဖြတ်ပါ။',
      viewLink: 'လင့်ခ်ကို ကြည့်ရှုပါ။',
      src: 'မြေပုံရဲ့လိပ်စာ',
      desc: 'ပုံဖော်ပြချက်',
      link: 'ပုံလင့်ခ်'
    },
    indent: {
      decrease: 'indentation ကို လျှော့ချပါ။',
      increase: 'indentation တိုးလာသည်။'
    },
    justify: {
      left: 'ဘယ်ဘက်သို့ ညှိပါ။',
      right: 'ညာဘက်ကို ညှိပါ။',
      center: 'အလယ်တန်းညှိခြင်း။',
      justify: 'တရားမျှတပါ။'
    },
    lineHeight: {
      title: 'မျဉ်းအမြင့်',
      default: 'ပုံသေမျဉ်းအမြင့်'
    },
    link: {
      insert: 'link ထည့်ပါ။',
      text: 'link စာသား',
      url: 'လင့်ခ်လိပ်စာ',
      unLink: 'လင့်ခ်ဖြုတ်ပါ။',
      edit: 'လင့်ခ်ကို ပြင်ဆင်ပါ။',
      view: 'လင့်ခ်ကို ကြည့်ရှုပါ။'
    },
    textStyle: {
      bold: 'ရဲရင့်သည်။',
      clear: 'ရှင်းလင်းသောပုံစံ',
      code: 'အတွင်းကုဒ်',
      italic: 'စာလုံးစောင်း',
      sub: 'subscript',
      sup: 'လုံးကြီးတင်',
      through: 'ဖောက်',
      underline: 'မျဉ်းသား'
    },
    undo: {
      undo: 'မလုပ်တော့',
      redo: 'ပြန်လုပ်ပါ။'
    },
    todo: {
      todo: 'အရင်လို'
    },
    listModule: {
      unOrderedList: 'အစီအစဥ်မရှိသောစာရင်း',
      orderedList: 'အမိန့်စာရင်း'
    },
    tableModule: {
      deleteCol: 'ကော်လံကိုဖျက်ပါ။',
      deleteRow: 'အတန်းကိုဖျက်ပါ။',
      deleteTable: 'ပုံစံကိုဖျက်ပါ။',
      widthAuto: 'သပ္ပနံ',
      insertCol: 'ကော်လံထည့်ပါ။',
      insertRow: 'အတန်းထည့်ပါ။',
      insertTable: 'ဇယားထည့်ပါ။',
      header: 'ခေါင်းစီး'
    },
    videoModule: {
      delete: 'ဗီဒီယိုကိုဖျက်ပါ။',
      uploadVideo: 'ဗီဒီယိုကို တင်ပါ။',
      insertVideo: 'ဗီဒီယိုထည့်ပါ။',
      videoSrc: 'ဗီဒီယိုလိပ်စာ',
      videoSrcPlaceHolder: 'ဗီဒီယိုဖိုင် url သို့မဟုတ် ပြင်ပအဖွဲ့အစည်း <iframe>',
      videoPoster: 'ဗီဒီယိုကာဗာ',
      videoPosterPlaceHolder: 'မျက်နှာဖုံးပုံ',
      ok: 'သေချာတယ်။',
      editSize: 'အရွယ်အစားကိုမွမ်းမံပါ။',
      width: 'အကျယ်',
      height: 'အမြင့်ကြီး'
    },
    uploadImgModule: {
      uploadImage: 'ပုံတစ်ပုံကို တင်ပါ။',
      uploadError: '{{fileName}} ကို အပ်လုဒ်တင်ရာတွင် အမှားအယွင်းရှိနေသည်'
    },
    highLightModule: {
      selectLang: 'ဘာသာစကားတစ်ခုကို ရွေးပါ။'
    }
  },
  am_ET: {
    editor: {
      more: 'ተጨማሪ',
      justify: 'አሰላለፍ',
      indent: 'ወደ ውስጥ መግባት',
      image: 'ስዕል',
      video: 'ቪዲዮ'
    },
    common: {
      ok: 'በእርግጠኝነት',
      delete: 'ሰርዝ',
      enter: 'የመጓጓዣ መመለስ'
    },
    blockQuote: {
      title: 'ጥቅስ'
    },
    codeBlock: {
      title: 'ኮድ እገዳ'
    },
    color: {
      color: 'የጽሑፍ ቀለም',
      bgColor: 'የጀርባ ቀለም',
      default: 'ነባሪ ቀለም',
      clear: 'ግልጽ የጀርባ ቀለም'
    },
    divider: {
      title: 'የመከፋፈል መስመር'
    },
    emotion: {
      title: 'አገላለጽ'
    },
    fontSize: {
      title: 'የቅርጸ ቁምፊ መጠን',
      default: 'ነባሪ የቅርጸ-ቁምፊ መጠን'
    },
    fontFamily: {
      title: 'ቅርጸ-ቁምፊ',
      default: 'ነባሪ ቅርጸ-ቁምፊ'
    },
    fullScreen: {
      title: 'ሙሉ ማያ'
    },
    header: {
      title: 'ርዕስ',
      text: 'ጽሑፍ'
    },
    image: {
      netImage: 'የድር ምስል',
      delete: 'ስዕል ሰርዝ',
      edit: 'ስዕል አርትዕ',
      viewLink: 'አገናኝ ይመልከቱ',
      src: 'የካርታው አድራሻ',
      desc: 'የምስል መግለጫ',
      link: 'የምስል አገናኝ'
    },
    indent: {
      decrease: 'መግባቱን ይቀንሱ',
      increase: 'ገብ መጨመር'
    },
    justify: {
      left: 'በግራ የተሰለፈ',
      right: 'ወደ ቀኝ አሰልፍ',
      center: 'የመሃል አሰላለፍ',
      justify: 'አረጋግጡ'
    },
    lineHeight: {
      title: 'የረድፍ ቁመት',
      default: 'ነባሪ የረድፍ ቁመት'
    },
    link: {
      insert: 'አገናኝ አስገባ',
      text: 'አገናኝ ጽሑፍ',
      url: 'አገናኝ አድራሻ',
      unLink: 'ግንኙነት አቋርጥ',
      edit: 'አገናኝ አርትዕ',
      view: 'የእይታ አገናኝ'
    },
    textStyle: {
      bold: 'ደፋር',
      clear: 'ግልጽ ቅርጸት',
      code: 'የመስመር ውስጥ ኮድ',
      italic: 'ሰያፍ',
      sub: 'የደንበኝነት ምዝገባ',
      sup: 'ልዕለ ስክሪፕት።',
      through: 'አድማ',
      underline: 'አስምርበት'
    },
    undo: {
      undo: 'መሻር',
      redo: 'ድገም'
    },
    todo: {
      todo: 'መጪ'
    },
    listModule: {
      unOrderedList: 'ያልታዘዘ ዝርዝር',
      orderedList: 'የታዘዘ ዝርዝር'
    },
    tableModule: {
      deleteCol: 'አምድ ሰርዝ',
      deleteRow: 'ረድፍ ሰርዝ',
      deleteTable: 'ሠንጠረዥን ሰርዝ',
      widthAuto: 'ስፋት የሚለምደዉ',
      insertCol: 'አምድ አስገባ',
      insertRow: 'ረድፍ አስገባ',
      insertTable: 'ጠረጴዛ አስገባ',
      header: 'ራስጌ'
    },
    videoModule: {
      delete: 'ቪዲዮ ሰርዝ',
      uploadVideo: 'ቪዲዮ ስቀል',
      insertVideo: 'ቪዲዮ አስገባ',
      videoSrc: 'የቪዲዮ አድራሻ',
      videoSrcPlaceHolder: 'የቪዲዮ ፋይል url ወይም የሶስተኛ ወገን <iframe>',
      videoPoster: 'የቪዲዮ ሽፋን',
      videoPosterPlaceHolder: 'የሽፋን ምስል',
      ok: 'በእርግጠኝነት',
      editSize: 'መጠን ቀይር',
      width: 'ስፋት.',
      height: 'ቁመት'
    },
    uploadImgModule: {
      uploadImage: 'ሥዕል ስቀሉ',
      uploadError: '{{fileName}} የሰቀላ ስህተት'
    },
    highLightModule: {
      selectLang: 'ቋንቋ ይምረጡ'
    }
  }
}
