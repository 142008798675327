// 老挝
export default {
    返回: 'ກັບຄືນ',
    用户服务协议及法律声明: 'ສັນຍາການໃຊ້ບໍລິການ ຂອງຜູ້ໃຊ້ແລະ ຄຳຊີ້ແຈງທາງກົດໝາຍ​',
    用户服务协议: 'ຂໍ້ຕົກລົງບໍລິການຜູ້ໃຊ້',
    法律声明: 'ປະກາດທາງກົດໝາຍ',
    隐私政策: 'ນະໂຍບາຍຄວາມເປັນສ່ວນໂຕ',
    商品分类: 'ໝວດໝູ່',
    请选择: 'ກະລຸນາເລືອກ',
    暂无商品: 'ບໍ່ມີຜະລິດຕະພັນ',
    请选择商品分类: 'ກະລຸນາເລືອກປະເພດຜະລິດຕະພັນ',
    全部: 'ທັງໝົດ',
    销量从高到低: 'ຍອດຂາຍຈາກສູງຫາຕ່ໍາ',
    销量从低到高: 'ຍອດຂາຍຈາກຕ່ໍາຫາສູງ',
    价格从高到低: 'ລາຄາຈາກສູງຫາຕ່ໍາ',
    价格从低到高: 'ລາຄາຈາກຕ່ໍາຫາສູງ',
    推荐产品: 'ສິນຄ້າແນະນໍາ',
    首页: 'ໜ້າ',
    全部产品: 'ສິນຄ້າທັງຫມົດ',
    搜索: 'ຄົ້ນຫາ',
    请输入内容: 'ກະລຸນາປ້ອນເນື້ອໃນ',
    免费注册: 'ລົງທະບຽນຟຣີ',
    邮箱: 'ອີເມວ',
    请输入邮箱: 'ກະລຸນາປ້ອນອີເມວ',
    密码: 'ລະຫັດຜ່ານ',
    请输入密码: 'ກະລຸນາປ້ອນລະຫັດ',
    请设置位密码需包字母及数字: 'ກະລຸນາຕັ້ງລະຫັດ 8-16 ຕົວອັກສອນ, ລວມທັງຕົວອັກສອນແລະຕົວເລກ',
    手机号: 'ເບີ​ໂທ',
    验证码: 'ລະຫັດຢືນຢັນ',
    验证码2: 'ລະຫັດຢືນຢັນ',
    请输入验证码: 'ກະລຸນາໃສ່ລະຫັດຢືນຢັນ',
    已阅读并同意以下协议: 'ຂ້ອຍໄດ້ອ່ານແລະ ຕົກລົງເຫັນດີກັບຂໍ້ຕົກລົງດັ່ງລຸ່ມນີ້',
    请勾选商品: 'ກະລຸນາໝາຍຕິກສິນຄ້າ',

    注册: 'ລົງທະບຽນ',
    已有账号: 'ມີບັນຊີຢູ່ແລ້ວ',
    直接登录: 'ເຂົ້າສູ່ລະບົບໂດຍກົງ',
    秒后重新发送: 'ສົ່ງອີກຄັ້ງໃນ {seconds} ວິນາທີ',
    发送验证码: 'ສົ່ງລະຫັດຢືນຢັນ',
    邮箱必须输入: 'ຕ້ອງປ້ອນອີເມວ',
    请输入正确的邮箱: 'ກະລຸນາໃສ່ອີເມວທີ່ບໍ່ມີປະໂຫຍດຂອງທ່ານ',
    密码必须输入: 'ຕ້ອງປ້ອນລະຫັດ',
    密码最少8位最多16位: 'ລະຫັດຜ່ານຕ້ອງມີຢ່າງໜ້ອຍ 8 ຕົວອັກສອນ ແລະ ບໍ່ເກີນ 16 ຕົວອັກສອນ',
    请输入正确的密码格式必须包含两种字符: 'ກະລຸນາໃສ່ຮູບແບບລະຫັດຜ່ານທີ່ຖືກຕ້ອງຕ້ອງມີສອງປະເພດ',
    请输入正确的手机号: 'ກະລຸນາປ້ອນເບີໂທລະສັບທີ່ຖືກຕ້ອງ',
    请输入正确验证码: 'ກະລຸນາປ້ອນລະຫັດຢືນຢັນທີ່ຖືກຕ້ອງ',
    注册成功: 'ລົງທະບຽນສໍາເລັດ',
    需勾选协议: 'ຈໍາເປັນຕ້ອງໄດ້ໝາຍຕິກຂໍ້ຕົກລົງ',
    发送成功: 'ສົ່ງສຳເລັດແລ້ວ',
    重新发送验证码: 'ສົ່ງລະຫັດອີກຄັ້ງ',
    登录: 'ເຂົ້າ​ສູ່​ລະ​ບົບ',
    密码登录: 'ເຂົ້າສູ່ລະບົບດ້ວຍລະຫັດຜ່ານ',
    验证码登录: 'ເຂົ້າສູ່ລະບົບດ້ວຍລະຫັດຢືນຢັນ',
    请输入邮箱手机号: 'ກະລຸນາປ້ອນອີເມວ/ເບີໂທມືຖື',
    请输入账号密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານບັນຊີ',
    立即登录: 'ເຂົ້າສູ່ລະບົບທັນທີ',
    我是新用户: 'ຂ້ອຍເປັນຜູ້ໃຊ້ໃໝ່',
    立即注册: 'ລົງທະບຽນຕອນນີ້',
    忘记密码: 'ລືມລະຫັດຜ່ານ',
    欢迎登录: 'ຍິນດີຕ້ອນຮັບເຂົ້າສູ່ລະບົບ',
    取消: 'ຍົກເລີກ',
    确定: 'ໝັ້ນໝາຍ',
    验证邮箱: 'ຢືນຢັນອີເມວ',
    修改邮箱: 'ແກ້ໄຂອີເມວ',
    手机验证: 'ຢືນຢັນເບີໂທລະສັບ',
    邮箱验证: 'ຢັ້ງຢືນອີເມລ',
    邮箱修改: 'ການແກ້ໄຂອີເມວ',
    完成: 'ສໍາເລັດ',
    为确认身份我们需验证您的安全手机: 'ເພື່ອຢືນຢັນຕົວຕົນ, ພວກເຮົາຈໍາເປັນຕ້ອງຢືນຢັນຜ່ານໂທລະສັບທີ່ມີຄວາມປອດໄພຂອງທ່ານ',
    如果您当前的手机号已无法使用请联系客服: 'ຖ້າເບີໂທລະສັບມືຖືປັດຈຸບັນຂອງເຈົ້າບໍ່ສາມາດໃຊ້ງານໄດ້, ກະລຸນາຕິດຕໍ່ຝ່າຍບໍລິການລູກຄ້າ',
    当前安全手机号: 'ເບີໂທທີ່ມີຄວາມປອດໄພ',
    恭喜您邮箱地址验证成功: 'ຊົມເຊີຍ! ຢືນຢັນທີ່ຢູ່ອີເມວສຳເລັດແລ້ວ',
    请在下次使用邮箱地址进行登录: 'ກະລຸນາໃຊ້ທີ່ຢູ່ອີເມວຂອງທ່ານເພື່ອເຂົ້າສູ່ລະບົບຄັ້ງຕໍ່ໄປ',
    下一步: 'ຕໍ່​ໄປ',
    返回个人中心: 'ກັບໄປທີ່ສູນຂໍ້ມູນສ່ວນຕົວ',
    修改密码: 'ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ',
    再次输入新密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານໃໝ່ອີກຄັ້ງ',
    立即修改: 'ແກ້ໄຂທັນທີ',
    请再次输入密码: 'ກະລຸນາໃສ່ລະຫັດຜ່ານອີກຄັ້ງ',
    两次输入密码不一致: 'ລະຫັດຜ່ານທີ່ໃສ່2ຄັ້ງແມ່ນບໍ່ຖືກຕ້ອງ',
    请输入旧密码: 'ກະລຸນາໃສ່ລະຫັດຜ່ານເກົ່າ',
    长度在8到16个字符: 'ຄວາມຍາວລະຫວ່າງ 8 ຫາ 16 ຕົວອັກສອນ',
    请输入新密码: 'ກະລຸນາໃສ່ລະຫັດຜ່ານໃໝ່',
    必须包含两种字符: 'ຕ້ອງມີຕົວອັກສອນສອງຕົວ',
    新密码不能和旧密码相同: 'ລະຫັດຜ່ານໃໝ່ບໍ່ສາມາດໃສ່ຄືກັບໂຕເກົ່າໄດ້',
    修改成功: 'ແກ້ໄຂສຳເລັດແລ້ວ',
    找回密码: 'ດຶງລະຫັດຜ່ານ',
    立即提交: 'ສົ່ງທັນທີ',
    姓: 'ນາມສະກຸນ',
    名: 'ຊື່',
    国家区域: 'ປະເທດ/ພາກພື້ນ',
    城市: 'ເມືອງ',
    邮政编码: 'ລະ​ຫັດ​ໄປ​ສະ​ນີ',
    详细地址: 'ທີ່ຢູ່ລະອຽດ',
    默认地址: 'ທີ່ຢູ່ເລີ່ມຕົ້ນ',
    新增地址: 'ເພີ່ມທີ່ຢູ່',
    请输入名: 'ກະລຸນາຂຽນຊື່',
    长度在1到20个字符: 'ຄວາມຍາວ 1-20 ຕົວອັກສອນ',
    长度在1到8个字符: 'ຄວາມຍາວ 1-8 ຕົວອັກສອນ',
    请输入姓: 'ກະລຸນາຂຽນນາມສະກຸນ',
    请选择国家: 'ກະລຸນາເລືອກປະເທດ',
    请选择城市: 'ກະລຸນາເລືອກລັດ/ເມືອງ/ລັດ',
    请输入邮编: 'ກະລຸນາປ້ອນລະຫັດໄປສະນີ',
    请输入详细地址: 'ກະລຸນາປ້ອນທີ່ຢູ່ລະອຽດ',
    请输入电话号码: 'ກະລຸນາປ້ອນເບີໂທ',
    编辑地址: 'ແກ້ໄຂທີ່ຢູ່',
    收货地址: 'ທີ່ຢູ່ຈັດສົ່ງ',
    地址最多: 'ສູງສຸດ',
    条: 'ທີ່ຢູ່',
    还能保存: 'ແລະອີກ',
    xxx条: 'ທີ່ຢູ່ສາມາດບັນທຶກໄດ້',
    地址最多10条还能保存xxx条: 'ສູງສຸດ 10 ທີ່ຢູ່, ແລະອີກ {seconds} ທີ່ຢູ່ສາມາດບັນທຶກໄດ້',
    编辑: 'ແກ້ໄຂ',
    删除地址: 'ລຶບທີ່ຢູ່',
    暂无地址信息: 'ບໍ່ມີຂໍ້ມູນທີ່ຢູ່ໃນຂະນະນີ້',
    是否删除地址: 'ຕ້ອງການລຶບທີ່ຢູ່ຫຼືບໍ່',
    删除成功: 'ລຶບສຳເລັດແລ້ວ',
    已取消删除: 'ຍົກເລີກການລຶບ',
    是否删除: 'ລຶບ​ຫຼື​ບໍ່​',
    售后申请: 'ຄໍາຮ້ອງຂໍບໍລິການຫລັງການຂາຍ',
    售后申请提交成功: 'ສົ່ງຄຳຮ້ອງຂໍບໍລິການຫຼັງການຂາຍສຳເລັດແລ້ວ',
    售后申请提交失败: 'ການຍື່ນໃບສະໝັກຫຼັງການຂາຍລົ້ມເຫລວ',
    售后专员可能与您电话沟通请保持手机畅通: 'ເຈົ້າໜ້າທີ່ບໍລິການຫຼັງການຂາຍຈະຕິດຕໍ່ຜ່ານທາງໂທລະສັບ, ກະລຸນາເປີດໂທລະສັບໄວ້',
    温馨提示: 'ເຄັດລັບດີໆ',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询: 'ທີ່ຢູ່ການສົ່ງຄືນສິນຄ້າຈະຖືກແຈ້ງໃຫ້ຊາບໃນຮູບແບບຂອງຂໍ້ຄວາມ ຫຼື ສາມາດກວດສອບຈາກບັນທຶກປະຫວັດຄຳຂໍ​',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通: 'ຫຼັງຈາກສົ່ງຄໍາສັ່ງຊື້ການບໍລິການແລ້ວ, ຜູ້​​ບໍລິການຈະຕິດຕໍ່ຜ່ານທາງໂທລະສັບ, ກະລຸນາເປີດໂທລະສັບຂອງທ່ານໄວ້',
    退货处理成功后退款金额将原路返回到您的支持账户中: 'ຫຼັງຈາກສົ່ງຄຳຂໍຄືນສິນຄ້າຖືກອະນຸມັດຮຽບຮ້ອຍແລ້ວ, ຍອດຄືນເງິນຈະຖືກສົ່ງຄືນໄປຫາບັນຊີເດີມຂອງທ່ານ',
    联系客服: 'ຕິດຕໍ່ຝ່າຍບໍລິການລູກຄ້າ',
    商品信息: 'ຂໍ້​ມູນ​ຜະ​ລິດ​ຕະ​ພັນ​',
    单价: 'ລາຄາຫົວໜ່ວຍ',
    数量: 'ຈຳນວນ',
    小计: 'ທັງໝົດຍ່ອຍ',
    实付: 'ການຈ່າຍເງິນທີ່ແທ້ຈິງ',
    申请信息: 'ຂໍ້ມູນສະຫມັກ',
    退货类型: 'ປະເພດການຄືນສິນຄ້າ',
    我要退款: 'ຂ້ອຍຕ້ອງການຄືນເງິນ',
    我要退货退款: 'ຂ້ອຍຕ້ອງການຄືນສິນຄ້າແລະ ຄືນເງິນ',
    没收到货或与店铺协商同意不用退货只退款: 'ຍັງບໍ່ໄດ້ຮັບສິນຄ້າ, ຫຼື ເຈລະຈາກັບທາງຮ້ານເພື່ອຕົກລົງທີ່ຈະບໍ່ສົ່ງສິນຄ້າຄືນແຕ່ສົ່ງເງິນຄືນໃຫ້.',
    已收到货需要退还收到的货物: 'ໄດ້ຮັບສິນຄ້າແລ້ວຕ້ອງການສົ່ງຄືນສິນຄ້າທີ່ໄດ້ຮັບ',
    退货金额: 'ຈໍາ​ນວນ​ເງິນ​ຄືນ​',
    不可修改最多: 'ບໍ່ສາມາດແກ້ໄຂໄດ້, ຫຼາຍທີ່ສຸດ',
    含发货邮费: 'ລວມຄ່າສົ່ງຈັດສົ່ງແລ້ວ',
    退货理由: 'ເຫດຜົນສໍາລັບການສົ່ງຄືນ',
    请选择退货原因: 'ກະລຸນາເລືອກເຫດຜົນສໍາລັບການສົ່ງຄືນ',
    问题描述: 'ລາຍລະອຽດບັນຫາ',
    上传凭证: 'ອັບໂຫລດຫຼັກຖານ',
    为了帮助您更好的解决问题请上传图片: 'ເພື່ອຊ່ວຍໃຫ້ທ່ານແກ້ໄຂບັນຫາໄດ້ດີຂຶ້ນ, ກະລຸນາອັບໂຫຼດຮູບ',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件: 'ສາມາດອັບໂຫລດໄດ້ເຖິງ 5 ຮູບ, ແລະຂະໜາດຂອງແຕ່ລະຮູບບໍ່ເກີນ 5M, ຮອງຮັບໄຟລ໌ຮູບແບບ bmp, gif, jpg, png, jpeg',
    联系方式: 'ຂໍ້ມູນຕິດຕໍ່',
    联系人: 'ຜູ້​ຕິດ​ຕໍ່​',
    请输入联系人: 'ກະລຸນາປ້ອນຊື່ຜູ້ຕິດຕໍ່',
    联系电话: 'ເບີ​ໂທ',
    确认提交: 'ຢືນຢັນການສົ່ງ',
    客服电话: 'ສາຍດ່ວນຜູ້ບໍລິການ',
    客服邮箱: 'ອີເມວຝ່າຍບໍລິການ',
    请输入名字: 'ກະລຸນາໃສ່ຊື່',
    长度在2到10个字符: 'ຄວາມຍາວ 2-10 ຕົວອັກສອນ',
    请输入电话: 'ກະລຸນາປ້ອນເບີໂທ',
    请描述问题: 'ກະລຸນາອະທິບາຍລາຍລະອຽດຂອງບັນຫາ',
    最多上传五张: 'ອັບໂຫຼດສູງສຸດໄດ້5ໃບ',
    最多上传一张: 'ອັບໂຫຼດສູງສຸດໄດ້1ໃບ',
    上传成功: 'ອັບໂຫຼດສຳເລັດແລ້ວ',
    文件过大: 'ໄຟລ໌ໃຫຍ່ເກີນໄປ',
    售后单号: 'ໝາຍເລກການສັ່ງຊື້ຫຼັງການຂາຍ',
    申请时间: 'ເວລາສົ່ງຄຳຂໍ',
    重新申请: 'ສົ່ງຄຳຂໍຄືນໃໝ່',
    取消售后: 'ຍົກເລີກບໍລິການຫຼັງການຂາຍແລ້ວ',
    退款失败: 'ຖອນເງີນລົ້ມເຫລວ',
    退款中: 'ກຳລັງດຳເນິນການຄືນເງິນ',
    退款完成: 'ຄືນເງິນສຳເລັດ',
    提交申请: 'ສົ່ງຄຳຂໍ',
    店铺处理: 'ຮ້ານຄ້າກຳລັງດຳເນີນການ',
    店铺退款: 'ຮ້ານຄ້າກຳລັງດຳເນີນການຄືນເງິນ',
    售后完成: 'ສໍາເລັດການໃຫ້ບໍລິການຫຼັງການຂາຍ',
    售后商品: 'ສິນຄ້າທີ່ໄດ້ຮັບບໍລິການຫຼັງການຂາຍ',
    售后信息: 'ຂໍ້ມູນບໍລິການຫລັງການຂາຍ',
    售后方式: 'ຮູບແບບບໍລິການຫຼັງການຂາຍ',
    退款金额: 'ຈຳນວນເງິນຄືນ',
    退款原因: 'ເຫດຜົນການຄືນເງິນ',
    退款说明: 'ອະທິບາຍການສົ່ງເງິນຄືນ',
    购买信息: 'ຂໍ້ມູນການສັ່ງຊື້​',
    商品单价: 'ລາຄາຕໍ່ໜ່ວຍ',
    件: 'ອັນ',
    实付金额: 'ຈໍານວນເງິນທີ່ຈ່າຍຕົວຈິງ',
    发货件数: 'ຈໍານວນສິນຄ້າທີ່ຈັດສົ່ງ',
    发货状态: 'ສະຖານະການຈັດສົ່ງ',
    订单编号: 'ໝາຍເລກຄຳສັ່ງຊື້',
    等待店铺审核: 'ກຳລັງລໍຖ້າຮ້ານຄ້າກວດສອບ',
    留言内容: 'ເນື້ອໃນຂໍ້ຄວາມ',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料: 'ທ່ານສາມາດຝາກຂໍ້ຄວາມໃຫ້ຮ້ານຄ້າໄດ້ທີ່ນີ້, ເພື່ອປົກປ້ອງສິດ ແລະຜົນປະໂຫຍດຂອງເຈົ້າ, ກະລຸນາອັບໂຫຼດຂໍ້ມູນ ແລະ ເອກະສານໃຫ້ລະອຽດທີ່ສຸດ',
    发表留言: 'ຝາກຂໍ້ຄວາມຮຽບຮ້ອຍແລ້ວ',
    店: 'S',
    客: 'C',
    客户: 'ລູກ​ຄ້າ',
    店铺: 'ຮ້ານຄ້າ',
    退货原因: 'ເປັນຫຍັງຈຶ່ງຍົກເລີກສິນຄ້າ(ເຫດຜົນ)',
    退货数量: 'ຈຳນວນສົ່ງສິນຄ້າຄືນ',
    请输入反馈内容: 'ກະລຸນາໃສ່ເນື້ອໃນຄໍາຄິດຄໍາເຫັນ',
    取消成功: 'ຍົກເລີກຄວາມສຳເລັດ',
    发表成功: 'ເຜີຍແຜ່ສຳເລັດແລ້ວ',
    添加超时: 'ເພີ່ມເວລາໝົດອາຍ',
    下单时间: 'ເວລາສັ່ງຊື້',
    查看详情: 'ກວດເບິ່ງລາຍລະອຽດ',
    服务单号: 'ໝາຍເລກການສັ່ງຊື້',
    暂无售后信息: 'ບໍ່ມີຂໍ້ມູນບໍລິການຫຼັງການຂາຍໃນຂະນະນີ້',
    留言回复: 'ຂໍ້ຄວາມຕອບກັບ',
    x条回复: '{num}ລາຍການຕອບກັບ',
    留言回复长度xxx个字符: 'ຂໍ້ຄວາມຕອບກັບ, ຄວາມຍາວ 2-100 ຕົວອັກສອນ',
    提交: 'ສະເຫນີ',
    长度在2到100个字符: 'ຄວາມຍາວ 2-100 ຕົວອັກສອນ',
    评论成功: 'ສະແດງຄຳເຫັນສຳເລັດ',
    手机号码: 'ເບີໂທ',
    邮箱地址: 'ທີ່​ຢູ່​ອີ​ເມວ',
    立即留言: 'ຝາກຂໍ້ຄວາມທັນທີ',
    请填写留言内容: 'ກະລຸນາຂຽນເນື້ອໃນຂໍ້ຄວາມ',
    留言成功: 'ຝາກຂໍ້ຄວາມສຳເລັດ',
    留言管理: 'ການ​ຄຸ້ມ​ຄອງ​ຂໍ້​ຄວາມ​',
    我要留言: 'ຂ້ອຍຕ້ອງການຝາກຂໍ້ຄວາມ',
    回复: 'ຕອບ',
    查看全部: 'ເບິ່ງທັງໝົດ',
    查看全部x条回复: 'ເບິ່ງການຕອບກັບທັງໝົດໃນ {num}  ລາຍການ',
    暂无留言信息: 'ບໍ່ມີຂໍ້ຄວາມໃນຂະນະນີ້',
    全部消息: 'ຂໍ້ຄວາມທັງໝົດ',
    已读消息: 'ຂໍ້ຄວາມອ່ານແລ້ວ',
    未读消息: 'ຂໍ້ຄວາມທີ່ຍັງບໍ່ທັນອ່ານ',
    标记已读: 'ຫມາຍວ່ານອ່ານແລ້ວ',
    删除: 'ລຶບ',
    暂无消息通知: 'ບໍ່ມີການແຈ້ງເຕືອນຂໍ້ຄວາມໃນຂະນະນີ້',
    请勾选信息: 'ກະລຸນາໝາຍຕິກຂໍ້ມູນ',
    请选择删除项需已读才能删除: 'ກະລຸນາເລືອກທີ່ຈະລຶບລາຍການ, ທ່ານຕ້ອງໄດ້ອ່ານມັນເພື່ອລຶບ',
    待付款: 'ລໍຖ້າການຊຳລະເງິນ',
    待发货: 'ບໍ່ທັນໄດ້ສົ່ງ',
    待收货: 'ລໍຖ້າຮັບສິນຄ້າ',
    已完成: 'ສຳເລັດ',
    订单号: 'ເລກຄຳສັ່ງຊື້',
    订单金额: 'ຈຳນວນອໍເດີ້',
    已关闭: 'ປິດ',
    部分订单取消: '部分订单取消',
    部分退款完成: '部分退款完成',
    部分退款: '部分退款',
    部分收货: '部分收货',
    交易异常: 'ຂໍ້ຍົກເວັ້ນການເຮັດທຸລະກໍາ',
    部分发货: 'ການຂົນສົ່ງບາງສ່ວນ',
    发货时间: 'ເວລາຈັດສົ່ງ',
    退款成功: 'ຖອນເງີນສໍາເລັດ',
    取消原因: 'ເຫດຜົນສໍາລັບການຍົກເລີກ',
    暂无订单信息: 'ບໍ່ມີຂໍ້ມູນການສັ່ງຊື້ໃນຂະນະນີ້',
    删除订单成功: 'ລົບຄຳສັ່ງຊື້ສຳເລັດ',
    订单已超时: 'ຄຳສັ່ງຊື້ໝົດເວລາ',
    确认收货成功: 'ຢືນຢັນການຮັບສິນຄ້າສຳເລັດ',
    取消订单成功: "ຍົກເລີກຄຳສັ່ງຊື້ສຳເລັດ",
    当前头像: 'ຮູບໂປຣໄຟລ໌ປະຈຸບັນ',
    点击上传: 'ຄລິກເພື່ອອັບໂຫລດ',
    昵称: 'ຊື່ຫຼີ້ນ',
    保密: 'ຮັກສາຄວາມລັບ',
    男: 'ຜູ້​ຊາຍ',
    女: 'ຜູ້ຍິງ',
    昵称不能为空: 'ຊື່ຫຼີ້ນຫ້າມວ່າງເປົ່າ',
    退出登录: 'ອອກ​ຈາກ​ລະ​ບົບ',
    上传失败: 'ການອັບໂຫລດລົ້ມເຫລວ',
    上传头像图片只能是JPG格式: 'ໂປຣຟາຍທີ່ອັບລົງຈະຕ້ອງເປັນ JPG ເທົ່ານັ້ນ',
    上传头像图片大小不能超过2MB: 'ຂະໜາດຟາຍທີ່ອັບລົງຕ້ອງບໍ່ເກີນ2MB',
    个人信息: 'ຂໍ້ມູນສ່ວນບຸກຄົນ​',
    我的订单: ' ຄໍາສັ່ງຊື້ຂອງຂ້ອຍ',
    售后服务: 'ບໍ​ລິ​ການ​ຫລັງ​ການ​ຂາຍ',
    地址管理: 'ຈັດການທີ່ຢູ່​',
    扫码支付: 'ສະແກນຄິວອາໂຄດຈ່າຍເງິນ',
    消息通知: 'ແຈ້ງເຕືອນຂໍ້ຄວາມ',
    微信支付: 'ການຈ່າຍເງິນ WeChat',
    交易单号: 'ໝາຍເລກທຸລະກຳ',
    支付成功: 'ຊໍາລະເງິນສໍາເລັດ​',
    支付失败: 'ຊຳລະເງິນບໍ່ຊຳເລັດ',
    查看订单: 'ກວດ​ສອບ​ຄໍາ​ສັ່ງ​ຊື້',
    返回首页: 'ກັບ​ຄືນ​ໜ້າ',
    应付总额: 'ຍອດລວມທີ່ຕ້ອງຊຳລະ',
    请您在: 'ກະລຸນາຈ່າຍດ້ວຍວົງເລັບຮູບເງົາພາຍໃນ',
    内完成支付否则订单会被自动取消: ' ,ຖ້າບໍ່ດັ່ງນັ້ນຄໍາສັ່ງຈະຖືກຍົກເລີກໂດຍອັດຕະໂນມັດ',
    请您在x内完成支付否则订单会被自动取消: 'ກະລຸນາຊຳລະເງິນພາຍໃນ <span style="color: red;">{num}</span>, ບໍ່ສະນັ້ນຄໍາສັ່ງຊື້ຈະຖືກຍົກເລີກໂດຍອັດຕະໂນມັດ',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消: 'ທ່ານໄດ້ຊຳລະເງິນແບບອອບໄລນ໌,ກະລຸນາລໍຖ້າໃຫ້ຮ້ານຄ້າຢືນຢັນການຮັບເງິນ! ຖ້າຮ້ານບໍ່ຢືນຢັນການຮັບຫຼັງຈາກ<span style="color: red;">{num}</span>ຄໍາສັ່ງຊື້ຈະຖືກຍົກເລີກໂດຍອັດຕະໂນມັດ!',
    确定放弃支付吗: 'ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການຍົກເລີກການຈ່າຍເງິນ?',
    订单提交成功请尽快付款: 'ມອບໃຫ້ສຳເລັດແລ້ວ, ກະລຸນາຈ່າຍເງິນໄວເທົ່າທີ່ຈະໄວໄດ້！',
    选择以下支付方式付款: 'ເລືອກວິທີຈ່າຍເງິນຕໍ່ໄປນີ້ເພື່ອຈ່າຍເງິນ',
    继续支付: 'ສືບຕໍ່ຈ່າຍ',
    残忍离开: 'ອອກໄປຢ່າງໂຫດຮ້າຍ',
    提示: 'ຄຳໃບ້',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货: 'ການຈ່າຍເງິນແບບອອບລາຍບໍ່ຮອງຮັບການບໍລິການຫຼັງການຂາຍແບບອອນລາຍ, ກະລຸນາຕິດຕໍ່ຜູ້ຄ້າຫາກທ່ານຕ້ອງການບໍລິການຫຼັງການຂາຍ. ຖ້າທ່ານໄດ້ຈ່າຍເງິນແລ້ວ, ກະລຸນາລໍຖ້າດ້ວຍຄວາມອົດທົນເພື່ອໃຫ້ຜູ້ຄ້າຢືນຢັນການຊໍາລະແລະຈັດສົ່ງມັນ!',
    支付凭证: 'ໃບ​ຊໍາ​ລະ​ເງິນ​',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持xxx格式图片不大于3M: 'ຖ້າທ່ານໄດ້ຈ່າຍຕາມວິທີການຊໍາລະຂ້າງເທິງ​ນີ້,  ກະລຸນາອັບໂຫລດເອກະສານທີກ່ຽວຂ້ອງ, ເຊັ່ນ: ພາບໜ້າຈໍການໂອນ ແລະ​ອື່ນໆ​, ຮອງຮັບຮູບແບບ jpg, png , ແລະຮູບພາບບໍ່ຄວນຈະມີຂະຫນາດໃຫຍ່ກວ່າ 3M',
    订单已取消: 'ຄຳສັ່ງນີ້ຖືກຍົກເລີກແລ້ວ',
    分: 'ນາທີ',
    秒: 'ວິນາທີ',
    请选择付款方式: 'ກະລຸນາເລືອກວິທີການຊຳລະເງິນ',
    实付款: 'ການຈ່າຍເງິນທີ່ຕ້ອງຊຳລະ',
    请在: '请在',
    您当前是线下支付请耐心等待店铺确认收款若: 'ເຈົ້າໄດ້ຊຳລະເງິນແບບອອບໄລນ໌,ກະລຸນາລໍຖ້າເພື່ອໃຫ້ຮ້ານຄ້າຢືນຢັນການຮັບສິນຄ້າ！ຖ້າຮ້ານບໍ່ຢືນຢັນການຈ່າຍເງິນຫຼັງຈາກ',
    后店铺未确认收款订单将自动取消: ' , ຄໍາສັ່ງຈະຖືກຍົກເລີກໂດຍອັດຕະໂນມັດ!',
    退货申请中: '退货申请中',
    删除订单: 'ລຶບ​ຄໍາ​ສັ່ງ​ຊື້',
    确认收货: 'ຢືນຢັນການຮັບສິນຄ້າ',
    取消订单: 'ຍົກ​ເລີກ​ຄຳສັ່ງຊື້',
    去付款: 'ໄປຈ່າຍເງິນ',
    支付方式: 'ວິທີການຊໍາລະເງິນ',
    支付宝: 'Alipay',
    微信: 'WeChat',
    线下支付: 'ການຈ່າຍເງິນອອບໄລນ໌',
    商品总额: 'ຈຳນວນສິນຄ້າທັງໝົດ',
    运费: 'ຄ່າຂົນສົ່ງ',
    收货信息: 'ຂໍ້ມູນການຮັບເຄື່ອງ',
    创建时间: 'ເວ​ລາ​ການ​ສ້າງ​',
    付款时间: 'ເວລາຊໍາລະ',
    订单取消时间: 'ເວລາຍົກເລີກການສັ່ງຊື້',
    收件人: 'ຜູ້ຮັບ',
    地址: 'ທີ່ຢູ່',
    部分商品正在退款中: 'ບາງລາຍການກຳລັງດຳເນີນການຄືນເງິນ',
    已经有一个账户: 'ມີບັນຊີຢູ່ແລ້ວ？',
    天: 'ມື້',
    时: 'ເວລາ',
    联系信息: 'ຂໍ້​ມູນ​ຕິດ​ຕໍ່',
    收件地址: 'ທີ່ຢູ່ຜູ້ຮັບ',
    切换地址: 'ປ່ຽນທີ່ຢູ່',
    电子邮件地址: 'ທີ່​ຢູ່​ອີ​ເມວ',
    通过电子邮件向我发送新闻和优惠: 'ສົ່ງອີເມວຂ່າວ ແລະຂໍ້ສະເໜີໃຫ້ຂ້ອຍ',
    姓名: 'ຊື່',
    完整地址: 'ສໍາເລັດທີ່ຢູ່',
    电话: 'ໂທລະສັບ',
    还没有收货地址哦: 'ບໍ່ມີທີ່ຢູ່ຈັດສົ່ງເທື່ອ',
    添加地址: 'ເພີ່ມທີ່ຢູ່',
    在线支付: 'ການຈ່າຍເງິນອອນໄລນ໌',
    商品: 'ສິນຄ້າ',
    备注说明: 'ຫມາຍເຫດ',
    订单: 'ຄຳສັ່ງຊື້',
    商品件数: 'ຈຳນວນສິນຄ້າ',
    商品总价: 'ລາ​ຄາ​ລວມ',
    活动优惠: 'ສ່ວນຫລຸດຈາກໂປໂມຊັນ',
    优惠卷抵扣: 'ຄູປອງສ່ວນຫຼຸດ',
    提交订单: 'ສົ່ງຄຳສັ່ງຊື້',
    库存: 'ຄລັງສິນຄ້າ',
    描述文字: 'ຂໍ້ຄວາມຄໍາອະທິບາຍ',
    发货地: 'ສະຖານທີ່ຈັດສົ່ງ',
    缺少库存: 'ສິນຄ້າໝົດ',
    添加购物车: 'ເພີ່ມໃສ່ກະຕ່າ',
    立即购买: 'ຊື້ທັນທີ',
    此宝贝已下架: 'ສິນຄ້າຖືກນຳອອກຈາກຊັ້ນວາງແລ້ວ',
    描述: 'ຄຳອະທິບາຍ',
    添加成功: 'ເພີ່ມລາຍການສຳເລັດ',
    添加失败库存不足: 'ເພີ່ມສິນຄ້າບໍ່ສຳເລັດ ສິນຄ້າໃນຄັງບໍ່ພຽງພໍ',
    请选择规格: 'ກະລຸນາເລືອກຂໍ້ມູນລາຍລະອຽດຂອງສິນຄ້າ',
    请输入商品名称: 'ກະລຸນາປ້ອນຊື່ສິນຄ້າ',
    综合排序: 'ການຈັດລຽງລຳດັບໂດຍລວມ',
    销量高低: 'ຍອດຂາຍສູງຕ່ຳ',
    价格高低: 'ລາຄາສູງຕ່ຳ',
    没有找到搜索的内容: 'ບໍ່ພົບເນື້ອຫາທີ່ຄົ້ນຫາ',
    选择地址: 'ເລືອກທີ່ຢູ່',
    是否选择该地址: 'ຕ້ອງການເລືອກທີ່ຢູ່ນີ້ບໍ',
    选择成功: 'ເລືອກສຳເລັດ',
    未选择地址或没有地址: 'ບໍ່ໄດ້ເລືອກທີ່ຢູ່ ຫຼື ບໍ່ມີທີ່ຢູ່',
    已取消: 'ຍົກເລີກແລ້ວ',
    操作: 'ການດໍາເນີນງານ',
    全选: 'ເລືອກ​ທັງ​ຫມົດ',
    已失效商品: 'ລາຍການໝົດອາຍຸ',
    已下架: 'ໄດ້ຖືກໂຍກຍ້າຍ',
    删除选中商品: 'ລຶບສິນຄ້າທີ່ເລືອກ',
    共x件商品已选择x件: 'ຈໍານວນທັງໝົດ {num} ລາຍການ, ໄດ້ເລືອກເອົາ {num2} ລາຍການ',
    商品合计: ' ສິນຄ້າທັງໝົດ',
    下单结算: 'ຊຳລະລາຍການສັ່ງຊື້',
    失效宝贝: 'ສິນຄ້າໝົດອາຍຸ',
    删除失效商品: 'ລົບສິນຄ້າທີ່ຫມົດອາຍຸ',
    您的购物车还没有商品哟: 'ບໍ່ມີສິນຄ້າຢູ່ໃນກະຕ່າຊື້ເຄື່ອງຂອງທ່ານ~',
    继续逛逛: 'ສືບຕໍ່ຊື້ເຄື່ອງ',
    性别: 'ເພດ',
    确认已付款: 'ຢືນຢັນການຈ່າຍເງິນ',
    立即支付: 'ຈ່າຍທັນທີ',
    个人中心: 'ສູນຂໍ້ມູນສ່ວນບຸກຄົນ​',
    确定删除选中的商品吗: 'ແນ່ໃຈບໍ່ວ່າຕ້ອງການລົບລາຍການທີ່ເລືອກ？',
    未发货: 'ບໍ່ທັນຈັດສົ່ງ',
    已发货: 'ຈັດສົ່ງແລ້ວ',
    请输入下方图形验证码: 'ກະລຸນາໃສ່ລະຫັດການຢັ້ງຢືນຮູບພາບຂ້າງລຸ່ມນີ້',
    看不清换一张: 'ປ່ຽນອັນຫນຶ່ງ',
    KBZ支付: 'KBZPay',
    BCEL支付: 'BCEL',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名: 'ກະລຸນາຂຽນຂໍ້ຄວາມຂອງທ່ານປະໄວ້, ຖ້າທ່ານຊໍາລະຜ່ານ BCEL ຫຼື KBZPayກະລຸນາພິມເລກບັນຊີ ແລະ ຊື່ບັນຊີທະນາຄານຂອງທ່ານ ສຳລັບໄຊ້ໃນການຈ່າຍ ແລະ ຮັບເງິນຄື',
    查询: 'ສອບຖາມ',
    语言: 'ພາສາ',
    请选择语言: 'ກະລຸນາເລືອກພາສາ',
    货币: 'ເງິນຕາ',
    请选择展示货币: 'ກະລຸນາເລືອກສະກຸນເງິນທີ່ສະແດງ',
    查看支付是否成功: 'ກວດເບິ່ງວ່າການຈ່າຍເງິນສຳເລັດຫຼືບໍ່>>>',
    您未支付请重新支付: 'ທ່ານຍັງບໍ່ໄດ້ຈ່າຍ, ກະລຸນາຈ່າຍອີກເທື່ອຫນຶ່ງ',
    确定已输入接收退款的银行卡号和户名: 'ໃຫ້ແນ່ໃຈວ່າທ່ານໄດ້ໃສ່ເລກບັດທະນາຄານ ແລະຊື່ບັນຊີເພື່ອຮັບເງິນຄືນ',
    分享店铺: 'ແບ່ງປັນຮ້ານ',
    绑定: 'ຜູກມັດ',
    绑定邮箱: 'ຜູກມັດອີເມວ',
    分享至: 'ແຊຣ໌ໄປທີ່',
    保存: 'ເກັບໄວ້',
    复制成功: 'ສຳເນົາສຳເລັດແລ້ວ',
    海报: 'ໂປສເຕີ',
    分享: 'ແບ່ງປັນ',
    推荐店铺给你: 'ສູນການຄ້າແນະນຳໃຫ້ທ່ານ',
    分享店铺至: '分享店铺至',

    至: 'ເຖິງ',
    币种: 'ສະກຸນເງິນ',
    变动理由: 'ເຫດຜົນໃນການປ່ຽນແປງ',
    不记得: 'ບໍ່ຈື່',
    充值: 'ເຕີມ',
    打款凭证: 'ໃບຢັ້ງຢືນການຈ່າຍເງິນ',
    打款说明: 'ຄໍາແນະນໍາການຈ່າຍເງິນ',
    打款信息: 'ຂໍ້ມູນການຊຳລະເງິນ',
    打款状态: 'ສະຖານະການຊຳລະເງິນ',
    待打款: 'ການຈ່າຍເງິນທີ່ຍັງຄ້າງຢູ່',
    待审核: 'ທີ່ຈະໄດ້ຮັບການທົບທວນຄືນ',
    到账方式: 'ວິທີການຊຳລະເງິນ',
    到账银行卡号: 'ຖອນເງິນຮອດບັດທະນາຄານ',
    冻结中: 'ອາຍັດຢູ່',
    国家: 'ຊາດ',
    获取验证码: 'ຮັບລະຫັດຢືນຢັນ',
    记得: 'ຈື່ໄວ້',
    记录时间: 'ເວລາເຂົ້າສູ່ລະບົບ',
    拒绝打款: 'ປະຕິເສດທີ່ຈະຈ່າຍ',
    开户人: 'ເປີດບັນຊີ',
    开户支行: 'ສາຂາການເປີດບັນຊີ',
    可提现: 'ສາມາດຖອນເງິນໄດ້',
    可提现金额: 'ຈໍານວນທີ່ສາມາດຖອນໄດ້',
    立即提现: 'ຖອນເງິນທັນທີ',
    流水号: 'ເລກລໍາດັບ',
    没有适合此币种的银行卡请先添加: 'ບໍ່ມີບັດສຳລັບສະກຸນເງິນນີ້, ກະລຸນາເພີ່ມກ່ອນ',
    没有收款码请先添加: 'ບໍ່ມີລະຫັດການຊຳລະເງິນ, ກະລຸນາເພີ່ມກ່ອນ',
    每天最多提现x次单次最高提现金额x: 'ຖອນສູງສຸດ*ຄັ້ງຕໍ່ວັນ，ຈຳນວນຖອນສູງສຸດຄັ້ງດຽວ*',
    您是否记得账号当前使用的支付密码: 'ທ່ານຈື່ລະຫັດຜ່ານການຊຳລະເງິນທີ່ບັນຊີຂອງທ່ານໃຊ້ຢູ່ໃນປັດຈຸບັນຫຼືບໍ່?',
    您未设置支付密码请先设置: 'ທ່ານຍັງບໍ່ໄດ້ຕັ້ງລະຫັດຜ່ານການຊຳລະເງິນ, ກະລຸນາຕັ້ງກ່ອນ',
    钱包余额: 'ຈຳນວນເງິນໃນກະເປົາ',
    请输入开户人: 'ກະລຸນາປ້ອນຊື່ເຈົ້າຂອງບັນຊີ',
    请输入开户支行: 'ກະລຸນາປ້ອນສາຂາການເປີດບັນຊີ',
    请输入收款名称: 'ກະລຸນາປ້ອນຊື່ການຊຳລະເງິນ',
    请输入提现金额: 'ກະລຸນາປ້ອນຈໍານວນການຖອນເງິນ',
    请输入新支付密码6位数字: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນໃໝ່, 6ຫຼັກ',
    请输入新支付密码: 'ກະລຸນາປ້ອນລະຫັດການຊຳລະເງິນໃໝ່',
    请输入银行卡号: 'ກະລຸນາປ້ອນໝາຍເລກບັດທະນາຄານ',
    请输入原支付密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນເກົ່າ',
    请输入支付密码6位数字: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນ，6ຫຼັກ',
    请输入支付密码: 'ກະລຸນາປ້ອນລະຫັດການຊຳລະເງິນ',
    请选择币种: 'ກະລຸນາເລືອກສະກຸນເງິນ',
    请选择大洲: 'ກະລຸນາເລືອກທະວີບ',
    请选择结束时间: 'ກະລຸນາເລືອກວັນທີສິ້ນສຸດ',
    请选择开始时间: 'ກະລຸນາເລືອກວັນທີເລີ່ມຕົ້ນ',
    请选择收款码类型: 'ກະລຸນາເລືອກປະເພດລະຫັດການຊຳລະເງິນ',
    请选择银行: 'ກະລຸນາເລືອກທະນາຄານ',
    请选择银行名称: 'ກະລຸນາເລືອກຊື່ທະນາຄານ',
    请再次输入新支付密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນໃໝ່ອີກຄັ້ງ',
    请再次输入支付密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນອີກຄັ້ງ',
    去设置: 'ໄປທີ່ການຕັ້ງຄ່າ',
    全部提现: 'ຖອນເງິນທັງຫມົດ',
    确定删除收款码吗: 'ແນ່ໃຈບໍ່ວ່າຈະລຶບລະຫັດການຊຳລະເງິນ',
    确定删除银行卡吗: 'ແນ່ໃຈບໍ່ວ່າຈະລຶບບັດທະນາຄານ',
    确定提现: 'ຢືນຢັນການຖອນເງິນ',
    确认打款: 'ຢືນຢັນການຈ່າຍເງິນ',
    确认新密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນໃໝ່ອີກຄັ້ງ',
    确认支付密码: 'ຢືນຢັນລະຫັດຜ່ານການຊຳລະເງິນ',
    上传的收款二维码需要清晰无遮挡: 'ລະຫັດ QR ການຊຳລະເງິນທີ່ອັບໂຫລດຈະຕ້ອງຊັດເຈນແລະ ບໍ່ມີສິ່ງກີດຂວາງ',
    审核备注: 'ການໄລ່ລຽງບັນທຶກ',
    审核不通过: 'ການກວດສອບບໍ່ຜ່ານ',
    审核结果: 'ຜົນການກວດສອບ',
    审核通过: 'ຜ່ານການໄລ່ລຽງ',
    审核信息: 'ກວດສອບຂໍ້ມູນ',
    时间: 'ເວລາ',
    实际到账金额: 'ຈຳນວນເງິນທີ່ໄດ້ຮັບຈິງ',
    收款方式: 'ວິທີການຊຳລະເງິນ',
    收款管理: 'ການຈັດການຄໍເລັກຊັນ',
    收款码: 'ລະຫັດການຊຳລະເງິນ',
    收款码管理: 'ການຈັດການລະຫັດການຊຳລະເງິນ',
    收款码类型: 'ປະເພດລະຫັດການຊຳລະເງິນ',
    收款名称: 'ຊື່ຜູ້ຮັບເງິນ',
    收款账号: 'ໝາຍເລກບັນຊີການຊຳລະເງິນ',
    手续费: 'ຄ່າທໍານຽມການຖອນເງິນ',
    提现: 'ຖອນເງິນ',
    提现币种: 'ສະກຸນເງິນທີ່ຖອນ',
    提现成功: 'ສຳເລັດການຖອນເງິນ',
    提现记录: 'ບັນທຶກການຖອນເງິນ',
    提现金额: 'ຈໍານວນການຖອນເງິນ',
    提现失败: 'ລົ້ມເຫລວການຖອນເງິນ',
    提现时间: 'ເວລາຖອນເງິນ',
    提现手续费: 'ຄ່າທຳນຽມຖອນເງິນ',
    提现详情: 'ລາຍລະອຽດຖອນເງິນ',
    提现中: 'ກຳລັງຖອນເງິນ',
    提现状态: 'ສະຖານະການຖອນເງິນ',
    添加收款码: 'ເພີ່ມລະຫັດການຊຳລະເງິນ',
    添加银行卡: 'ເພີ່ມບັດທະນາຄານ',
    退款: 'ການຄືນເງິນ',
    忘记支付密码: 'ລືມລະຫັດການຊຳລະເງິນ?',
    尾号: 'ໝາຍເລກຫາງ',
    我的钱包: 'ກະເປົາເງິນຂອງຂ້ອຍ',
    我的余额: 'ຈຳນວນເງິນຂອງຂ້ອຍ',
    详情: 'ລາຍລະອຽດ',
    消费: 'ລາຍຈ່າຍ',
    新支付密码: 'ລະຫັດຜ່ານຊຳລະເງິນໃໝ່',
    修改支付密码: 'ປ່ຽນລະຫັດຜ່ານຊຳລະເງິນ',
    选择收款方式: 'ເລືອກວິທີການຊຳລະເງິນ',
    银行卡: 'ບັດທະນາຄານ',
    银行卡管理: 'ການຈັດການບັດທະນາຄານ',
    银行卡号: 'ເລກບັດບັນຊີ',
    银行名称: 'ຊື່ທະນາຄານ',
    余额记录: 'ບັນທຶກຈຳນວນເງິນ',
    余额数据: 'ຂໍ້ມູນຈຳນວນ',
    原支付密码: 'ລະຫັດຜ່ານຊຳລະເງິນເກົ່າ',
    暂无数据: 'ບໍ່​ມີ​ຂໍ້​ມູນ',
    支持币种: 'ສະ​ຫນັບ​ສະ​ຫນູນ​ສະ​ກຸນ​ເງິນ​',
    支付密码: 'ລະຫັດຜ່ານຊຳລະເງິນ',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件: 'ອັບໂຫລດໄດ້ພຽງຮູບດຽວ, ຂະໜາດບໍ່ເກີນ5M,ຮອງຮັບfile jpg png jpeg',
    账户余额: 'ຍອດເງິນໃນບັນຊີ',
    钱包服务: 'ບໍລິການກະເປົາເງິນ',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'ຈໍານວນການຖອນສູງສຸດຕໍ່ມື້ແມ່ນ {num} ຄັ້ງ, ຈໍານວນການຖອນສູງສຸດຕໍ່ຄັ້ງແມ່ນ {icon}{money}, ແລະຈໍານວນການຖອນຂັ້ນຕ່ໍາແມ່ນ {icon}{money2}.',
    确认: 'ຢືນຢັນ',
    输入金额超过可提现余额: 'ຈໍານວນທີ່ປ້ອນເຂົ້າເກີນຍອດເງິນທີ່ຖອນໄດ້',
    日期选择: 'ການເລືອກວັນທີ',
    备注: 'ຂໍ້ສັງເກດ',
    当前没有余额记录: 'ປະຈຸບັນບໍ່ມີບັນທຶກຍອດເງິນ!',
    当前没有提现记录: 'ປະຈຸບັນບໍ່ມີບັນທຶກການຖອນເງິນ!',
    还没有绑定银行卡: 'ບໍ່ມີບັດທະນາຄານຖືກຜູກມັດເທື່ອ!',
    还没有添加收款码: 'ຍັງບໍ່ໄດ້ເພີ່ມລະຫັດການຈ່າຍເງິນເທື່ອ!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'ເຄັດ​ລັບ​: ພຽງ​ແຕ່​ຫນຶ່ງ​ຮູບ​ພາບ​ທີ່​ສາ​ມາດ​ອັບ​ໂຫຼດ​ໄດ້​, ຂະ​ຫນາດ​ຂອງ​ຮູບ​ພາບ​ບໍ່​ຄວນ​ຈະ​ເກີນ 5M​, ແລະ​ໄຟລ​໌​ຮູບ​ແບບ jpg​, png​, ແລະ jpeg ສະ​ຫນັບ​ສະ​ຫນູນ​. ລະຫັດ QR ການຈ່າຍເງິນທີ່ອັບໂຫຼດຕ້ອງຈະແຈ້ງ ແລະ ບໍ່ມີສິ່ງກີດຂວາງ.',
    请上传收款码: 'ກະລຸນາອັບໂຫລດລະຫັດການຈ່າຍເງິນ',
    请输入收款码名称: 'ກະລຸນາໃສ່ຊື່ລະຫັດການຈ່າຍເງິນ',
    打款成功: 'ການຈ່າຍເງິນສຳເລັດແລ້ວ',
    开始日期: 'ວັນ​ທີ່​ເລີ່ມ',
    结束日期: 'ວັນທີສິ້ນສຸດ',
    请再次输入新密码: 'ກະລຸນາໃສ່ລະຫັດຜ່ານອີກຄັ້ງ',
    修改: 'ທົບທວນຄືນ',
    请选择收款方式: 'ກະລຸນາເລືອກວິທີການຊໍາລະເງິນ',
    收款类型: 'ປະເພດການຈ່າຍເງິນ',
    收款码名称: 'ຊື່ລະຫັດການຈ່າຍເງິນ',
    编辑银行卡: 'ແກ້ໄຂບັດທະນາຄານ',
    编辑收款码: 'ແກ້ໄຂລະຫັດການຈ່າຍເງິນ',
    订单ID: 'ລະຫັດຄໍາສັ່ງ',
    加载中: 'ກຳລັງດາວໂຫຼດ',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'ຂໍ​ອະ​ໄພ, ຮ້ານ​ທີ່​ທ່ານ​ໄດ້​ໄປ​ຢ້ຽມ​ຢາມ​ອາດ​ຈະ​ໄດ້​ຮັບ​ການ​ລຶບ, ຊື່​ຂອງ​ຕົນ​ໄດ້​ຖືກ​ປ່ຽນ​ແປງ, ຫຼື​ມັນ​ອາດ​ຈະ​ບໍ່​ມີ ...',
    请选择支付货币: 'ກະລຸນາເລືອກສະກຸນເງິນການຈ່າຍເງິນ',
    请选择收货地址: 'ບໍ່ໄດ້ເພີ່ມທີ່ຢູ່, ກະລຸນາເພີ່ມມັນກ່ອນ',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit} {money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "ກິໂລທຳອິດ{num}kg {currencyUnit} {money}，ກິໂລຕໍ່ໄປ{num2}kg   {currencyUnit} {money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "ເຄື່ອງທຳອິດ{num3}ອັນ{currencyUnit} {money3}，ເຄື່ອງຕໍ່ໄປ{num4}ອັນ{currencyUnit} {money4}",
    支持配送区域: '（ສະຫນັບສະຫນູນພື້ນທີ່ການຈັດສົ່ງ：{diqu}。）',
    配送费: '配送费',
    暂无配送区域: 'ບໍ່ມີພື້ນທີ່ຈັດສົ່ງເທື່ອ',
    该商品在当前地区暂不支持配送: 'ຜະລິດຕະພັນນີ້ບໍ່ຮອງຮັບການຈັດສົ່ງໃນພາກພື້ນປັດຈຸບັນ',
    查看运费: 'ເບິ່ງຄ່າຂົນສົ່ງ',
    运费方式: 'ວິທີການຂົນສົ່ງ',
    价格: 'ລາຄາ',
    请选择配送方式: 'ກະລຸນາເລືອກວິທີການຈັດສົ່ງ',
    配送方式: 'ວິທີການຈັດສົ່ງ',
    配送费说明: 'ລາຍລະອຽດຄ່າຈັດສົ່ງ',
    我明白了: 'ຂ້ອຍເຫັນ',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'ຖ້າສິນຄ້າຫຼາຍຊະນິດໃຊ້ແມ່ແບບການຂົນສົ່ງດຽວກັນ, ການຂົນສົ່ງຈະຖືກຄິດໄລ່ຕາມກົດລະບຽບຂອງວິທີການຂົນສົ່ງດຽວກັນ.',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'ຖ້າສິນຄ້າຫຼາຍອັນບໍ່ມີແມ່ແບບຄ່າຂົນສົ່ງຄືກັນ, ຄ່າຂົນສົ່ງແມ່ນຜົນລວມຂອງລາຄາຂອງຫຼາຍແມ່ແບບ.',
    快捷登录登录即表示同意协议: 'ໂດຍການເຂົ້າສູ່ລະບົບ, ທ່ານຕົກລົງເຫັນດີກັບຂໍ້ຕົກລົງ',
    打款中: 'ກຳລັງດຳເນີນການຈ່າຍເງິນ',
    银行编码: 'ລະຫັດທະນາຄານ',
    卢比支付: 'ການຈ່າຍເງິນ IDR',
    比索支付: 'ການຈ່າຍເງິນ PHP',
    泰铢支付: 'ການຈ່າຍເງິນ THB',
    越南盾支付: 'ການຈ່າຍເງິນ VND',
    请输入大于1的正整数: 'ກະລຸນາໃສ່ຈຳນວນເຕັມບວກທີ່ໃຫຍ່ກວ່າ 1',
    设置密码: 'ຕັ້ງລະຫັດຜ່ານ',
    登录密码: 'ລະຫັດຜ່ານເຂົ້າສູ່ລະບົບ',
    设置成功: 'ຕັ້ງຄ່າສຳເລັດແລ້ວ',
    当前仅支持xxx的金额: 'ປະຈຸບັນນີ້ຮອງຮັບແຕ່ {currency} {min}-{currency} {max} ເທົ່ານັ້ນ',
    查看运费: 'ເບິ່ງຄ່າຂົນສົ່ງ',
    运费方式: 'ວິທີການຂົນສົ່ງ',
    价格: 'ລາຄາ',
    请选择配送方式: 'ກະລຸນາເລືອກວິທີການຈັດສົ່ງ',
    配送方式: 'ວິທີການຈັດສົ່ງ',
    配送费说明: 'ລາຍລະອຽດຄ່າຈັດສົ່ງ',
    我明白了: 'ຂ້ອຍເຫັນ',
    "若多个商品选用同一个运费模板，则运费是按同一个运费方式的规则进行计算。": 'ຖ້າສິນຄ້າຫຼາຍຊະນິດໃຊ້ແມ່ແບບການຂົນສົ່ງດຽວກັນ, ການຂົນສົ່ງຈະຖືກຄິດໄລ່ຕາມກົດລະບຽບຂອງວິທີການຂົນສົ່ງດຽວກັນ.',
    '若多个商品不是同一个运费模板，则运费是多个模板的价格之和。': 'ຖ້າສິນຄ້າຫຼາຍອັນບໍ່ມີແມ່ແບບຄ່າຂົນສົ່ງຄືກັນ, ຄ່າຂົນສົ່ງແມ່ນຜົນລວມຂອງລາຄາຂອງຫຼາຍແມ່ແບບ.',
    '快捷登录，登录即表示同意协议': 'ໂດຍການເຂົ້າສູ່ລະບົບ, ທ່ານຕົກລົງເຫັນດີກັບຂໍ້ຕົກລົງ',
    促销广告: 'ການໂຄສະນາສົ່ງເສີມ',
    最近新品: 'ຜະລິດຕະພັນໃຫມ່',
    更多: 'ເພີ່ມເຕີມ',
    全部分类: 'ໝວດໝູ່ທັງໝົດ',
    推荐商品: 'ສິນຄ້າແນະນໍາ',

    //     1.6
    我的优惠券:'ຄູປ໋ອງສ່ວນຫຼຸດຂອງຂ້ອຍ',
    历史记录:'ບັນທຶກປະຫວັດສາດ',
    已使用:'ໃຊ້ແລ້ວ',
    已过期:'ໝົດອາຍຸແລ້ວ',
    优惠券:'ຄູປ໋ອງ',
    领取:'ຮັບ',
    已领取:'ໄດ້ຮັບ',
    有效期:'ໄລຍະເວລາທີ່ຖືກຕ້ອງ：',
    活动优惠详情:'ລາຍລະອຽດສ່ວນຫຼຸດເຫດການ',
    可用商品:'ລາຍການທີ່ມີຢູ່',
    单独购买:'ລາຄາຕົ້ນສະບັບ',
    发起拼团:'ລາຄາຊື້ກຸ່ມ',
    拼团价:'ລາຄາຊື້ກຸ່ມ',
    可参与的拼团:'ການຊື້ກຸ່ມທີ່ມີຢູ່',
    '再邀X人即可拼单成功':'ແບ່ງປັນກັບອີກ {num} ຄົນເພື່ອການສັ່ງຊື້ທີ່ສຳເລັດ >',
    拼单详情:'ລາຍລະອຽດການຊື້ກຸ່ມ',
    待分享:'ລໍຖ້າແບ່ງປັນ',
    '若拼团失败，货款将原路退回':'ຖ້າການຊື້ກຸ່ມລົ້ມເຫລວ, ການຈ່າຍເງິນຈະຖືກສົ່ງຄືນ.',
    促销价:'ລາຄາຂາຍ',
    距离结束时间:'End in',
    满XX减XX:"ຊື້ {currencyUnit}{priceStart} ການສະຖານທີ  ່{currencyUnit}{reducePrice}{couponType==2?'%':''} ໄດ້",
    满XX减X:'ຊື້ {currencyUnit}{priceStart} ການສະຖານທີ່ {reducePrice}% ໄດ້',
    分享好友X人成团立省X:'ຊື້ເປັນກຸ່ມ <span style="color: #8D4BBB;">{num}</span>ຄົນ ປະຢັດ<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'ແບ່ງປັນກັບຫມູ່ເພື່ອນ',
    '这些人正在拼，立即参与 ':'ກໍາລັງຊື້ກຸ່ມ, ເຂົ້າຮ່ວມດຽວນີ້ ',
    满x可用:'ມີໃຫ້ສັ່ງເກີນ {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'ກໍາລັງຊື້ກຸ່ມ, ເຂົ້າຮ່ວມດຽວນີ້',
    限领x张:'{receiveCount} ຄູປ໋ອງຕໍ່ຂີດຈຳກັດຜູ້ໃຊ້',
    满XX送赠品:'ຂອງຂວັນຟຣີສໍາລັບຄໍາສັ່ງຫຼາຍກວ່າ {currencyUnit}{priceStart}',
    去使用:'ການ ນໍາ ໃຊ້',
    销量:'ການຂາຍ',
    赠品:'ແຈກ',
    限时优惠:'ການສະເຫນີເວລາຈໍາກັດ',
    距结束:'End in ：',
    拼团好物:'ຊື້ຄຸນສະມາຊິກ',
    当前优惠券可以购买以下商品:'ຄູປ໋ອງປະຈຸບັນສາມາດຊື້ຜະລິດຕະພັນຕໍ່ໄປນີ້:',
    以下商品:'ຜົນຜະລິດຕະພັນີ້ນ',
    满XX减XX可用:"ຊື້ {currencyUnit}{priceStart} ການສະຖານທີ່ {currencyUnit}{reducePrice} ໄດ້",
    满XX减X可用:'ຊື້ {currencyUnit}{priceStart} ການສະຖານທີ່ {reducePrice}% ໄດ້',
    再买x可用:'ຍັງຕ້ອງການຊື້ {currencyUnit}{difPrice}',
    合计:'ທັງໝົດ',
    优惠:'ສ່ວນຫຼຸດ',
    去购物车:'ກະຕ່າຊື້ເຄື່ອງ',
    拼团:'ຊື້ຄຸນສະມາຊິກ',
    满XX可用:'ມີໃຫ້ສັ່ງເກີນ {currencyUnit}{priceStart}',
    满减满赠:'ສ່ວນຫຼຸດເຕັມ/ຂອງຂວັນເຕັມ',
    成团发货:'ລໍຖ້າການຈັດສົ່ງ',
    还差xx人成团:'ຍັງຕ້ອງການ <span style="color: #FF4545;">{num}</span> ຄົນເພື່ອສັ່ງໃຫ້ສຳເລັດ',
    拼团结束:'ການຊື້ກຸ່ມສິ້ນສຸດລົງ',
    拼团成功:'ການຊື້ກຸ່ມປະສົບຜົນສໍາເລັດ',
    有成员未付款请等待付款成团:'ບາງຄົນຍັງບໍ່ໄດ້ຈ່າຍເງິນ, ກະລຸນາລໍຖ້າການຈ່າຍເງິນ',
    我正在参与的拼团:'ການ ເຂົ້າ ຮ່ວມ ໃນ ການ ຊື້ ກຸ່ມ',
    满减:'ສ່ວນຫຼຸດເຕັມ',
    满赠:'ຂອງຂວັນເຕັມ',
    规格选择:'ເລືອກສະເພາະ',
    凑单:'ເຮັດຄໍາສັ່ງຮ່ວມກັນ',
    切换其他优惠:'ເລືອກຂໍ້ສະເໜີອື່ນ',
    超过最大购买数:'ເກີນຈຳນວນການຊື້ສູງສຸດ',
    商品限购x件:'ຈໍາກັດ {num} ຕ່ອນ',
    剩余:'剩余',
    结束:'结束',
    参与拼团:'ເຂົ້າຮ່ວມ',
    拼单失败:'ການຊື້ກຸ່ມລົ້ມເຫລວ',
    可用优惠券:'ຄູປອງທີ່ມີຢູ່',
    免费:'ຟຣີ',
    已赠完:'ສິນຄ້າໝົດ',
    已领完:"ໃຊ້ແລ້ວ",
    请勾选删除商品: 'ກະລຸນາກວດເບິ່ງລາຍການສິນຄ້າທີ່ຈະລົບ',
    活动未开始:'ເຫດການຍັງບໍ່ໄດ້ເລີ່ມຕົ້ນ',

    已满X可减X:'ສ່ວນຫຼຸດ {currencyUnit}{reducePrice} ສຳລັບການຊື້ຫຼາຍກວ່າ {currencyUnit}{difPrice}',
    已满X可减XX:'ສ່ວນຫຼຸດ {reducePrice}% ສຳລັບການຊື້ຫຼາຍກວ່າ {currencyUnit}{difPrice}',

    再买X可减XX:'ຊື້ອີກ {currencyUnit}{difPrice} ຮັບສ່ວນຫຼຸດ {reducePrice}%',
    再买X送赠品:'ຊື້ອີກ {currencyUnit}{difPrice} ແລະຮັບຂອງຂັວນຟຣີ',
    已满X送赠品:'ຂອງຂວັນໄດ້ຖືກມອບໃຫ້',
    限X件:'ຈໍາກັດການຊື້  {num} ຕ່ອນ',
    您还没有优惠券:'ເຈົ້າບໍ່ມີຄູປ໋ອງ',
    x件: '{num}ອັນ',

    退货积分:'ກັບຄືນຈຸດ',
    积分:'ປະສົມປະສານ',
    用户注册:'ການລົງທະບຽນຜູ້ໃຊ້',
    手动增加:'ເພີ່ມຂຶ້ນດ້ວຍຕົນເອງ',
    积分兑换退款:'ການຄືນເງິນຄະແນນ',
    下单购物:'ສັ່ງຊື້ສິນຄ້າ',
    过期积分:'ຈຸດໝົດອາຍຸ',
    兑换商品:'ແລກຊື້ສິນຄ້າ',
    手动减少:'ການຫຼຸດຜ່ອນຄູ່ມື',
    订单退款:'ສັ່ງຄືນເງິນ',
    积分明细:'ລາຍລະອຽດຄະແນນ',
    已兑:'ແລກແລ້ວ',
    积分商城:'Points Mall',
    规则:'ກົດລະບຽບ',
    可用积分:'ຄະແນນທີ່ມີຢູ່',
    冻结积分:'ຈຸດຄ້າງ',
    我的兑换:'ການແລກປ່ຽນຂອງຂ້ອຍ',
    售后:'ຫຼັງຈາກການຂາຍ',
    分销中心:'ສູນກະຈາຍສິນຄ້າ',
    立即兑换:'ແລກດຽວນີ້',
    已兑完:'ແລກແລ້ວ',
    "积分不足，无法兑换":'ຄະແນນບໍ່ພຽງພໍບໍ່ສາມາດແລກໄດ້',
    兑换数量:'ປະລິມານການແລກປ່ຽນ',
'限兑*件':'ການ ແລກ ປ່ຽນ ຈໍ ກັດ  {num} ລາຍ ການ',
    积分抵扣:'ການຫັກຄະແນນ',
'共*分':'ລວມ {num} ຈຸດ',
    全部订单:'ຄໍາສັ່ງທັງຫມົດ',

兑换须知:'ຄໍາແນະນໍາການໄຖ່',
    已达兑换上限:'ຮອດຂີດຈຳກັດການໄຖ່ແລ້ວ',
    我的积分:'ຄະແນນຂອງຂ້ອຍ',
    积分规则:'ກົດລະບຽບຄະແນນ',
    兑换时间:'ເວລາແລກ',
    我的佣金:'ຄະນະກໍາມະຂອງຂ້ອຍ',
    佣金数据:'ຂໍ້ມູນຄະນະກໍາມະ',
    分销申请协议:'ຂໍ້ຕົກລົງການສະເໜີການແຈກຢາຍ',
    申请成为分销员:'ສະໝັກເປັນຕົວແທນຈຳໜ່າຍ',
    "已申请，请等待审核":'ນຳໃຊ້ແລ້ວ, ກະລຸນາລໍຖ້າກວດສອບ',
    "审核拒绝，继续申请":'ປະຕິເສດໂດຍການກວດສອບ, ສືບຕໍ່ນຳໃຊ້',
    可提佣金:'ຄະ ນະ ກໍາ ມະ ການ ຖອນ ເງິນ ',
    冻结佣金:'ຢຸດຄະນະ ກຳ ມະການ',
    分销订单:'ຄໍາສັ່ງແຈກຢາຍ',
    佣金明细:'ລາຍລະອຽດຄະນະກໍາມະການ',
    我的下级:'subordinates ຂອງຂ້ອຍ',
    推广商品:'ສົ່ງເສີມຜະລິດຕະພັນ',
    推广店铺:'ສົ່ງເສີມຮ້ານ',
    暂无记录:'ບໍ່ມີບັນທຶກ',
    一级佣金收入:'ລາຍໄດ້ຄະນະກໍາມະລະດັບທໍາອິດ',
    一级佣金退款:'ການຄືນເງິນຄ່ານາຍໜ້າລະດັບທຳອິດ',
    二级佣金收入:'ລາຍໄດ້ຂອງຄະນະກໍາມະການຂັ້ນສອງ',
    二级佣金退款:'ການຄືນເງິນຄ່ານາຍໜ້າລະດັບທີສອງ',
    下单用户:'ການ ສັ່ງ ໃຫ້ ຜູ້ ໃຊ້ ',
    订单总价:'ສັ່ງລາຄາທັງໝົດ',
    分销利润层级:'ລະດັບການແຜ່ກະຈາຍ',
    结算状态:'ສະ ຖາ ນະ ການ ຕັ້ງ ຖິ່ນ ຖານ ',
    佣金:'ຄະນະກໍາມະ',
    一级:'ລະດັບ 1',
    二级:'ລະດັບ 2',
    已结算:'ຕົກລົງ',
    请输入用户昵称或手机号:'ກະລຸນາໃສ່ຊື່ຜູ້ໃຊ້ ຫຼືເບີໂທລະສັບມືຖື',
    二级团队:'ທີມງານລະດັບທີສອງ',
    分享链接:'ແບ່ງປັນການເຊື່ອມຕໍ່',
    可推广至:'ສາມາດຂະຫຍາຍອອກໄປ',
    分享最高可赚x:'ຮັບສູງເຖິງ {icon}{num} ໂດຍການແບ່ງປັນ',
    分享推广:'ແບ່ງປັນແລະສົ່ງເສີມ',
    用户昵称或手机号:'ຊື່ຜູ້ໃຊ້ ຫຼືເບີໂທລະສັບ',
    复制:'ສຳເນົາ',
    商品名称:'ຊື່ ຜະ ລິດ ຕະ ພັນ',
    最高可得:'ສູງສຸດທີ່ມີຢູ່',
    可获得积分:'ຄະແນນທີ່ໄດ້ຮັບ',
    总获佣金:'ໄດ້ຮັບຄ່ານາຍໜ້າທັງໝົດ',
    请选择银行卡: 'ກະລຸນາເລືອກບັດທະນາຄານ',
    请选择收款码: 'ກະລຸນາເລືອກລະຫັດການຈ່າຍເງິນ',
    不能超过最小提现额度:'ຈໍານວນການຖອນເງິນຂັ້ນຕ່ໍາບໍ່ສາມາດເກີນ!',

    普通订单:'ຄໍາສັ່ງທໍາມະດາ',
    售货机:'ເຄື່ອງຂາຍ',
    订单类型:'ປະເພດຄໍາສັ່ງ',
    请选择类型:'ກະລຸນາເລືອກປະເພດ',
    '房间号/桌号':'ໝາຍເລກຫ້ອງ/ຕາຕະລາງ'
}
//老挝
