import axios from 'axios'
import Qs from 'qs'
import { Message, Loading } from 'element-ui'
import store from '@/store'
import router from '@/router/index.js'
import { gettoken, removetoken } from '@/utils/auth'
import { environments } from './env'
const { apiUrl } = environments[environments.apiNow]
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'https://gateway-test.bawanli.com',//测试地址
  // baseURL: 'https://gateway.whatsbuy.com',//正式地址
    // baseURL: 'http://192.168.110.165:8098',//本地
  // baseURL: 'http://192.168.110.154:8098',//本地磊哥
  // baseURL: 'http://192.168.110.248:8098',//本地玉杰
  // baseURL: 'http://192.168.110.163:8098', //本地欧阳
  // baseURL: 'http://192.168.110.174:8098',
  // baseURL: 'https://gateway-uat.whatsbuy.com',//uat地址
  timeout: 90000
})
let loadingInstance

//拦截器  -请求拦截
http.interceptors.request.use(
  config => {
    if (
      !config.noLoading &&
      (location.pathname !== '/404' || localStorage.getItem('storeID'))
    ) {
      // 只有当前页面不是404页面时才显示loading
      loadingInstance = Loading.service({
        fullscreen: true,
        background: 'rgb(0,0,0, .1)',
        text: window.$i18n.t('加载中')
      })
    }
    config.headers['clientId'] = 'users'
    config.headers['storeId'] = localStorage.getItem('storeID')
    config.headers['lang'] = localStorage.getItem('lang')
    config.headers['currencyId4Font'] = localStorage.getItem('currency')
    config.headers['mode'] = 'PC'
    // config.headers['mobile'] = 'PC'
      if(config.url=='/userConsumer/userGoods/getFrontGoods4Id'||config.url=='/userConsumer/userGoods/userListFrontGoodsStock'){
          if(config.params?.teamworkID){
              config.headers['groupId']=config.params?.teamworkID
          }
      }
    // localStorage.getItem("startTime11");
    if (config.url == '/payCon/userOrderPay/bcelPayNotify') {
      config.headers['X-ft-Sign'] = localStorage.getItem('signS')
    }
    if (
      config.method &&
      config.method.toLocaleLowerCase() == 'get' &&
      config.data
    ) {
      config.url = config.url + '?' + Qs.stringify(config.data)
    }
    if (store.state.user.token) {
      config.headers['Authorization'] = gettoken()
    }

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

//拦截器  -响应拦截
http.interceptors.response.use(
  response => {
    const res = response.data
    // if (response.config.url != '/threeTerminalCon/utils/ImgUpload'&&!response.config.noLoading) {
    loadingInstance && loadingInstance.close()
    // }
    console.log(
      router.currentRoute.path,
      '********',
      location.pathname,
      location.pathname === '/404'
    )
    if (location.pathname === '/404' || !localStorage.getItem('storeID')) {
      console.log('走这里')
      // 如果当前页面是404页面，则不执行后续逻辑
      return Promise.reject('Do not make API requests on /404 page')
    }
    console.log('走这里2')

    const whiteList = ['getImageCode']
    if (whiteList.find(url => response.config.url.indexOf(url) >= 0)) {
      return Promise.resolve(response)
    }
    if (res.code !== 200) {
      //token过期
      if (res.code === 200435 || res.code == 20999) {
        removetoken()
        localStorage.removeItem('userData')
        store.dispatch('user/removetoken')
        store.dispatch('order/Seaddress', null)
        store.commit('locshoppingcar/lookloacshoppingcarnum')
        router.push('/login?storeId=' + localStorage.getItem('storeID'))
        return Promise.reject(new Error(res.msg || 'Error'))
      } else if (res.code === 200500) {
        removetoken()
        store.dispatch('user/removetoken')
        store.dispatch('order/Seaddress', null)
        store.commit('locshoppingcar/lookloacshoppingcarnum')
        store.dispatch('loginfalse/updataorder', '1')
        router.push('/404')
      } else if (res.code === 200491) {
        removetoken()
        localStorage.removeItem('userData')
        store.dispatch('user/removetoken')
        store.dispatch('order/Seaddress', null)
        store.commit('locshoppingcar/lookloacshoppingcarnum')
        // router.push('/login?storeId='+localStorage.getItem("storeID"))
        return Promise.reject(new Error(res.msg || 'Error'))
      } else if (res.code === 200497) {
        return Promise.resolve(res)
      } else if (
        res.code === 200001 &&
        response.config.url.indexOf('checkPay') >= 0
      ) {
        return Promise.resolve(res)
      } else if (
        res.code === 200469 ||
        res.code === 200514 ||
        res.code === 200412
      ) {
        return Promise.resolve(res)
      } else if (res.code === 200498) {
        Message({
          message: res.data || res.msg,
          type: 'error',
          duration: 5 * 1000
        })
      } else if (res.code === 200426) {
        Message({
          message: '请填写完整必填项',
          type: 'error',
          duration: 5 * 1000
        })
      }else if (res.code===200727){
          Message({
              message: window.$i18n.t('已领取'),
              type: 'error',
              duration: 5 * 1000
          })
      }else {
          Message({
              message: res.data || res.msg,
              type: 'error',
              duration: 5 * 1000
          })
      }
      return Promise.resolve(res)
    } else {
      return Promise.resolve(res)
    }
  },
  err => {
    return Promise.reject(err)
  }
)
export default http
