<template>
  <div class="custom-countdown">
    <div class="countdown row-s">
      <div  v-if="remainingTime.days > 0" class="day">{{ remainingTime.days }} {{ $t('天') }} </div>
      <div class="hh">{{ pad(remainingTime.hours) }}</div>
      <div class="colon">:</div>
      <div class="hh">{{ pad(remainingTime.minutes) }}</div>
      <div class="colon">:</div>
      <div class="hh">{{ pad(remainingTime.seconds) }}</div>
    </div>
<!--    <span v-if="remainingTime.days > 0"></span>-->
<!--    <span>{{ pad(remainingTime.hours) }}:{{ pad(remainingTime.minutes) }}:{{ pad(remainingTime.seconds) }}</span>-->
  </div>
</template>
<script>
export default {
  props: {
    time: {
      type: Number,
      required: true
    },
    // endTime:Number,
    nowTime:{
      type: String,
      required: true
    }

  },
  data() {
    return {
      countdown: null,
      remainingTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      nowtime:''
    };
  },
  methods: {
    // 初始化倒计时
    initializeCountdown() {
      this.calculateTime();

      this.countdown = setInterval(() => {
        this.calculateTime();

      }, 1000);
    },
    // 计算剩余时间
    calculateTime() {

      this.nowtime += 1;
      let timeDiff = this.time - Math.floor(this.nowtime);
      const newRemainingTime = {
        days: Math.floor(timeDiff / (24 * 60 * 60)),
        hours: 0,
        minutes: 0,
        seconds: 0
      };

      timeDiff -= newRemainingTime.days * (24 * 60 * 60);
      newRemainingTime.hours = Math.floor(timeDiff / (60 * 60));
      timeDiff -= newRemainingTime.hours * (60 * 60);
      newRemainingTime.minutes = Math.floor(timeDiff / 60);
      newRemainingTime.seconds = timeDiff % 60;
      // 使用Vue的$set方法确保响应性
      this.$set(this, 'remainingTime', newRemainingTime);
      if (timeDiff <= 0) {
        this.endCountdown();
      }
    },
    // 补零
    pad(value) {
      return value < 10 ? '0' + value : value;
    },
    // 倒计时结束时调用的方法
    endCountdown() {
      // 在这里执行你的逻辑
      this.$emit('endCountdown')
      console.log("倒计时结束，执行相应操作");
      clearInterval(this.countdown); // 停止倒计时
    },
  },
  mounted() {
    this.nowtime=new Date(this.$getTimeZoneOffset(this.nowTime)).getTime() / 1000
    console.log(this.$getTimeZoneOffset(this.nowTime),this.nowTime)
    this.initializeCountdown();
  },
  beforeDestroy() {
    clearInterval(this.countdown);
  }
};
</script>
<style scoped lang="scss">
.countdown{
  .day{
    font-size: 18px;
    margin: 0 5px;
  }
  .hh{
    width: 20px;
    height: 20px;
    line-height: 20px;
    //display: inline-block;
    background: #FF4545;
    border-radius: 4px;
    color: #fff;
    text-align: center;
  }
  .colon{
    display: inline-block;
    margin: 0 4px;
    color: #ee0a24;
  }
}
</style>
